import builtins from './builtins.json';
const exports = {};
// Generated by Haxe 4.3.6
(function ($hx_exports, $global) { "use strict";
$hx_exports["formula"] = $hx_exports["formula"] || {};
var $estr = function() { return js_Boot.__string_rec(this,''); },$hxEnums = $hxEnums || {},$_;
function $extend(from, fields) {
	var proto = Object.create(from);
	for (var name in fields) proto[name] = fields[name];
	if( fields.toString !== Object.prototype.toString ) proto.toString = fields.toString;
	return proto;
}
var DateTools = function() { };
DateTools.__name__ = true;
DateTools.__format_get = function(d,e) {
	switch(e) {
	case "%":
		return "%";
	case "A":
		return DateTools.DAY_NAMES[d.getDay()];
	case "B":
		return DateTools.MONTH_NAMES[d.getMonth()];
	case "C":
		return StringTools.lpad(Std.string(d.getFullYear() / 100 | 0),"0",2);
	case "D":
		return DateTools.__format(d,"%m/%d/%y");
	case "F":
		return DateTools.__format(d,"%Y-%m-%d");
	case "M":
		return StringTools.lpad(Std.string(d.getMinutes()),"0",2);
	case "R":
		return DateTools.__format(d,"%H:%M");
	case "S":
		return StringTools.lpad(Std.string(d.getSeconds()),"0",2);
	case "T":
		return DateTools.__format(d,"%H:%M:%S");
	case "Y":
		return Std.string(d.getFullYear());
	case "a":
		return DateTools.DAY_SHORT_NAMES[d.getDay()];
	case "d":
		return StringTools.lpad(Std.string(d.getDate()),"0",2);
	case "e":
		return Std.string(d.getDate());
	case "b":case "h":
		return DateTools.MONTH_SHORT_NAMES[d.getMonth()];
	case "H":case "k":
		return StringTools.lpad(Std.string(d.getHours()),e == "H" ? "0" : " ",2);
	case "I":case "l":
		var hour = d.getHours() % 12;
		return StringTools.lpad(Std.string(hour == 0 ? 12 : hour),e == "I" ? "0" : " ",2);
	case "m":
		return StringTools.lpad(Std.string(d.getMonth() + 1),"0",2);
	case "n":
		return "\n";
	case "p":
		if(d.getHours() > 11) {
			return "PM";
		} else {
			return "AM";
		}
		break;
	case "r":
		return DateTools.__format(d,"%I:%M:%S %p");
	case "s":
		return Std.string(d.getTime() / 1000 | 0);
	case "t":
		return "\t";
	case "u":
		var t = d.getDay();
		if(t == 0) {
			return "7";
		} else if(t == null) {
			return "null";
		} else {
			return "" + t;
		}
		break;
	case "w":
		return Std.string(d.getDay());
	case "y":
		return StringTools.lpad(Std.string(d.getFullYear() % 100),"0",2);
	default:
		throw new haxe_exceptions_NotImplementedException("Date.format %" + e + "- not implemented yet.",null,{ fileName : "DateTools.hx", lineNumber : 101, className : "DateTools", methodName : "__format_get"});
	}
};
DateTools.__format = function(d,f) {
	var r_b = "";
	var p = 0;
	while(true) {
		var np = f.indexOf("%",p);
		if(np < 0) {
			break;
		}
		var len = np - p;
		r_b += len == null ? HxOverrides.substr(f,p,null) : HxOverrides.substr(f,p,len);
		r_b += Std.string(DateTools.__format_get(d,HxOverrides.substr(f,np + 1,1)));
		p = np + 2;
	}
	var len = f.length - p;
	r_b += len == null ? HxOverrides.substr(f,p,null) : HxOverrides.substr(f,p,len);
	return r_b;
};
DateTools.format = function(d,f) {
	return DateTools.__format(d,f);
};
var EReg = function(r,opt) {
	this.r = new RegExp(r,opt.split("u").join(""));
};
EReg.__name__ = true;
EReg.prototype = {
	match: function(s) {
		if(this.r.global) {
			this.r.lastIndex = 0;
		}
		this.r.m = this.r.exec(s);
		this.r.s = s;
		return this.r.m != null;
	}
	,matched: function(n) {
		if(this.r.m != null && n >= 0 && n < this.r.m.length) {
			return this.r.m[n];
		} else {
			throw haxe_Exception.thrown("EReg::matched");
		}
	}
	,matchedPos: function() {
		if(this.r.m == null) {
			throw haxe_Exception.thrown("No string matched");
		}
		return { pos : this.r.m.index, len : this.r.m[0].length};
	}
	,matchSub: function(s,pos,len) {
		if(len == null) {
			len = -1;
		}
		if(this.r.global) {
			this.r.lastIndex = pos;
			this.r.m = this.r.exec(len < 0 ? s : HxOverrides.substr(s,0,pos + len));
			var b = this.r.m != null;
			if(b) {
				this.r.s = s;
			}
			return b;
		} else {
			var b = this.match(len < 0 ? HxOverrides.substr(s,pos,null) : HxOverrides.substr(s,pos,len));
			if(b) {
				this.r.s = s;
				this.r.m.index += pos;
			}
			return b;
		}
	}
	,map: function(s,f) {
		var offset = 0;
		var buf_b = "";
		do {
			if(offset >= s.length) {
				break;
			} else if(!this.matchSub(s,offset)) {
				buf_b += Std.string(HxOverrides.substr(s,offset,null));
				break;
			}
			var p = this.matchedPos();
			buf_b += Std.string(HxOverrides.substr(s,offset,p.pos - offset));
			buf_b += Std.string(f(this));
			if(p.len == 0) {
				buf_b += Std.string(HxOverrides.substr(s,p.pos,1));
				offset = p.pos + 1;
			} else {
				offset = p.pos + p.len;
			}
		} while(this.r.global);
		if(!this.r.global && offset > 0 && offset < s.length) {
			buf_b += Std.string(HxOverrides.substr(s,offset,null));
		}
		return buf_b;
	}
};
var HxOverrides = function() { };
HxOverrides.__name__ = true;
HxOverrides.dateStr = function(date) {
	var m = date.getMonth() + 1;
	var d = date.getDate();
	var h = date.getHours();
	var mi = date.getMinutes();
	var s = date.getSeconds();
	return date.getFullYear() + "-" + (m < 10 ? "0" + m : "" + m) + "-" + (d < 10 ? "0" + d : "" + d) + " " + (h < 10 ? "0" + h : "" + h) + ":" + (mi < 10 ? "0" + mi : "" + mi) + ":" + (s < 10 ? "0" + s : "" + s);
};
HxOverrides.cca = function(s,index) {
	var x = s.charCodeAt(index);
	if(x != x) {
		return undefined;
	}
	return x;
};
HxOverrides.substr = function(s,pos,len) {
	if(len == null) {
		len = s.length;
	} else if(len < 0) {
		if(pos == 0) {
			len = s.length + len;
		} else {
			return "";
		}
	}
	return s.substr(pos,len);
};
HxOverrides.now = function() {
	return Date.now();
};
var Lambda = function() { };
Lambda.__name__ = true;
Lambda.fold = function(it,f,first) {
	var x = $getIterator(it);
	while(x.hasNext()) first = f(x.next(),first);
	return first;
};
Math.__name__ = true;
var Reflect = function() { };
Reflect.__name__ = true;
Reflect.field = function(o,field) {
	try {
		return o[field];
	} catch( _g ) {
		return null;
	}
};
Reflect.fields = function(o) {
	var a = [];
	if(o != null) {
		var hasOwnProperty = Object.prototype.hasOwnProperty;
		for( var f in o ) {
		if(f != "__id__" && f != "hx__closures__" && hasOwnProperty.call(o,f)) {
			a.push(f);
		}
		}
	}
	return a;
};
var Std = function() { };
Std.__name__ = true;
Std.string = function(s) {
	return js_Boot.__string_rec(s,"");
};
Std.parseInt = function(x) {
	var v = parseInt(x);
	if(isNaN(v)) {
		return null;
	}
	return v;
};
var StringTools = function() { };
StringTools.__name__ = true;
StringTools.startsWith = function(s,start) {
	if(s.length >= start.length) {
		return s.lastIndexOf(start,0) == 0;
	} else {
		return false;
	}
};
StringTools.endsWith = function(s,end) {
	var elen = end.length;
	var slen = s.length;
	if(slen >= elen) {
		return s.indexOf(end,slen - elen) == slen - elen;
	} else {
		return false;
	}
};
StringTools.isSpace = function(s,pos) {
	var c = HxOverrides.cca(s,pos);
	if(!(c > 8 && c < 14)) {
		return c == 32;
	} else {
		return true;
	}
};
StringTools.ltrim = function(s) {
	var l = s.length;
	var r = 0;
	while(r < l && StringTools.isSpace(s,r)) ++r;
	if(r > 0) {
		return HxOverrides.substr(s,r,l - r);
	} else {
		return s;
	}
};
StringTools.rtrim = function(s) {
	var l = s.length;
	var r = 0;
	while(r < l && StringTools.isSpace(s,l - r - 1)) ++r;
	if(r > 0) {
		return HxOverrides.substr(s,0,l - r);
	} else {
		return s;
	}
};
StringTools.trim = function(s) {
	return StringTools.ltrim(StringTools.rtrim(s));
};
StringTools.lpad = function(s,c,l) {
	if(c.length <= 0) {
		return s;
	}
	var buf_b = "";
	l -= s.length;
	while(buf_b.length < l) buf_b += c == null ? "null" : "" + c;
	buf_b += s == null ? "null" : "" + s;
	return buf_b;
};
var byte_ByteData = {};
byte_ByteData.get_length = function(this1) {
	return this1.length;
};
byte_ByteData.readByte = function(this1,i) {
	return this1.b[i];
};
byte_ByteData._new = function(data) {
	return data;
};
byte_ByteData.ofString = function(s) {
	return haxe_io_Bytes.ofString(s);
};
byte_ByteData.ofBytes = function(b) {
	return b;
};
byte_ByteData.readString = function(this1,pos,len) {
	return this1.getString(pos,len);
};
var datetime_DTPeriod = $hxEnums["datetime.DTPeriod"] = { __ename__:true,__constructs__:null
	,Year: ($_=function(n) { return {_hx_index:0,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Year",$_.__params__ = ["n"],$_)
	,Month: ($_=function(n) { return {_hx_index:1,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Month",$_.__params__ = ["n"],$_)
	,Day: ($_=function(n) { return {_hx_index:2,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Day",$_.__params__ = ["n"],$_)
	,Hour: ($_=function(n) { return {_hx_index:3,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Hour",$_.__params__ = ["n"],$_)
	,Minute: ($_=function(n) { return {_hx_index:4,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Minute",$_.__params__ = ["n"],$_)
	,Second: ($_=function(n) { return {_hx_index:5,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Second",$_.__params__ = ["n"],$_)
	,Week: ($_=function(n) { return {_hx_index:6,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Week",$_.__params__ = ["n"],$_)
};
datetime_DTPeriod.__constructs__ = [datetime_DTPeriod.Year,datetime_DTPeriod.Month,datetime_DTPeriod.Day,datetime_DTPeriod.Hour,datetime_DTPeriod.Minute,datetime_DTPeriod.Second,datetime_DTPeriod.Week];
var datetime_DTSnap = $hxEnums["datetime.DTSnap"] = { __ename__:true,__constructs__:null
	,Year: ($_=function(direction) { return {_hx_index:0,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Year",$_.__params__ = ["direction"],$_)
	,Month: ($_=function(direction) { return {_hx_index:1,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Month",$_.__params__ = ["direction"],$_)
	,Day: ($_=function(direction) { return {_hx_index:2,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Day",$_.__params__ = ["direction"],$_)
	,Hour: ($_=function(direction) { return {_hx_index:3,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Hour",$_.__params__ = ["direction"],$_)
	,Minute: ($_=function(direction) { return {_hx_index:4,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Minute",$_.__params__ = ["direction"],$_)
	,Second: ($_=function(direction) { return {_hx_index:5,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Second",$_.__params__ = ["direction"],$_)
	,Week: ($_=function(direction,day) { return {_hx_index:6,direction:direction,day:day,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Week",$_.__params__ = ["direction","day"],$_)
};
datetime_DTSnap.__constructs__ = [datetime_DTSnap.Year,datetime_DTSnap.Month,datetime_DTSnap.Day,datetime_DTSnap.Hour,datetime_DTSnap.Minute,datetime_DTSnap.Second,datetime_DTSnap.Week];
var datetime_DateTime = {};
datetime_DateTime.now = function() {
	return Math.floor(new Date().getTime() / 1000) + 62135596800.0;
};
datetime_DateTime.local = function() {
	return Math.floor(new Date().getTime() / 1000) + 62135596800.0 - 62135596800.0 + datetime_DateTime.getLocalOffset() + 62135596800.0;
};
datetime_DateTime.make = function(year,month,day,hour,minute,second) {
	if(second == null) {
		second = 0;
	}
	if(minute == null) {
		minute = 0;
	}
	if(hour == null) {
		hour = 0;
	}
	if(day == null) {
		day = 1;
	}
	if(month == null) {
		month = 1;
	}
	if(year == null) {
		year = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(month,year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (day - 1) * 86400 + hour * 3600 + minute * 60 + second - 62135596800.0 + 62135596800.0;
};
datetime_DateTime.fromTime = function(time) {
	return time + 62135596800.0;
};
datetime_DateTime.fromString = function(str) {
	return datetime_utils_DateTimeUtils.fromString(str);
};
datetime_DateTime.fromDate = function(date) {
	return Math.floor(date.getTime() / 1000) + 62135596800.0;
};
datetime_DateTime.daysInMonth = function(month,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	return datetime_utils_DateTimeMonthUtils.days(month,isLeapYear);
};
datetime_DateTime.weeksInYear = function(year) {
	var start = datetime_utils_DateTimeUtils.yearToStamp(year) - 62135596800.0 + 62135596800.0;
	var weekDay = datetime_DateTime.getWeekDay(start);
	if(weekDay == 4 || weekDay == 3 && datetime_DateTime.isLeapYear(start)) {
		return 53;
	} else {
		return 52;
	}
};
datetime_DateTime.isLeap = function(year) {
	if(year % 4 == 0) {
		if(year % 100 == 0) {
			return year % 400 == 0;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
datetime_DateTime.getLocalOffset = function() {
	var now = new Date();
	var year = now.getFullYear();
	var month = now.getMonth() + 1;
	var day = now.getDate();
	var hour = now.getHours();
	var minute = now.getMinutes();
	var second = now.getSeconds();
	if(second == null) {
		second = 0;
	}
	if(minute == null) {
		minute = 0;
	}
	if(hour == null) {
		hour = 0;
	}
	if(day == null) {
		day = 1;
	}
	if(month == null) {
		month = 1;
	}
	if(year == null) {
		year = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(month,year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (day - 1) * 86400 + hour * 3600 + minute * 60 + second - 62135596800.0 + 62135596800.0 - 62135596800.0 - (now.getTime() / 1000 | 0) | 0;
};
datetime_DateTime._new = function(time) {
	return time + 62135596800.0;
};
datetime_DateTime.utc = function(this1) {
	return this1 - 62135596800.0 - datetime_DateTime.getLocalOffset() + 62135596800.0;
};
datetime_DateTime.getYear = function(this1) {
	var cquads = (this1 / 12622780800.0 | 0) * 12622780800.0;
	var centuries = ((this1 - cquads) / 3155673600.0 | 0) * 3155673600.0;
	if(centuries > 9467020800.) {
		centuries -= 3155673600.0;
	}
	var quads = ((this1 - cquads - centuries) / 126230400.0 | 0) * 126230400.0;
	var years = (this1 - cquads - centuries - quads) / 31536000 | 0;
	return (cquads / 12622780800.0 | 0) * 400 + (centuries / 3155673600.0 | 0) * 100 + (quads / 126230400.0 | 0) * 4 + (years == 4 ? years : years + 1);
};
datetime_DateTime.yearStart = function(this1) {
	var cquads = (this1 / 12622780800.0 | 0) * 12622780800.0;
	var centuries = ((this1 - cquads) / 3155673600.0 | 0) * 3155673600.0;
	if(centuries > 9467020800.) {
		centuries -= 3155673600.0;
	}
	var quads = ((this1 - cquads - centuries) / 126230400.0 | 0) * 126230400.0;
	var years = (this1 - cquads - centuries - quads) / 31536000 | 0;
	if(years == 4) {
		--years;
	}
	return cquads + centuries + quads + years * 31536000 - 62135596800.0;
};
datetime_DateTime.monthStart = function(this1,month) {
	if(month == null) {
		month = 0;
	}
	if(month == 0) {
		month = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	}
	return datetime_DateTime.yearStart(this1) + datetime_utils_DateTimeMonthUtils.toSeconds(month,datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.getMonthStart = function(this1,month) {
	return datetime_DateTime.monthStart(this1,month) + 62135596800.0;
};
datetime_DateTime.isLeapYear = function(this1) {
	var year = datetime_DateTime.getYear(this1);
	if(year % 4 == 0) {
		if(year % 100 == 0) {
			return year % 400 == 0;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
datetime_DateTime.getMonth = function(this1) {
	return datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.getDay = function(this1) {
	return datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.daysInThisMonth = function(this1) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	return datetime_utils_DateTimeMonthUtils.days(month,month == 2 && datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.getYearDay = function(this1) {
	return ((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1;
};
datetime_DateTime.weeksInThisYear = function(this1) {
	return datetime_DateTime.weeksInYear(datetime_DateTime.getYear(this1));
};
datetime_DateTime.getWeekDay = function(this1,mondayBased) {
	if(mondayBased == null) {
		mondayBased = false;
	}
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	var a = (14 - month) / 12 | 0;
	var y = datetime_DateTime.getYear(this1) - a;
	var weekDay = (7000 + (datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1)) + y + (y / 4 | 0) - (y / 100 | 0) + (y / 400 | 0) + (31 * (month + 12 * a - 2) / 12 | 0))) % 7;
	if(mondayBased && weekDay == 0) {
		return 7;
	} else {
		return weekDay;
	}
};
datetime_DateTime.getWeekDayNum = function(this1,weekDay,num) {
	if(num == null) {
		num = 1;
	}
	return datetime_utils_DateTimeUtils.getWeekDayNum(this1 - 62135596800.0 + 62135596800.0,weekDay,num) + 62135596800.0;
};
datetime_DateTime.getWeek = function(this1) {
	var week = (((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1 - datetime_DateTime.getWeekDay(this1,true) + 10) / 7 | 0;
	var year = datetime_DateTime.getYear(this1);
	if(week < 1) {
		return datetime_DateTime.weeksInYear(year - 1);
	} else if(week > 52 && week > datetime_DateTime.weeksInYear(year)) {
		return 1;
	} else {
		return week;
	}
};
datetime_DateTime.getHour = function(this1) {
	return (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
};
datetime_DateTime.getHour12 = function(this1) {
	var hour = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
	if(hour == 0) {
		return 12;
	} else if(hour > 12) {
		return hour - 12;
	} else {
		return hour;
	}
};
datetime_DateTime.getMinute = function(this1) {
	return (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
};
datetime_DateTime.getSecond = function(this1) {
	return this1 - Math.floor(this1 / 60) * 60 | 0;
};
datetime_DateTime.add = function(this1,period) {
	var time;
	switch(period._hx_index) {
	case 0:
		time = datetime_utils_DateTimeUtils.addYear(this1 - 62135596800.0 + 62135596800.0,period.n);
		break;
	case 1:
		time = datetime_utils_DateTimeUtils.addMonth(this1 - 62135596800.0 + 62135596800.0,period.n);
		break;
	case 2:
		time = this1 - 62135596800.0 + period.n * 86400;
		break;
	case 3:
		time = this1 - 62135596800.0 + period.n * 3600;
		break;
	case 4:
		time = this1 - 62135596800.0 + period.n * 60;
		break;
	case 5:
		time = this1 - 62135596800.0 + period.n;
		break;
	case 6:
		time = this1 - 62135596800.0 + period.n * 7 * 86400;
		break;
	}
	return time + 62135596800.0;
};
datetime_DateTime.sub = function(this1,period) {
	var time;
	switch(period._hx_index) {
	case 0:
		time = datetime_utils_DateTimeUtils.addYear(this1 - 62135596800.0 + 62135596800.0,-period.n);
		break;
	case 1:
		time = datetime_utils_DateTimeUtils.addMonth(this1 - 62135596800.0 + 62135596800.0,-period.n);
		break;
	case 2:
		time = this1 - 62135596800.0 - period.n * 86400;
		break;
	case 3:
		time = this1 - 62135596800.0 - period.n * 3600;
		break;
	case 4:
		time = this1 - 62135596800.0 - period.n * 60;
		break;
	case 5:
		time = this1 - 62135596800.0 - period.n;
		break;
	case 6:
		time = this1 - 62135596800.0 - period.n * 7 * 86400;
		break;
	}
	return time + 62135596800.0;
};
datetime_DateTime.snap = function(this1,period) {
	var time;
	switch(period._hx_index) {
	case 0:
		time = datetime_utils_DateTimeSnapUtils.snapYear(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 1:
		time = datetime_utils_DateTimeSnapUtils.snapMonth(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 2:
		time = datetime_utils_DateTimeSnapUtils.snapDay(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 3:
		time = datetime_utils_DateTimeSnapUtils.snapHour(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 4:
		time = datetime_utils_DateTimeSnapUtils.snapMinute(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 5:
		time = period.direction == 1 ? this1 - 62135596800.0 + 1 : this1 - 62135596800.0;
		break;
	case 6:
		time = datetime_utils_DateTimeSnapUtils.snapWeek(this1 - 62135596800.0 + 62135596800.0,period.direction,period.day);
		break;
	}
	return time + 62135596800.0;
};
datetime_DateTime.toString = function(this1) {
	var M = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	var D = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	var h = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
	var m = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
	var s = this1 - Math.floor(this1 / 60) * 60 | 0;
	return "" + datetime_DateTime.getYear(this1) + "-" + (M < 10 ? "0" + M : "" + M) + "-" + (D < 10 ? "0" + D : "" + D) + " " + (h < 10 ? "0" + h : "" + h) + ":" + (m < 10 ? "0" + m : "" + m) + ":" + (s < 10 ? "0" + s : "" + s);
};
datetime_DateTime.format = function(this1,format) {
	return datetime_utils_DateTimeUtils.strftime(this1 - 62135596800.0 + 62135596800.0,format);
};
datetime_DateTime.getTime = function(this1) {
	return this1 - 62135596800.0;
};
datetime_DateTime.getDate = function(this1) {
	return new Date((this1 - 62135596800.0) * 1000);
};
datetime_DateTime.gt = function(this1,dt) {
	return this1 - 62135596800.0 > dt - 62135596800.0;
};
datetime_DateTime.gte = function(this1,dt) {
	return this1 - 62135596800.0 >= dt - 62135596800.0;
};
datetime_DateTime.lt = function(this1,dt) {
	return this1 - 62135596800.0 < dt - 62135596800.0;
};
datetime_DateTime.lte = function(this1,dt) {
	return this1 - 62135596800.0 <= dt - 62135596800.0;
};
datetime_DateTime.eq = function(this1,dt) {
	return this1 - 62135596800.0 == dt - 62135596800.0;
};
datetime_DateTime.neq = function(this1,dt) {
	return this1 - 62135596800.0 != dt - 62135596800.0;
};
datetime_DateTime.mathPlus1 = function(this1,period) {
	return datetime_DateTime.add(this1,period);
};
datetime_DateTime.mathPlus2 = function(this1,period) {
	return datetime_DateTime.add(this1,period);
};
datetime_DateTime.mathPlus3 = function(this1,period) {
	this1 = datetime_DateTime.add(this1,period) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
datetime_DateTime.mathMinus1 = function(this1,period) {
	return datetime_DateTime.sub(this1,period);
};
datetime_DateTime.mathMinus2 = function(this1,period) {
	this1 = datetime_DateTime.sub(this1,period) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
datetime_DateTime.dtiCreate = function(this1,begin) {
	return datetime_DateTimeInterval.create(begin,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiMinus = function(this1,dti) {
	return datetime_DateTimeInterval.subFrom(dti,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiPlus1 = function(this1,dti) {
	return datetime_DateTimeInterval.addTo(dti,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiPlus2 = function(this1,dti) {
	return datetime_DateTimeInterval.addTo(dti,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiMinus2 = function(this1,dti) {
	this1 = datetime_DateTimeInterval.subFrom(dti,this1 - 62135596800.0 + 62135596800.0) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
datetime_DateTime.dtiPlus3 = function(this1,dti) {
	this1 = datetime_DateTimeInterval.addTo(dti,this1 - 62135596800.0 + 62135596800.0) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
var datetime_DateTimeInterval = {};
datetime_DateTimeInterval.create = function(begin,end) {
	var dtic = new datetime_cores_DateTimeIntervalCore();
	dtic.begin = end - 62135596800.0 < begin - 62135596800.0 ? end : begin;
	dtic.end = end - 62135596800.0 < begin - 62135596800.0 ? begin : end;
	dtic.negative = end - 62135596800.0 < begin - 62135596800.0;
	return dtic;
};
datetime_DateTimeInterval._new = function(dtic) {
	return dtic;
};
datetime_DateTimeInterval.invert = function(this1) {
	this1.negative = !this1.negative;
	return this1;
};
datetime_DateTimeInterval.addTo = function(this1,dt) {
	return dt - 62135596800.0 + (this1.negative ? -1 : 1) * (this1.end - 62135596800.0 - (this1.begin - 62135596800.0)) + 62135596800.0;
};
datetime_DateTimeInterval.subFrom = function(this1,dt) {
	return dt - 62135596800.0 - (this1.negative ? -1 : 1) * (this1.end - 62135596800.0 - (this1.begin - 62135596800.0)) + 62135596800.0;
};
datetime_DateTimeInterval.toString = function(this1) {
	var years = this1.getYears();
	var months = this1.getMonths();
	var days = this1.getDays();
	var hours = this1.getHours();
	var minutes = this1.getMinutes();
	var seconds = this1.getSeconds();
	var parts = [];
	if(years != 0) {
		parts.push("" + years + "y");
	}
	if(months != 0) {
		parts.push("" + months + "m");
	}
	if(days != 0) {
		parts.push("" + days + "d");
	}
	if(hours != 0) {
		parts.push("" + hours + "hrs");
	}
	if(minutes != 0) {
		parts.push("" + minutes + "min");
	}
	if(seconds != 0) {
		parts.push("" + seconds + "sec");
	}
	return (this1.negative ? "-" : "") + "(" + (parts.length == 0 ? "0sec" : parts.join(", ")) + ")";
};
datetime_DateTimeInterval.sign = function(this1) {
	if(this1.negative) {
		return -1;
	} else {
		return 1;
	}
};
datetime_DateTimeInterval.format = function(this1,format) {
	return datetime_utils_DateTimeIntervalUtils.strftime(this1,format);
};
datetime_DateTimeInterval.formatPartial = function(this1,format) {
	return datetime_utils_DateTimeIntervalUtils.formatPartial(this1,format);
};
datetime_DateTimeInterval.eq = function(this1,dtic) {
	if(this1.negative == dtic.negative) {
		return this1.getTotalSeconds() == dtic.getTotalSeconds();
	} else {
		return false;
	}
};
datetime_DateTimeInterval.gt = function(this1,dtic) {
	if(this1.negative != dtic.negative) {
		return dtic.negative;
	}
	var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
	if(this1.negative) {
		return delta < 0;
	} else {
		return delta > 0;
	}
};
datetime_DateTimeInterval.gte = function(this1,dtic) {
	if(!(this1.negative == dtic.negative && this1.getTotalSeconds() == dtic.getTotalSeconds())) {
		if(this1.negative != dtic.negative) {
			return dtic.negative;
		} else {
			var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
			if(this1.negative) {
				return delta < 0;
			} else {
				return delta > 0;
			}
		}
	} else {
		return true;
	}
};
datetime_DateTimeInterval.lt = function(this1,dtic) {
	var tmp;
	if(!(this1.negative == dtic.negative && this1.getTotalSeconds() == dtic.getTotalSeconds())) {
		if(this1.negative != dtic.negative) {
			tmp = dtic.negative;
		} else {
			var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
			tmp = this1.negative ? delta < 0 : delta > 0;
		}
	} else {
		tmp = true;
	}
	return !tmp;
};
datetime_DateTimeInterval.lte = function(this1,dtic) {
	var tmp;
	if(this1.negative != dtic.negative) {
		tmp = dtic.negative;
	} else {
		var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
		tmp = this1.negative ? delta < 0 : delta > 0;
	}
	return !tmp;
};
datetime_DateTimeInterval.neq = function(this1,dtic) {
	return !(this1.negative == dtic.negative && this1.getTotalSeconds() == dtic.getTotalSeconds());
};
var datetime_cores_DateTimeIntervalCore = function() {
	this.seconds = -1;
	this.minutes = -1;
	this.hours = -1;
	this.days = -1;
	this.months = -1;
	this.years = -1;
	this.negative = false;
};
datetime_cores_DateTimeIntervalCore.__name__ = true;
datetime_cores_DateTimeIntervalCore.prototype = {
	getYears: function() {
		if(this.years < 0) {
			this.years = datetime_DateTime.getYear(this.end) - datetime_DateTime.getYear(this.begin);
			var this1 = this.begin;
			var m1 = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var m2 = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			if(m2 < m1) {
				this.years--;
			} else if(m1 == m2) {
				var this1 = this.begin;
				var d1 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
				var this1 = this.end;
				var d2 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
				if(d2 < d1) {
					this.years--;
				} else if(d1 == d2) {
					var this1 = this.begin;
					var h1 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
					var this1 = this.end;
					var h2 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
					if(h2 < h1) {
						this.years--;
					} else if(h2 == h1) {
						var this1 = this.begin;
						var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
						var this1 = this.end;
						var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
						if(m2 < m1) {
							this.years--;
						} else {
							var tmp;
							if(m2 == m1) {
								var this1 = this.end;
								var this2 = this.begin;
								tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
							} else {
								tmp = false;
							}
							if(tmp) {
								this.years--;
							}
						}
					}
				}
			}
		}
		return this.years;
	}
	,getMonths: function() {
		if(this.months < 0) {
			var this1 = this.begin;
			var monthBegin = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var monthEnd = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			this.months = monthBegin <= monthEnd ? monthEnd - monthBegin : 12 - monthBegin + monthEnd;
			var this1 = this.begin;
			var d1 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var d2 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			if(d2 < d1) {
				this.months--;
			} else if(d1 == d2) {
				var this1 = this.begin;
				var h1 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
				var this1 = this.end;
				var h2 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
				if(h2 < h1) {
					this.months--;
				} else if(h2 == h1) {
					var this1 = this.begin;
					var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
					var this1 = this.end;
					var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
					if(m2 < m1) {
						this.months--;
					} else {
						var tmp;
						if(m2 == m1) {
							var this1 = this.end;
							var this2 = this.begin;
							tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
						} else {
							tmp = false;
						}
						if(tmp) {
							this.months--;
						}
					}
				}
			}
		}
		return this.months;
	}
	,getTotalMonths: function() {
		return this.getYears() * 12 + this.getMonths();
	}
	,getDays: function() {
		if(this.days < 0) {
			var this1 = this.begin;
			var dayBegin = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var dayEnd = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var tmp;
			if(dayBegin <= dayEnd) {
				tmp = dayEnd - dayBegin;
			} else {
				var this1 = this.begin;
				tmp = datetime_utils_DateTimeMonthUtils.days(datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1)),datetime_DateTime.isLeapYear(this.begin)) - dayBegin + dayEnd;
			}
			this.days = tmp;
			var this1 = this.begin;
			var h1 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			var this1 = this.end;
			var h2 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			if(h2 < h1) {
				this.days--;
			} else if(h2 == h1) {
				var this1 = this.begin;
				var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
				var this1 = this.end;
				var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
				if(m2 < m1) {
					this.days--;
				} else {
					var tmp;
					if(m2 == m1) {
						var this1 = this.end;
						var this2 = this.begin;
						tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
					} else {
						tmp = false;
					}
					if(tmp) {
						this.days--;
					}
				}
			}
		}
		return this.days;
	}
	,getTotalDays: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 86400 | 0;
	}
	,getHours: function() {
		if(this.hours < 0) {
			var this1 = this.begin;
			var hourBegin = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			var this1 = this.end;
			var hourEnd = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			this.hours = hourBegin <= hourEnd ? hourEnd - hourBegin : 24 - hourBegin + hourEnd;
			var this1 = this.begin;
			var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			var this1 = this.end;
			var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			if(m2 < m1) {
				this.hours--;
			} else {
				var tmp;
				if(m2 == m1) {
					var this1 = this.end;
					var this2 = this.begin;
					tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
				} else {
					tmp = false;
				}
				if(tmp) {
					this.hours--;
				}
			}
		}
		return this.hours;
	}
	,getTotalHours: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 3600 | 0;
	}
	,getMinutes: function() {
		if(this.minutes < 0) {
			var this1 = this.begin;
			var minuteBegin = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			var this1 = this.end;
			var minuteEnd = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			this.minutes = minuteBegin <= minuteEnd ? minuteEnd - minuteBegin : 60 - minuteBegin + minuteEnd;
			var this1 = this.end;
			var this2 = this.begin;
			if((this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0)) {
				this.minutes--;
			}
		}
		return this.minutes;
	}
	,getTotalMinutes: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 60 | 0;
	}
	,getSeconds: function() {
		if(this.seconds < 0) {
			var this1 = this.begin;
			var secondBegin = this1 - Math.floor(this1 / 60) * 60 | 0;
			var this1 = this.end;
			var secondEnd = this1 - Math.floor(this1 / 60) * 60 | 0;
			this.seconds = secondBegin <= secondEnd ? secondEnd - secondBegin : 60 - secondBegin + secondEnd;
		}
		return this.seconds;
	}
	,getTotalSeconds: function() {
		return this.end - 62135596800.0 - (this.begin - 62135596800.0);
	}
	,getTotalWeeks: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 604800 | 0;
	}
};
var datetime_utils_DateTimeIntervalUtils = function() {
};
datetime_utils_DateTimeIntervalUtils.__name__ = true;
datetime_utils_DateTimeIntervalUtils.strftime = function(dti,format) {
	var prevPos = 0;
	var pos = format.indexOf("%");
	var str = "";
	while(pos >= 0) {
		str += format.substring(prevPos,pos);
		++pos;
		switch(format.charCodeAt(pos)) {
		case 37:
			str += "%";
			break;
		case 68:
			str += StringTools.lpad(dti.getDays() + "","0",2);
			break;
		case 72:
			str += StringTools.lpad(dti.getHours() + "","0",2);
			break;
		case 73:
			str += StringTools.lpad(dti.getMinutes() + "","0",2);
			break;
		case 77:
			str += StringTools.lpad(dti.getMonths() + "","0",2);
			break;
		case 82:
			str += dti.negative ? "-" : "+";
			break;
		case 83:
			str += StringTools.lpad(dti.getSeconds() + "","0",2);
			break;
		case 89:
			str += StringTools.lpad(dti.getYears() + "","0",2);
			break;
		case 97:
			str += dti.getTotalDays() + "";
			break;
		case 98:
			str += dti.getTotalMonths() + "";
			break;
		case 99:
			str += dti.getTotalHours() + "";
			break;
		case 100:
			str += dti.getDays() + "";
			break;
		case 101:
			str += dti.getTotalMinutes() + "";
			break;
		case 102:
			str += dti.getTotalSeconds() + "";
			break;
		case 104:
			str += dti.getHours() + "";
			break;
		case 105:
			str += dti.getMinutes() + "";
			break;
		case 109:
			str += dti.getMonths() + "";
			break;
		case 114:
			str += dti.negative ? "-" : "";
			break;
		case 115:
			str += dti.getSeconds() + "";
			break;
		case 121:
			str += dti.getYears() + "";
			break;
		}
		prevPos = pos + 1;
		pos = format.indexOf("%",pos + 1);
	}
	str += format.substring(prevPos);
	return str;
};
datetime_utils_DateTimeIntervalUtils.formatPartial = function(dti,format) {
	var result = [];
	var pos = 0;
	var str = "";
	var _g = 0;
	var _g1 = format.length;
	while(_g < _g1) {
		var f = _g++;
		pos = format[f].indexOf("%");
		if(pos >= 0) {
			switch(format[f].charCodeAt(pos + 1)) {
			case 68:
				if(dti.getDays() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getDays() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 72:
				if(dti.getHours() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getHours() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 73:
				if(dti.getMinutes() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getMinutes() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 77:
				if(dti.getMonths() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getMonths() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 83:
				if(dti.getSeconds() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getSeconds() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 89:
				if(dti.getYears() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getYears() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 97:
				if(dti.getTotalDays() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalDays() + format[f].substring(pos + 2);
				break;
			case 98:
				if(dti.getTotalMonths() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalMonths() + format[f].substring(pos + 2);
				break;
			case 99:
				if(dti.getTotalHours() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalHours() + format[f].substring(pos + 2);
				break;
			case 100:
				if(dti.getDays() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getDays() + format[f].substring(pos + 2);
				break;
			case 101:
				if(dti.getTotalMinutes() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalMinutes() + format[f].substring(pos + 2);
				break;
			case 102:
				if(dti.getTotalSeconds() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalSeconds() + format[f].substring(pos + 2);
				break;
			case 104:
				if(dti.getHours() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getHours() + format[f].substring(pos + 2);
				break;
			case 105:
				if(dti.getMinutes() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getMinutes() + format[f].substring(pos + 2);
				break;
			case 109:
				if(dti.getMonths() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getMonths() + format[f].substring(pos + 2);
				break;
			case 115:
				if(dti.getSeconds() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getSeconds() + format[f].substring(pos + 2);
				break;
			case 121:
				if(dti.getYears() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getYears() + format[f].substring(pos + 2);
				break;
			default:
				continue;
			}
			result.push(str);
		}
	}
	return result;
};
var datetime_utils_DateTimeMonthUtils = function() {
};
datetime_utils_DateTimeMonthUtils.__name__ = true;
datetime_utils_DateTimeMonthUtils.days = function(month,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	if(month == 1) {
		return 31;
	} else if(month == 2 && isLeapYear) {
		return 29;
	} else if(month == 2) {
		return 28;
	} else if(month == 3) {
		return 31;
	} else if(month == 4) {
		return 30;
	} else if(month == 5) {
		return 31;
	} else if(month == 6) {
		return 30;
	} else if(month == 7) {
		return 31;
	} else if(month == 8) {
		return 31;
	} else if(month == 9) {
		return 30;
	} else if(month == 10) {
		return 31;
	} else if(month == 11) {
		return 30;
	} else {
		return 31;
	}
};
datetime_utils_DateTimeMonthUtils.getMonth = function(days,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	if(days < 32) {
		return 1;
	} else if(isLeapYear) {
		if(days < 61) {
			return 2;
		} else if(days < 92) {
			return 3;
		} else if(days < 122) {
			return 4;
		} else if(days < 153) {
			return 5;
		} else if(days < 183) {
			return 6;
		} else if(days < 214) {
			return 7;
		} else if(days < 245) {
			return 8;
		} else if(days < 275) {
			return 9;
		} else if(days < 306) {
			return 10;
		} else if(days < 336) {
			return 11;
		} else {
			return 12;
		}
	} else if(days < 60) {
		return 2;
	} else if(days < 91) {
		return 3;
	} else if(days < 121) {
		return 4;
	} else if(days < 152) {
		return 5;
	} else if(days < 182) {
		return 6;
	} else if(days < 213) {
		return 7;
	} else if(days < 244) {
		return 8;
	} else if(days < 274) {
		return 9;
	} else if(days < 305) {
		return 10;
	} else if(days < 335) {
		return 11;
	} else {
		return 12;
	}
};
datetime_utils_DateTimeMonthUtils.getMonthDay = function(days,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	if(days < 32) {
		return days;
	} else if(isLeapYear) {
		if(days < 61) {
			return days - 31;
		} else if(days < 92) {
			return days - 60;
		} else if(days < 122) {
			return days - 91;
		} else if(days < 153) {
			return days - 121;
		} else if(days < 183) {
			return days - 152;
		} else if(days < 214) {
			return days - 182;
		} else if(days < 245) {
			return days - 213;
		} else if(days < 275) {
			return days - 244;
		} else if(days < 306) {
			return days - 274;
		} else if(days < 336) {
			return days - 305;
		} else {
			return days - 335;
		}
	} else if(days < 60) {
		return days - 31;
	} else if(days < 91) {
		return days - 59;
	} else if(days < 121) {
		return days - 90;
	} else if(days < 152) {
		return days - 120;
	} else if(days < 182) {
		return days - 151;
	} else if(days < 213) {
		return days - 181;
	} else if(days < 244) {
		return days - 212;
	} else if(days < 274) {
		return days - 243;
	} else if(days < 305) {
		return days - 273;
	} else if(days < 335) {
		return days - 304;
	} else {
		return days - 334;
	}
};
datetime_utils_DateTimeMonthUtils.toSeconds = function(month,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	return 86400 * (month == 1 ? 0 : isLeapYear ? month == 2 ? 31 : month == 3 ? 60 : month == 4 ? 91 : month == 5 ? 121 : month == 6 ? 152 : month == 7 ? 182 : month == 8 ? 213 : month == 9 ? 244 : month == 10 ? 274 : month == 11 ? 305 : 335 : month == 2 ? 31 : month == 3 ? 59 : month == 4 ? 90 : month == 5 ? 120 : month == 6 ? 151 : month == 7 ? 181 : month == 8 ? 212 : month == 9 ? 243 : month == 10 ? 273 : month == 11 ? 304 : 334);
};
var datetime_utils_DateTimeSnapUtils = function() {
};
datetime_utils_DateTimeSnapUtils.__name__ = true;
datetime_utils_DateTimeSnapUtils.snapYear = function(dt,direction) {
	switch(direction) {
	case -1:
		return datetime_DateTime.yearStart(dt);
	case 0:
		var next = datetime_DateTime.yearStart(datetime_utils_DateTimeUtils.addYear(dt,1) + 62135596800.0);
		var previous = datetime_DateTime.yearStart(dt);
		if(next - (dt - 62135596800.0) > dt - 62135596800.0 - previous) {
			return previous;
		} else {
			return next;
		}
		break;
	case 1:
		return datetime_DateTime.yearStart(datetime_utils_DateTimeUtils.addYear(dt,1) + 62135596800.0);
	}
};
datetime_utils_DateTimeSnapUtils.snapMonth = function(dt,direction) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	var isLeap = datetime_DateTime.isLeapYear(dt);
	switch(direction) {
	case -1:
		return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap);
	case 0:
		var previous = datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap);
		var next = datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + datetime_utils_DateTimeMonthUtils.days(month,isLeap) * 86400;
		if(next - (dt - 62135596800.0) > dt - 62135596800.0 - previous) {
			return previous;
		} else {
			return next;
		}
		break;
	case 1:
		return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + datetime_utils_DateTimeMonthUtils.days(month,isLeap) * 86400;
	}
};
datetime_utils_DateTimeSnapUtils.snapDay = function(dt,direction) {
	var days = (dt - 62135596800.0) / 86400;
	switch(direction) {
	case -1:
		return Math.floor(days) * 86400;
	case 0:
		return Math.round(days) * 86400;
	case 1:
		return Math.ceil(days) * 86400;
	}
};
datetime_utils_DateTimeSnapUtils.snapHour = function(dt,direction) {
	var hours = (dt - 62135596800.0) / 3600;
	switch(direction) {
	case -1:
		return Math.floor(hours) * 3600;
	case 0:
		return Math.round(hours) * 3600;
	case 1:
		return Math.ceil(hours) * 3600;
	}
};
datetime_utils_DateTimeSnapUtils.snapMinute = function(dt,direction) {
	var minutes = (dt - 62135596800.0) / 60;
	switch(direction) {
	case -1:
		return Math.floor(minutes) * 60;
	case 0:
		return Math.round(minutes) * 60;
	case 1:
		return Math.ceil(minutes) * 60;
	}
};
datetime_utils_DateTimeSnapUtils.snapWeek = function(dt,direction,required) {
	var current = datetime_DateTime.getWeekDay(dt);
	var days = Math.floor((dt - 62135596800.0) / 86400);
	switch(direction) {
	case -1:
		return (days - (current >= required ? current - required : current + 7 - required)) * 86400;
	case 0:
		var previous = (days - (current >= required ? current - required : current + 7 - required)) * 86400;
		var next = (days + (required > current ? required - current : required + 7 - current)) * 86400;
		if(next - (dt - 62135596800.0) > dt - 62135596800.0 - previous) {
			return previous;
		} else {
			return next;
		}
		break;
	case 1:
		return (days + (required > current ? required - current : required + 7 - current)) * 86400;
	}
};
var datetime_utils_DateTimeUtils = function() {
};
datetime_utils_DateTimeUtils.__name__ = true;
datetime_utils_DateTimeUtils.fromString = function(str) {
	if(str.length == 10 || str.charCodeAt(10) == 32) {
		return datetime_utils_DateTimeUtils.parse(str);
	} else if(str.charCodeAt(10) == 84) {
		return datetime_utils_DateTimeUtils.fromIsoString(str);
	} else {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD` or `YYYY-MM-DDThh:mm:ss[.SSS]Z`");
	}
};
datetime_utils_DateTimeUtils.parse = function(str) {
	var ylength = str.indexOf("-");
	if(ylength < 1 || str.length - ylength != 6 && str.length - ylength != 15) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD`");
	}
	if(str.length - ylength == 6) {
		str += " 00:00:00";
	}
	var year = Std.parseInt(HxOverrides.substr(str,0,ylength));
	var month = Std.parseInt(HxOverrides.substr(str,ylength + 1,2));
	var day = Std.parseInt(HxOverrides.substr(str,ylength + 4,2));
	var hour = Std.parseInt(HxOverrides.substr(str,ylength + 7,2));
	var minute = Std.parseInt(HxOverrides.substr(str,ylength + 10,2));
	var second = Std.parseInt(HxOverrides.substr(str,ylength + 13,2));
	if(year == null || month == null || day == null || hour == null || minute == null || second == null) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD`");
	}
	var year1 = year;
	var month1 = month;
	var day1 = day;
	var hour1 = hour;
	var minute1 = minute;
	var second1 = second;
	if(second == null) {
		second1 = 0;
	}
	if(minute == null) {
		minute1 = 0;
	}
	if(hour == null) {
		hour1 = 0;
	}
	if(day == null) {
		day1 = 1;
	}
	if(month == null) {
		month1 = 1;
	}
	if(year == null) {
		year1 = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year1) + datetime_utils_DateTimeMonthUtils.toSeconds(month1,year1 % 4 == 0 && (year1 % 100 == 0 ? year1 % 400 == 0 : true)) + (day1 - 1) * 86400 + hour1 * 3600 + minute1 * 60 + second1 - 62135596800.0 + 62135596800.0;
};
datetime_utils_DateTimeUtils.fromIsoString = function(str) {
	if(str.charCodeAt(str.length - 1) != 90) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 UTC/Zulu string: Z not found.");
	}
	if(str.length > 20) {
		if(str.charCodeAt(19) != 46) {
			throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 string: Millisecond specification erroneous.");
		}
		if(str.charCodeAt(23) != 90) {
			throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 string: Timezone specification erroneous.");
		}
	}
	return datetime_utils_DateTimeUtils.parse(HxOverrides.substr(str,0,10) + " " + HxOverrides.substr(str,11,8));
};
datetime_utils_DateTimeUtils.clamp = function(value,min,max) {
	if(value < min) {
		return min;
	} else if(value > max) {
		return max;
	} else {
		return value;
	}
};
datetime_utils_DateTimeUtils.yearToStamp = function(year) {
	--year;
	var cquads = year / 400 | 0;
	var quads = (year - cquads * 400) / 4 | 0;
	return cquads * 12622780800.0 + quads * 126230400.0 - (quads / 25 | 0) * 86400 + (year - cquads * 400 - quads * 4) * 31536000;
};
datetime_utils_DateTimeUtils.addYear = function(dt,amount) {
	var year = datetime_DateTime.getYear(dt) + amount;
	return datetime_utils_DateTimeUtils.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)),year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (dt - 62135596800.0 - (datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)),datetime_DateTime.isLeapYear(dt)))) - 62135596800.0;
};
datetime_utils_DateTimeUtils.addMonth = function(dt,amount) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + amount;
	if(month > 12) {
		var years = month / 12 | 0;
		dt = datetime_utils_DateTimeUtils.addYear(dt,years) + 62135596800.0;
		month -= years * 12;
	} else if(month <= 0) {
		var years = (month / 12 | 0) - 1;
		dt = datetime_utils_DateTimeUtils.addYear(dt,years) + 62135596800.0;
		month -= years * 12;
	}
	var isLeap = datetime_DateTime.isLeapYear(dt);
	var value = datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	var max = datetime_utils_DateTimeMonthUtils.days(month,isLeap);
	return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + ((value < 1 ? 1 : value > max ? max : value) - 1) * 86400 + ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) * 3600 + ((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) * 60 + (dt - Math.floor(dt / 60) * 60 | 0);
};
datetime_utils_DateTimeUtils.getWeekDayNum = function(dt,weekDay,num) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	if(num > 0) {
		return datetime_DateTime.add(datetime_DateTime.snap(datetime_DateTime.monthStart(dt,month) - 1 + 62135596800.0,datetime_DTSnap.Week(1,weekDay)),datetime_DTPeriod.Week(num - 1)) - 62135596800.0;
	} else if(num < 0) {
		return datetime_DateTime.add(datetime_DateTime.snap(datetime_DateTime.monthStart(dt,month + 1) - 1 + 62135596800.0,datetime_DTSnap.Week(-1,weekDay)),datetime_DTPeriod.Week(num + 1)) - 62135596800.0;
	} else {
		return dt - 62135596800.0;
	}
};
datetime_utils_DateTimeUtils.strftime = function(dt,format) {
	var prevPos = 0;
	var pos = format.indexOf("%");
	var str = "";
	while(pos >= 0) {
		str += format.substring(prevPos,pos);
		++pos;
		switch(format.charCodeAt(pos)) {
		case 37:
			str += "%";
			break;
		case 67:
			str += StringTools.lpad((datetime_DateTime.getYear(dt) / 100 | 0) + "","0",2);
			break;
		case 68:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "/","0",3) + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "/","0",3) + StringTools.lpad(HxOverrides.substr(datetime_DateTime.getYear(dt) + "",-2,null),"0",2);
			break;
		case 70:
			str += datetime_DateTime.getYear(dt) + "-" + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "-","0",3) + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 72:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + "","0",2);
			break;
		case 73:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + "","0",2);
			break;
		case 77:
			str += StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + "","0",2);
			break;
		case 80:
			str += ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) < 12 ? "am" : "pm";
			break;
		case 82:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + "","0",2);
			break;
		case 83:
			str += StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 84:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + ":","0",3) + StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 86:
			str += StringTools.lpad(datetime_DateTime.getWeek(dt) + "","0",2);
			break;
		case 89:
			str += datetime_DateTime.getYear(dt) + "";
			break;
		case 100:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 101:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + ""," ",2);
			break;
		case 106:
			str += StringTools.lpad("" + (((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1),"0",3);
			break;
		case 107:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ""," ",2);
			break;
		case 108:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + ""," ",2);
			break;
		case 109:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 112:
			str += ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) < 12 ? "AM" : "PM";
			break;
		case 114:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + ":","0",3) + StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 115:
			str += dt - 62135596800.0 + "";
			break;
		case 117:
			str += datetime_DateTime.getWeekDay(dt,true) + "";
			break;
		case 119:
			str += datetime_DateTime.getWeekDay(dt) + "";
			break;
		case 121:
			str += StringTools.lpad(HxOverrides.substr(datetime_DateTime.getYear(dt) + "",-2,null),"0",2);
			break;
		}
		prevPos = pos + 1;
		pos = format.indexOf("%",pos + 1);
	}
	str += format.substring(prevPos);
	return str;
};
var formula_ValueType = $hxEnums["formula.ValueType"] = { __ename__:true,__constructs__:null
	,TNumber: {_hx_name:"TNumber",_hx_index:0,__enum__:"formula.ValueType",toString:$estr}
	,TBoolean: {_hx_name:"TBoolean",_hx_index:1,__enum__:"formula.ValueType",toString:$estr}
	,TDate: {_hx_name:"TDate",_hx_index:2,__enum__:"formula.ValueType",toString:$estr}
	,TDateRange: {_hx_name:"TDateRange",_hx_index:3,__enum__:"formula.ValueType",toString:$estr}
	,TList: ($_=function(elementType) { return {_hx_index:4,elementType:elementType,__enum__:"formula.ValueType",toString:$estr}; },$_._hx_name="TList",$_.__params__ = ["elementType"],$_)
	,TUser: {_hx_name:"TUser",_hx_index:5,__enum__:"formula.ValueType",toString:$estr}
	,TSelect: {_hx_name:"TSelect",_hx_index:6,__enum__:"formula.ValueType",toString:$estr}
	,TText: {_hx_name:"TText",_hx_index:7,__enum__:"formula.ValueType",toString:$estr}
	,TFile: {_hx_name:"TFile",_hx_index:8,__enum__:"formula.ValueType",toString:$estr}
	,TRollup: {_hx_name:"TRollup",_hx_index:9,__enum__:"formula.ValueType",toString:$estr}
	,TAny: {_hx_name:"TAny",_hx_index:10,__enum__:"formula.ValueType",toString:$estr}
	,TVar: ($_=function(id) { return {_hx_index:11,id:id,__enum__:"formula.ValueType",toString:$estr}; },$_._hx_name="TVar",$_.__params__ = ["id"],$_)
	,TExist: ($_=function(exist) { return {_hx_index:12,exist:exist,__enum__:"formula.ValueType",toString:$estr}; },$_._hx_name="TExist",$_.__params__ = ["exist"],$_)
	,TNull: {_hx_name:"TNull",_hx_index:13,__enum__:"formula.ValueType",toString:$estr}
};
formula_ValueType.__constructs__ = [formula_ValueType.TNumber,formula_ValueType.TBoolean,formula_ValueType.TDate,formula_ValueType.TDateRange,formula_ValueType.TList,formula_ValueType.TUser,formula_ValueType.TSelect,formula_ValueType.TText,formula_ValueType.TFile,formula_ValueType.TRollup,formula_ValueType.TAny,formula_ValueType.TVar,formula_ValueType.TExist,formula_ValueType.TNull];
var formula_Value = $hxEnums["formula.Value"] = { __ename__:true,__constructs__:null
	,VNumber: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VNumber",$_.__params__ = ["value"],$_)
	,VBoolean: ($_=function(value) { return {_hx_index:1,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VBoolean",$_.__params__ = ["value"],$_)
	,VDate: ($_=function(value) { return {_hx_index:2,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VDate",$_.__params__ = ["value"],$_)
	,VDateRange: ($_=function(value) { return {_hx_index:3,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VDateRange",$_.__params__ = ["value"],$_)
	,VUser: ($_=function(value) { return {_hx_index:4,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VUser",$_.__params__ = ["value"],$_)
	,VSelect: ($_=function(value) { return {_hx_index:5,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VSelect",$_.__params__ = ["value"],$_)
	,VFile: ($_=function(value) { return {_hx_index:6,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VFile",$_.__params__ = ["value"],$_)
	,VRollup: ($_=function(value) { return {_hx_index:7,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VRollup",$_.__params__ = ["value"],$_)
	,VList: ($_=function(value) { return {_hx_index:8,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VList",$_.__params__ = ["value"],$_)
	,VNull: {_hx_name:"VNull",_hx_index:9,__enum__:"formula.Value",toString:$estr}
	,VText: ($_=function(value) { return {_hx_index:10,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VText",$_.__params__ = ["value"],$_)
};
formula_Value.__constructs__ = [formula_Value.VNumber,formula_Value.VBoolean,formula_Value.VDate,formula_Value.VDateRange,formula_Value.VUser,formula_Value.VSelect,formula_Value.VFile,formula_Value.VRollup,formula_Value.VList,formula_Value.VNull,formula_Value.VText];
var formula_ValueTool = $hx_exports["formula"]["ValueTool"] = function() { };
formula_ValueTool.__name__ = true;
formula_ValueTool.typeOf = function(value) {
	switch(value._hx_index) {
	case 0:
		return formula_ValueType.TNumber;
	case 1:
		return formula_ValueType.TBoolean;
	case 2:
		return formula_ValueType.TDate;
	case 3:
		return formula_ValueType.TDateRange;
	case 4:
		return formula_ValueType.TUser;
	case 5:
		return formula_ValueType.TSelect;
	case 6:
		return formula_ValueType.TFile;
	case 7:
		return formula_ValueType.TRollup;
	case 8:
		return formula_ValueType.TList(value.value.elementType);
	case 9:
		return formula_ValueType.TNull;
	case 10:
		return formula_ValueType.TText;
	}
};
formula_ValueTool.isListType = function(valueType) {
	if(valueType._hx_index == 4) {
		return true;
	} else {
		return false;
	}
};
formula_ValueTool.getListElementType = function(valueType) {
	if(valueType._hx_index == 4) {
		return valueType.elementType;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.number = function(value) {
	return formula_Value.VNumber(value);
};
formula_ValueTool.string = function(value) {
	return formula_Value.VText(formula_Text.fromString(value));
};
formula_ValueTool.boolean = function(value) {
	return formula_Value.VBoolean(value);
};
formula_ValueTool.date = function(value) {
	return formula_Value.VDate(value);
};
formula_ValueTool.dateRange = function(start,end) {
	return formula_Value.VDateRange(new formula_DateRangeValue(start,end));
};
formula_ValueTool.user = function(uuid,name,email) {
	return formula_Value.VUser(new formula_User(uuid,name,email));
};
formula_ValueTool.select = function(name,color) {
	return formula_Value.VSelect(new formula_Select(name,color));
};
formula_ValueTool.file = function(name,ossName) {
	return formula_Value.VFile(new formula_File(name,ossName));
};
formula_ValueTool.list = function(elementType,elements) {
	return formula_Value.VList(new formula_ListValue(elementType,elements));
};
formula_ValueTool.text = function(content,enhance) {
	return formula_Value.VText(formula_Text.fromString(content,enhance));
};
formula_ValueTool.elementUser = function(uuid,name,email) {
	return new formula_User(uuid,name,email);
};
formula_ValueTool.elementSelect = function(name,color) {
	return new formula_Select(name,color);
};
formula_ValueTool.elementFile = function(name,ossName) {
	return new formula_File(name,ossName);
};
formula_ValueTool.fromDynamic = function(value) {
	if(((value) instanceof Date)) {
		return formula_Value.VDate(value);
	}
	if(typeof(value) == "string") {
		return formula_Value.VText(formula_Text.fromString(value));
	}
	if(typeof(value) == "boolean") {
		return formula_Value.VBoolean(value);
	}
	if(((value) instanceof formula_User)) {
		return formula_Value.VUser(value);
	}
	if(((value) instanceof formula_Select)) {
		return formula_Value.VSelect(value);
	}
	if(((value) instanceof formula_File)) {
		return formula_Value.VFile(value);
	}
	if(((value) instanceof formula_Text)) {
		return formula_Value.VText(value);
	}
	return formula_Value.VNumber(value);
};
formula_ValueTool.toDynamic = function(value) {
	switch(value._hx_index) {
	case 0:
		return value.value;
	case 1:
		return value.value;
	case 2:
		return value.value;
	case 4:
		return value.value;
	case 5:
		return value.value;
	case 6:
		return value.value;
	case 8:
		return value.value;
	case 10:
		return value.value;
	default:
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asNumber = function(value) {
	switch(value._hx_index) {
	case 0:
		return value.value;
	case 10:
		var value1 = StringTools.trim(value.value.plainText());
		if(StringTools.startsWith(value1,"\"") && StringTools.endsWith(value1,"\"")) {
			value1 = value1.substring(1,value1.length - 1);
		}
		var num = parseFloat(value1);
		if(isNaN(num)) {
			return null;
		} else {
			return num;
		}
		break;
	default:
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asString = function(value) {
	if(value == null) {
		return "";
	} else if(value._hx_index == 10) {
		return value.value.plainText();
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asBoolean = function(value) {
	if(value == null) {
		return false;
	} else if(value._hx_index == 1) {
		return value.value;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asText = function(value) {
	if(value == null) {
		return formula_Text.fromString("");
	} else if(value._hx_index == 10) {
		return value.value;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asDate = function(value) {
	switch(value._hx_index) {
	case 2:
		return value.value;
	case 3:
		return value.value.start;
	default:
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asUser = function(value) {
	if(value._hx_index == 4) {
		return value.value;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asSelect = function(value) {
	if(value._hx_index == 5) {
		return value.value;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asFile = function(value) {
	if(value._hx_index == 6) {
		return value.value;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asDateRange = function(value) {
	switch(value._hx_index) {
	case 2:
		var _g = value.value;
		return new formula_DateRangeValue(_g,_g);
	case 3:
		return value.value;
	default:
		throw new haxe_Exception("");
	}
};
formula_ValueTool.asList = function(value) {
	if(value == null) {
		return null;
	} else if(value._hx_index == 8) {
		return value.value;
	} else {
		throw new haxe_Exception("");
	}
};
formula_ValueTool.formatFloat = function(f) {
	var s = f == null ? "null" : "" + f;
	var k = s.lastIndexOf(".");
	var numFracDigits = s.length - k - 1;
	if(k >= 0 && numFracDigits >= 10) {
		var q = Math.pow(10,Math.max(10,numFracDigits - 6));
		var s2 = Std.string(Math.round(f * q) / q);
		if(s.length - s2.length >= numFracDigits - 12 && s.length - s2.length >= 4) {
			return s2;
		}
	}
	return Std.string(Math.round(f * 1e12) / 1e12);
};
formula_ValueTool.toString = function(value) {
	while(true) if(value == null) {
		return "";
	} else {
		switch(value._hx_index) {
		case 0:
			var _g = value.value;
			if(isNaN(_g)) {
				return "";
			} else {
				return formula_ValueTool.formatFloat(_g);
			}
			break;
		case 1:
			if(value.value) {
				return "true";
			} else {
				return "false";
			}
			break;
		case 2:
			return HxOverrides.dateStr(value.value);
		case 3:
			var _g1 = value.value;
			return HxOverrides.dateStr(_g1.start) + " → " + HxOverrides.dateStr(_g1.end);
		case 4:
			return value.value.name;
		case 5:
			return value.value.name;
		case 6:
			return value.value.name;
		case 7:
			value = value.value;
			continue;
		case 8:
			var _this = value.value.toArray();
			var f = formula_ValueTool.toString;
			var result = new Array(_this.length);
			var _g2 = 0;
			var _g3 = _this.length;
			while(_g2 < _g3) {
				var i = _g2++;
				result[i] = f(_this[i]);
			}
			return result.join(",");
		case 9:
			return "";
		case 10:
			return value.value.plainText();
		}
	}
};
formula_ValueTool.toText = function(value) {
	while(true) if(value == null) {
		return formula_Text.fromString("");
	} else {
		switch(value._hx_index) {
		case 0:
			var _g = value.value;
			if(isNaN(_g)) {
				return formula_Text.fromString("");
			} else {
				return formula_Text.fromString(formula_ValueTool.formatFloat(_g));
			}
			break;
		case 1:
			if(value.value) {
				return formula_Text.fromString("true");
			} else {
				return formula_Text.fromString("false");
			}
			break;
		case 2:
			return formula_Text.fromString(HxOverrides.dateStr(value.value));
		case 3:
			var _g1 = value.value;
			return formula_Text.fromString(HxOverrides.dateStr(_g1.start) + " → " + HxOverrides.dateStr(_g1.end));
		case 4:
			return formula_Text.fromString(value.value.name);
		case 5:
			return formula_Text.fromString(value.value.name);
		case 6:
			return formula_Text.fromString(value.value.name);
		case 7:
			value = value.value;
			continue;
		case 8:
			var _this = value.value.toArray();
			var f = formula_ValueTool.toString;
			var result = new Array(_this.length);
			var _g2 = 0;
			var _g3 = _this.length;
			while(_g2 < _g3) {
				var i = _g2++;
				result[i] = f(_this[i]);
			}
			return formula_Text.fromString(result.join(","));
		case 9:
			return formula_Text.fromString("");
		case 10:
			return value.value;
		}
	}
};
formula_ValueTool.nameOfType = function(type) {
	switch(type._hx_index) {
	case 0:
		return "number";
	case 1:
		return "boolean";
	case 2:
		return "date";
	case 3:
		return "dateRange";
	case 4:
		return "list";
	case 5:
		return "user";
	case 6:
		return "select";
	case 7:
		return "string";
	case 8:
		return "file";
	case 9:
		return "rollup";
	case 10:
		return "any";
	case 11:
		throw new haxe_Exception("unreachable");
	case 12:
		throw new haxe_Exception("unreachable");
	case 13:
		return "null";
	}
};
var haxe_Exception = function(message,previous,native) {
	Error.call(this,message);
	this.message = message;
	this.__previousException = previous;
	this.__nativeException = native != null ? native : this;
};
haxe_Exception.__name__ = true;
haxe_Exception.caught = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value;
	} else if(((value) instanceof Error)) {
		return new haxe_Exception(value.message,null,value);
	} else {
		return new haxe_ValueException(value,null,value);
	}
};
haxe_Exception.thrown = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value.get_native();
	} else if(((value) instanceof Error)) {
		return value;
	} else {
		var e = new haxe_ValueException(value);
		return e;
	}
};
haxe_Exception.__super__ = Error;
haxe_Exception.prototype = $extend(Error.prototype,{
	unwrap: function() {
		return this.__nativeException;
	}
	,toString: function() {
		return this.get_message();
	}
	,get_message: function() {
		return this.message;
	}
	,get_native: function() {
		return this.__nativeException;
	}
});
var formula_Compare = function() { };
formula_Compare.__name__ = true;
formula_Compare.compareNumber = function(v1,v2) {
	if(v1 == v2) {
		return 0;
	}
	if(v1 - v2 > 0) {
		return 1;
	} else {
		return -1;
	}
};
formula_Compare.compareString = function(v1,v2) {
	var len = Math.min(v1.length,v2.length) | 0;
	var _g = 0;
	while(_g < len) {
		var i = _g++;
		var c1 = HxOverrides.cca(v1,i);
		var c2 = HxOverrides.cca(v2,i);
		if(c1 < c2) {
			return -1;
		}
		if(c1 > c2) {
			return 1;
		}
	}
	return v1.length - v2.length;
};
formula_Compare.compareText = function(v1Text,v2Text) {
	if(v1Text.equals(v2Text)) {
		return 0;
	}
	var v1 = v1Text.plainText();
	var v2 = v2Text.plainText();
	var len = Math.min(v1.length,v2.length) | 0;
	var _g = 0;
	while(_g < len) {
		var i = _g++;
		var c1 = HxOverrides.cca(v1,i);
		var c2 = HxOverrides.cca(v2,i);
		if(c1 < c2) {
			return -1;
		}
		if(c1 > c2) {
			return 1;
		}
	}
	return v1.length - v2.length;
};
formula_Compare.compareBoolean = function(v1,v2) {
	if(v1) {
		if(v2) {
			return 0;
		} else {
			return 1;
		}
	} else if(v2) {
		return -1;
	} else {
		return 0;
	}
};
formula_Compare.compareDate = function(v1,v2) {
	return formula_Compare.compareNumber(v1.getTime(),v2.getTime());
};
formula_Compare.compareList = function(v1,v2) {
	if(v1.elements.length != v2.elements.length) {
		return v1.elements.length - v2.elements.length;
	}
	var _g = 0;
	var _g1 = v1.elements.length;
	while(_g < _g1) {
		var index = _g++;
		var ret = formula_Compare.compareValue(v1.get(index),v2.get(index));
		if(ret != 0) {
			return ret;
		}
	}
	return 0;
};
formula_Compare.compareValue = function(v1,v2) {
	v1 = formula_Compare.convertValue(v1);
	v2 = formula_Compare.convertValue(v2);
	switch(v1._hx_index) {
	case 0:
		if(v2._hx_index == 0) {
			return formula_Compare.compareNumber(v1.value,v2.value);
		} else {
			throw new haxe_Exception("Cannot compare values of different types");
		}
		break;
	case 1:
		if(v2._hx_index == 1) {
			return formula_Compare.compareBoolean(v1.value,v2.value);
		} else {
			throw new haxe_Exception("Cannot compare values of different types");
		}
		break;
	case 2:
		var _g = v1.value;
		switch(v2._hx_index) {
		case 2:
			return formula_Compare.compareDate(_g,v2.value);
		case 3:
			return formula_Compare.compareDate(_g,formula_ValueTool.asDate(v2));
		default:
			throw new haxe_Exception("Cannot compare values of different types");
		}
		break;
	case 3:
		if(v2._hx_index == 2) {
			return formula_Compare.compareDate(formula_ValueTool.asDate(v1),v2.value);
		} else {
			throw new haxe_Exception("Cannot compare values of different types");
		}
		break;
	case 8:
		if(v2._hx_index == 8) {
			return formula_Compare.compareList(v1.value,v2.value);
		} else {
			throw new haxe_Exception("Cannot compare values of different types");
		}
		break;
	case 10:
		if(v2._hx_index == 10) {
			return formula_Compare.compareString(v1.value.plainText(),v2.value.plainText());
		} else {
			throw new haxe_Exception("Cannot compare values of different types");
		}
		break;
	default:
		throw new haxe_Exception("Cannot compare values of different types");
	}
};
formula_Compare.convertValue = function(v1) {
	while(true) switch(v1._hx_index) {
	case 4:
		return formula_Value.VText(formula_Text.fromString(v1.value.name));
	case 5:
		return formula_Value.VText(formula_Text.fromString(v1.value.name));
	case 6:
		return formula_Value.VText(formula_Text.fromString(v1.value.name));
	case 7:
		v1 = v1.value;
		continue;
	default:
		return v1;
	}
};
var formula_Text = function(segments) {
	this.segments = segments != null ? segments : [];
	this._updatePlainText();
};
formula_Text.__name__ = true;
formula_Text.fromString = function(str,enhancer) {
	return new formula_Text([{ text : str, enhancer : enhancer != null ? enhancer : { }}]);
};
formula_Text.prototype = {
	plainText: function() {
		return this._plainText;
	}
	,empty: function() {
		return this._plainText.length == 0;
	}
	,length: function() {
		return this._plainText.length;
	}
	,applyStyle: function(style) {
		var _g = 0;
		var _g1 = Reflect.fields(style);
		while(_g < _g1.length) {
			var field = _g1[_g];
			++_g;
			var _this = this.segments;
			var _g_current = 0;
			while(_g_current < _this.length) _this[_g_current++].enhancer[field] = Reflect.field(style,field);
		}
	}
	,deleteColor: function(textColor,isBackground) {
		if(isBackground == null) {
			isBackground = false;
		}
		var _this = this.segments;
		var _g_current = 0;
		while(_g_current < _this.length) {
			var _g_value = _this[_g_current];
			++_g_current;
			if(isBackground) {
				if(_g_value.enhancer.backgroundColor == textColor) {
					_g_value.enhancer.backgroundColor = "";
				}
			} else if(_g_value.enhancer.textColor == textColor) {
				_g_value.enhancer.textColor = "";
			}
		}
	}
	,_updatePlainText: function() {
		var _this = this.segments;
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = _this[i].text;
		}
		this._plainText = result.join("");
	}
	,slice: function(start,end) {
		return this.substring(start,end);
	}
	,add: function() {
		var $l=arguments.length;
		var others = new Array($l>0?$l-0:0);
		for(var $i=0;$i<$l;++$i){others[$i-0]=arguments[$i];}
		var newText = new formula_Text(this.segments.slice(0));
		var _g_current = 0;
		while(_g_current < others.length) {
			var other = others[_g_current++];
			var _g = 0;
			var _g1 = other.segments.length;
			while(_g < _g1) newText.segments.push(other.segments[_g++]);
		}
		newText._updatePlainText();
		return newText;
	}
	,contains: function(some) {
		return this._plainText.indexOf(some) != -1;
	}
	,substring: function(start,end) {
		var result = [];
		var currentPos = 0;
		var targetEnd = end == null ? this.length() : end;
		if(end < 0) {
			end = Math.max(0,this.length() + end) | 0;
		}
		if(start >= end) {
			return formula_Text.fromString("");
		}
		var _g = 0;
		var _g1 = this.segments;
		while(_g < _g1.length) {
			var fragment = _g1[_g];
			++_g;
			var fragmentStart = currentPos;
			var fragmentEnd = currentPos + fragment.text.length;
			if(fragmentEnd <= start) {
				currentPos += fragment.text.length;
				continue;
			}
			if(fragmentStart >= targetEnd) {
				break;
			}
			result.push({ text : fragment.text.substring((Math.max(start,fragmentStart) | 0) - fragmentStart,(Math.min(targetEnd,fragmentEnd) | 0) - fragmentStart), enhancer : { bold : fragment.enhancer.bold, backgroundColor : fragment.enhancer.backgroundColor, italic : fragment.enhancer.italic, lineThrough : fragment.enhancer.lineThrough, textColor : fragment.enhancer.textColor, code : fragment.enhancer.code, underline : fragment.enhancer.underline, link : fragment.enhancer.link}});
			currentPos += fragment.text.length;
		}
		return new formula_Text(result);
	}
	,concat: function(other) {
		return new formula_Text(this.segments.concat(other.segments));
	}
	,indexOf: function(searchString) {
		return this._plainText.indexOf(searchString);
	}
	,equals: function(other) {
		if(this.segments.length != other.segments.length) {
			return false;
		}
		var _g = 0;
		var _g1 = this.segments.length;
		while(_g < _g1) {
			var i = _g++;
			var seg1 = this.segments[i];
			var seg2 = other.segments[i];
			if(seg1.text != seg2.text) {
				return false;
			}
			if(!this.enhancerEquals(seg1.enhancer,seg2.enhancer)) {
				return false;
			}
		}
		return true;
	}
	,enhancerEquals: function(e1,e2) {
		if(e1.bold == e2.bold && e1.backgroundColor == e2.backgroundColor && e1.italic == e2.italic && e1.lineThrough == e2.lineThrough && e1.textColor == e2.textColor && e1.code == e2.code && e1.underline == e2.underline) {
			return e1.link == e2.link;
		} else {
			return false;
		}
	}
};
var formula_ListValue = function(elementType,elements) {
	this.elementType = elementType;
	this.elements = elements;
};
formula_ListValue.__name__ = true;
formula_ListValue.prototype = {
	get: function(index) {
		var _g = this.elementType;
		switch(_g._hx_index) {
		case 0:
			return formula_Value.VNumber(this.elements[index]);
		case 1:
			return formula_Value.VBoolean(this.elements[index]);
		case 2:
			return formula_Value.VDate(this.elements[index]);
		case 3:
			return formula_Value.VDateRange(this.elements[index]);
		case 4:
			return formula_Value.VList(new formula_ListValue(_g.elementType,this.elements[index].elements));
		case 5:
			return formula_Value.VUser(this.elements[index]);
		case 6:
			return formula_Value.VSelect(this.elements[index]);
		case 7:
			return formula_Value.VText(this.elements[index]);
		case 8:
			return formula_Value.VFile(this.elements[index]);
		case 9:
			return formula_Value.VRollup(this.elements[index]);
		case 10:
			throw new haxe_Exception("unreachable");
		case 11:
			throw new haxe_Exception("unreachable");
		case 12:
			throw new haxe_Exception("unreachable");
		default:
		}
		return this.elements[index];
	}
	,map: function(newElemType,f) {
		var newElements = [];
		var _g = 0;
		var _g1 = this.elements.length;
		while(_g < _g1) {
			var i = _g++;
			newElements.push(formula_ValueTool.toDynamic(f(this.get(i),formula_Value.VNumber(i))));
		}
		return new formula_ListValue(newElemType,newElements);
	}
	,filter: function(f) {
		var newElements = [];
		var _g = 0;
		var _g1 = this.elements.length;
		while(_g < _g1) {
			var i = _g++;
			if(formula_ValueTool.asBoolean(f(this.get(i),formula_Value.VNumber(i)))) {
				newElements.push(this.elements[i]);
			}
		}
		return new formula_ListValue(this.elementType,newElements);
	}
	,every: function(f) {
		var _g = 0;
		var _g1 = this.elements.length;
		while(_g < _g1) {
			var i = _g++;
			if(!formula_ValueTool.asBoolean(f(this.get(i),formula_Value.VNumber(i)))) {
				return false;
			}
		}
		return true;
	}
	,some: function(f) {
		var _g = 0;
		var _g1 = this.elements.length;
		while(_g < _g1) {
			var i = _g++;
			if(formula_ValueTool.asBoolean(f(this.get(i),formula_Value.VNumber(i)))) {
				return true;
			}
		}
		return false;
	}
	,unique: function() {
		var result = [];
		var map_h = Object.create(null);
		var _this = this.elements;
		var _g_current = 0;
		while(_g_current < _this.length) {
			var _g_value = _this[_g_current];
			var key = formula_ValueTool.toString(this.get(_g_current++));
			if(map_h[key] == null) {
				result.push(_g_value);
				map_h[key] = _g_value;
			}
		}
		return new formula_ListValue(this.elementType,result);
	}
	,toArray: function() {
		var newElements = [];
		var _g = 0;
		var _g1 = this.elements.length;
		while(_g < _g1) newElements.push(this.get(_g++));
		return newElements;
	}
	,first: function() {
		if(this.elements.length > 0) {
			return this.get(0);
		}
		return formula_Value.VNull;
	}
	,last: function() {
		if(this.elements.length > 0) {
			return this.get(this.elements.length - 1);
		}
		return formula_Value.VNull;
	}
};
var js_Boot = function() { };
js_Boot.__name__ = true;
js_Boot.__string_rec = function(o,s) {
	if(o == null) {
		return "null";
	}
	if(s.length >= 5) {
		return "<...>";
	}
	var t = typeof(o);
	if(t == "function" && (o.__name__ || o.__ename__)) {
		t = "object";
	}
	switch(t) {
	case "function":
		return "<function>";
	case "object":
		if(o.__enum__) {
			var e = $hxEnums[o.__enum__];
			var con = e.__constructs__[o._hx_index];
			var n = con._hx_name;
			if(con.__params__) {
				s = s + "\t";
				return n + "(" + ((function($this) {
					var $r;
					var _g = [];
					{
						var _g1 = 0;
						var _g2 = con.__params__;
						while(true) {
							if(!(_g1 < _g2.length)) {
								break;
							}
							var p = _g2[_g1];
							_g1 = _g1 + 1;
							_g.push(js_Boot.__string_rec(o[p],s));
						}
					}
					$r = _g;
					return $r;
				}(this))).join(",") + ")";
			} else {
				return n;
			}
		}
		if(((o) instanceof Array)) {
			var str = "[";
			s += "\t";
			var _g = 0;
			var _g1 = o.length;
			while(_g < _g1) {
				var i = _g++;
				str += (i > 0 ? "," : "") + js_Boot.__string_rec(o[i],s);
			}
			str += "]";
			return str;
		}
		var tostr;
		try {
			tostr = o.toString;
		} catch( _g ) {
			return "???";
		}
		if(tostr != null && tostr != Object.toString && typeof(tostr) == "function") {
			var s2 = o.toString();
			if(s2 != "[object Object]") {
				return s2;
			}
		}
		var str = "{\n";
		s += "\t";
		var hasp = o.hasOwnProperty != null;
		var k = null;
		for( k in o ) {
		if(hasp && !o.hasOwnProperty(k)) {
			continue;
		}
		if(k == "prototype" || k == "__class__" || k == "__super__" || k == "__interfaces__" || k == "__properties__") {
			continue;
		}
		if(str.length != 2) {
			str += ", \n";
		}
		str += s + k + " : " + js_Boot.__string_rec(o[k],s);
		}
		s = s.substring(1);
		str += "\n" + s + "}";
		return str;
	case "string":
		return o;
	default:
		return String(o);
	}
};
var haxe_iterators_ArrayIterator = function(array) {
	this.current = 0;
	this.array = array;
};
haxe_iterators_ArrayIterator.__name__ = true;
haxe_iterators_ArrayIterator.prototype = {
	hasNext: function() {
		return this.current < this.array.length;
	}
	,next: function() {
		return this.array[this.current++];
	}
};
var formula_User = function(uuid,name,email) {
	this.uuid = uuid;
	this.name = name;
	this.email = email;
};
formula_User.__name__ = true;
var formula_Select = function(name,color) {
	this.name = name;
	this.color = color;
};
formula_Select.__name__ = true;
var formula_File = function(name,ossName) {
	this.name = name;
	this.ossName = ossName;
};
formula_File.__name__ = true;
var formula_Utils = function() { };
formula_Utils.__name__ = true;
formula_Utils.repeat = function(text,count) {
	var ret = "";
	var _g = 0;
	while(_g < count) {
		++_g;
		ret += text;
	}
	return ret;
};
formula_Utils.formatFloat = function(number,count) {
	var mul = Math.pow(10,count);
	return parseFloat(StringTools.lpad(Std.string(Math.round(number * mul) / mul),"0",count));
};
formula_Utils.week = function(date) {
	var year = date.getFullYear();
	var startOfYear = new Date(year,0,1,0,0,0);
	var firstDayOfWeek = startOfYear.getDay();
	if(firstDayOfWeek == 0) {
		firstDayOfWeek = 7;
	}
	var weekNumber = Math.ceil((Math.ceil((date.getTime() - startOfYear.getTime()) / 86400000) + 1 + firstDayOfWeek - 1) / 7);
	if(weekNumber == 0) {
		var lastYear = year - 1;
		var lastStartOfYear = new Date(lastYear,0,1,0,0,0);
		var lastFirstDayOfWeek = lastStartOfYear.getDay();
		if(lastFirstDayOfWeek == 0) {
			lastFirstDayOfWeek = 7;
		}
		weekNumber = Math.ceil((Math.ceil((lastStartOfYear.getTime() - new Date(lastYear,11,31,0,0,0).getTime()) / 86400000) + 1 + lastFirstDayOfWeek - 1) / 7);
	}
	return weekNumber;
};
formula_Utils.splitFun = function(fn,valueType) {
	if(formula_Utils.splitFuns.indexOf(fn) != -1) {
		switch(valueType._hx_index) {
		case 4:
			return "list_" + fn;
		case 7:
			return "string_" + fn;
		default:
		}
	}
	return fn;
};
var haxe_exceptions_PosException = function(message,previous,pos) {
	haxe_Exception.call(this,message,previous);
	if(pos == null) {
		this.posInfos = { fileName : "(unknown)", lineNumber : 0, className : "(unknown)", methodName : "(unknown)"};
	} else {
		this.posInfos = pos;
	}
};
haxe_exceptions_PosException.__name__ = true;
haxe_exceptions_PosException.__super__ = haxe_Exception;
haxe_exceptions_PosException.prototype = $extend(haxe_Exception.prototype,{
	toString: function() {
		return "" + haxe_Exception.prototype.toString.call(this) + " in " + this.posInfos.className + "." + this.posInfos.methodName + " at " + this.posInfos.fileName + ":" + this.posInfos.lineNumber;
	}
});
var haxe_exceptions_NotImplementedException = function(message,previous,pos) {
	if(message == null) {
		message = "Not implemented";
	}
	haxe_exceptions_PosException.call(this,message,previous,pos);
};
haxe_exceptions_NotImplementedException.__name__ = true;
haxe_exceptions_NotImplementedException.__super__ = haxe_exceptions_PosException;
haxe_exceptions_NotImplementedException.prototype = $extend(haxe_exceptions_PosException.prototype,{
});
var formula_IntervalFix = function() { };
formula_IntervalFix.__name__ = true;
formula_IntervalFix.getTotalMonthsFix = function(interval) {
	var begin = interval.begin;
	var end = interval.end;
	var months = (datetime_DateTime.getYear(end) - datetime_DateTime.getYear(begin)) * 12 + (datetime_utils_DateTimeMonthUtils.getMonth(((end - 62135596800.0 - datetime_DateTime.yearStart(end)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(end)) - datetime_utils_DateTimeMonthUtils.getMonth(((begin - 62135596800.0 - datetime_DateTime.yearStart(begin)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(begin)));
	var d1 = datetime_utils_DateTimeMonthUtils.getMonthDay(((begin - 62135596800.0 - datetime_DateTime.yearStart(begin)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(begin));
	var d2 = datetime_utils_DateTimeMonthUtils.getMonthDay(((end - 62135596800.0 - datetime_DateTime.yearStart(end)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(end));
	if(d2 < d1) {
		--months;
	} else if(d1 == d2) {
		var h1 = (begin - Math.floor(begin / 86400) * 86400) / 3600 | 0;
		var h2 = (end - Math.floor(end / 86400) * 86400) / 3600 | 0;
		if(h2 < h1) {
			--months;
		} else if(h2 == h1) {
			var m1 = (begin - Math.floor(begin / 3600) * 3600) / 60 | 0;
			var m2 = (end - Math.floor(end / 3600) * 3600) / 60 | 0;
			if(m2 < m1) {
				--months;
			} else if(m2 == m1 && (end - Math.floor(end / 60) * 60 | 0) < (begin - Math.floor(begin / 60) * 60 | 0)) {
				--months;
			}
		}
	}
	return months;
};
formula_IntervalFix.getLocalWeek = function(date) {
	return datetime_DateTime.getWeek(date - 62135596800.0 + datetime_DateTime.getLocalOffset() + 62135596800.0);
};
var formula_DateTimeUtilsFix = function() {
};
formula_DateTimeUtilsFix.__name__ = true;
formula_DateTimeUtilsFix.fromString = function(str) {
	if(str.length == 10 || str.charCodeAt(10) == 32) {
		return formula_DateTimeUtilsFix.parse(str);
	} else if(str.charCodeAt(10) == 84) {
		return formula_DateTimeUtilsFix.fromIsoString(str);
	} else {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD` or `YYYY-MM-DDThh:mm:ss[.SSS]Z`");
	}
};
formula_DateTimeUtilsFix.parse = function(str) {
	var firstIndex = str.indexOf(":");
	if(firstIndex != -1 && firstIndex == str.lastIndexOf(":")) {
		if(HxOverrides.cca(str,str.length - 1) == 90) {
			str = str.substring(0,str.length - 1) + ":00";
		} else {
			str += ":00";
		}
	}
	var ylength = str.indexOf("-");
	if(ylength < 1 || str.length - ylength != 6 && str.length - ylength != 15) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD`");
	}
	if(str.length - ylength == 6) {
		str += " 00:00:00";
	}
	var year = Std.parseInt(HxOverrides.substr(str,0,ylength));
	var month = Std.parseInt(HxOverrides.substr(str,ylength + 1,2));
	var day = Std.parseInt(HxOverrides.substr(str,ylength + 4,2));
	var hour = Std.parseInt(HxOverrides.substr(str,ylength + 7,2));
	var minute = Std.parseInt(HxOverrides.substr(str,ylength + 10,2));
	var second = Std.parseInt(HxOverrides.substr(str,ylength + 13,2));
	if(year == null || month == null || day == null || hour == null || minute == null || second == null) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD`");
	}
	var year1 = year;
	var month1 = month;
	var day1 = day;
	var hour1 = hour;
	var minute1 = minute;
	var second1 = second;
	if(second == null) {
		second1 = 0;
	}
	if(minute == null) {
		minute1 = 0;
	}
	if(hour == null) {
		hour1 = 0;
	}
	if(day == null) {
		day1 = 1;
	}
	if(month == null) {
		month1 = 1;
	}
	if(year == null) {
		year1 = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year1) + datetime_utils_DateTimeMonthUtils.toSeconds(month1,year1 % 4 == 0 && (year1 % 100 == 0 ? year1 % 400 == 0 : true)) + (day1 - 1) * 86400 + hour1 * 3600 + minute1 * 60 + second1 - 62135596800.0 + 62135596800.0;
};
formula_DateTimeUtilsFix.fromIsoString = function(str) {
	var tz = null;
	var tzSep = str.charCodeAt(str.length - 6);
	if(str.charCodeAt(str.length - 1) == 90) {
		tz = 0;
	} else if(tzSep == 43 || tzSep == 45) {
		tz = Std.parseInt(HxOverrides.substr(str,str.length - 5,2)) * 60 + Std.parseInt(HxOverrides.substr(str,str.length - 2,2));
		if(tzSep == 43) {
			tz = -1 * tz;
		}
	} else {
		tz = 0;
	}
	if(tz == null) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 string: No timezone.");
	}
	if(str.length > 20) {
		if(str.charCodeAt(19) != 46 && str.charCodeAt(19) != tzSep) {
			throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 string: Millisecond specification erroneous.");
		}
	}
	return datetime_DateTime.add(formula_DateTimeUtilsFix.parse(HxOverrides.substr(str,0,10) + " " + HxOverrides.substr(str,11,8)),datetime_DTPeriod.Minute(tz));
};
formula_DateTimeUtilsFix.clamp = function(value,min,max) {
	if(value < min) {
		return min;
	} else if(value > max) {
		return max;
	} else {
		return value;
	}
};
formula_DateTimeUtilsFix.yearToStamp = function(year) {
	--year;
	var cquads = year / 400 | 0;
	var quads = (year - cquads * 400) / 4 | 0;
	return cquads * 12622780800.0 + quads * 126230400.0 - (quads / 25 | 0) * 86400 + (year - cquads * 400 - quads * 4) * 31536000;
};
formula_DateTimeUtilsFix.addYear = function(dt,amount) {
	var year = datetime_DateTime.getYear(dt) + amount;
	return formula_DateTimeUtilsFix.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)),year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (dt - 62135596800.0 - (datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)),datetime_DateTime.isLeapYear(dt)))) - 62135596800.0;
};
formula_DateTimeUtilsFix.addMonth = function(dt,amount) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + amount;
	if(month > 12) {
		var years = month / 12 | 0;
		dt = formula_DateTimeUtilsFix.addYear(dt,years) + 62135596800.0;
		month -= years * 12;
	} else if(month <= 0) {
		var years = (month / 12 | 0) - 1;
		dt = formula_DateTimeUtilsFix.addYear(dt,years) + 62135596800.0;
		month -= years * 12;
	}
	var isLeap = datetime_DateTime.isLeapYear(dt);
	var value = datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	var max = datetime_utils_DateTimeMonthUtils.days(month,isLeap);
	return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + ((value < 1 ? 1 : value > max ? max : value) - 1) * 86400 + ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) * 3600 + ((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) * 60 + (dt - Math.floor(dt / 60) * 60 | 0);
};
formula_DateTimeUtilsFix.getWeekDayNum = function(dt,weekDay,num) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	if(num > 0) {
		return datetime_DateTime.add(datetime_DateTime.snap(datetime_DateTime.monthStart(dt,month) - 1 + 62135596800.0,datetime_DTSnap.Week(1,weekDay)),datetime_DTPeriod.Week(num - 1)) - 62135596800.0;
	} else if(num < 0) {
		return datetime_DateTime.add(datetime_DateTime.snap(datetime_DateTime.monthStart(dt,month + 1) - 1 + 62135596800.0,datetime_DTSnap.Week(-1,weekDay)),datetime_DTPeriod.Week(num + 1)) - 62135596800.0;
	} else {
		return dt - 62135596800.0;
	}
};
formula_DateTimeUtilsFix.strftime = function(dt,format) {
	var prevPos = 0;
	var pos = format.indexOf("%");
	var str = "";
	while(pos >= 0) {
		str += format.substring(prevPos,pos);
		++pos;
		switch(format.charCodeAt(pos)) {
		case 37:
			str += "%";
			break;
		case 67:
			str += StringTools.lpad((datetime_DateTime.getYear(dt) / 100 | 0) + "","0",2);
			break;
		case 68:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "/","0",3) + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "/","0",3) + StringTools.lpad(HxOverrides.substr(datetime_DateTime.getYear(dt) + "",-2,null),"0",2);
			break;
		case 70:
			str += datetime_DateTime.getYear(dt) + "-" + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "-","0",3) + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 72:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + "","0",2);
			break;
		case 73:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + "","0",2);
			break;
		case 77:
			str += StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + "","0",2);
			break;
		case 80:
			str += ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) < 12 ? "am" : "pm";
			break;
		case 82:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + "","0",2);
			break;
		case 83:
			str += StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 84:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + ":","0",3) + StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 86:
			str += StringTools.lpad(datetime_DateTime.getWeek(dt) + "","0",2);
			break;
		case 89:
			str += datetime_DateTime.getYear(dt) + "";
			break;
		case 100:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 101:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + ""," ",2);
			break;
		case 106:
			str += StringTools.lpad("" + (((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1),"0",3);
			break;
		case 107:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ""," ",2);
			break;
		case 108:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + ""," ",2);
			break;
		case 109:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 112:
			str += ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) < 12 ? "AM" : "PM";
			break;
		case 114:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + ":","0",3) + StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 115:
			str += dt - 62135596800.0 + "";
			break;
		case 117:
			str += datetime_DateTime.getWeekDay(dt,true) + "";
			break;
		case 119:
			str += datetime_DateTime.getWeekDay(dt) + "";
			break;
		case 121:
			str += StringTools.lpad(HxOverrides.substr(datetime_DateTime.getYear(dt) + "",-2,null),"0",2);
			break;
		}
		prevPos = pos + 1;
		pos = format.indexOf("%",pos + 1);
	}
	str += format.substring(prevPos);
	return str;
};
var formula_DateRangeValue = function(start,end) {
	this.start = start;
	this.end = end;
};
formula_DateRangeValue.__name__ = true;
var formula_Builtins = function() {
	this.constMap = new haxe_ds_StringMap();
	this.funcMap = new haxe_ds_StringMap();
	var _gthis = this;
	var numTy = formula_ValueType.TNumber;
	var textTy = formula_ValueType.TText;
	var boolTy = formula_ValueType.TBoolean;
	var dateTy = formula_ValueType.TDate;
	this.constMap.h["e"] = formula_Value.VNumber(2.718281828459045);
	this.constMap.h["pi"] = formula_Value.VNumber(3.141592653589793);
	this.constMap.h["true"] = formula_Value.VBoolean(true);
	this.constMap.h["false"] = formula_Value.VBoolean(false);
	this.funcMap.h["prop"] = { type : formula_Builtins.fnTy(null,[textTy]), 'eval' : null};
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(-formula_ValueTool.asNumber(args[0]));
	}};
	this.funcMap.h["unaryMinus"] = value;
	this.funcMap.h["unaryPlus"] = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return args[0];
	}};
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) + formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["add"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) - formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["subtract"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) * formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["multiply"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) / formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["divide"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) % formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["mod"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		var _g = formula_ValueTool.asNumber(args[0]);
		return formula_Value.VNumber(_g > 0 ? 1 : _g < 0 ? -1 : 0);
	}};
	this.funcMap.h["sign"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.abs(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["abs"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.floor(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["floor"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.ceil(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["ceil"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.round(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["round"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[numTy],numTy), 'eval' : function(args) {
		var values = _gthis.parseValues(args);
		var result = new Array(values.length);
		var _g = 0;
		var _g1 = values.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asNumber(values[i]);
		}
		return formula_Value.VNumber(Lambda.fold(result.slice(1),Math.max,result[0]));
	}};
	this.funcMap.h["max"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[numTy],numTy), 'eval' : function(args) {
		var values = _gthis.parseValues(args);
		var result = new Array(values.length);
		var _g = 0;
		var _g1 = values.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asNumber(values[i]);
		}
		return formula_Value.VNumber(Lambda.fold(result.slice(1),Math.min,result[0]));
	}};
	this.funcMap.h["min"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.pow(formula_ValueTool.asNumber(args[0]),formula_ValueTool.asNumber(args[1])));
	}};
	this.funcMap.h["pow"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.exp(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["exp"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.log(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["ln"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.log(formula_ValueTool.asNumber(args[0])) / Math.log(2));
	}};
	this.funcMap.h["log2"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.log(formula_ValueTool.asNumber(args[0])) / Math.log(10));
	}};
	this.funcMap.h["log10"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.sqrt(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["sqrt"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		var arg = formula_ValueTool.asNumber(args[0]);
		return formula_Value.VNumber(arg < 0 ? -Math.pow(-arg,0.333333333333333315) : Math.pow(arg,0.333333333333333315));
	}};
	this.funcMap.h["cbrt"] = value;
	var value = { type : formula_Builtins.genFnTy([0],numTy,[formula_ValueType.TVar(0)]), 'eval' : function(args) {
		var _g = args[0];
		switch(_g._hx_index) {
		case 0:
			return formula_Value.VNumber(_g.value);
		case 1:
			return formula_Value.VNumber(_g.value ? 1 : 0);
		case 2:
			return formula_Value.VNumber(_g.value.getTime());
		case 3:
			return formula_Value.VNumber(_g.value.start.getTime());
		case 4:
			return null;
		case 5:
			return null;
		case 6:
			return null;
		case 7:
			return null;
		case 8:
			return null;
		case 9:
			return null;
		case 10:
			var num = parseFloat(StringTools.trim(_g.value.plainText()));
			if(isNaN(num)) {
				return null;
			} else {
				return formula_Value.VNumber(num);
			}
			break;
		}
	}};
	this.funcMap.h["toNumber"] = value;
	this.funcMap.h["if"] = { type : formula_Builtins.genFnTy([0],formula_ValueType.TVar(0),[boolTy,formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : null};
	this.funcMap.h["and"] = { type : formula_Builtins.fnTy(boolTy,[boolTy,boolTy]), 'eval' : null};
	this.funcMap.h["or"] = { type : formula_Builtins.fnTy(boolTy,[boolTy,boolTy]), 'eval' : null};
	var value = { type : formula_Builtins.fnTy(boolTy,[boolTy]), 'eval' : function(args) {
		return formula_Value.VBoolean(!formula_ValueTool.asBoolean(args[0]));
	}};
	this.funcMap.h["not"] = value;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0)]), 'eval' : function(args) {
		var _g = args[0];
		var value;
		if(_g == null) {
			value = true;
		} else {
			switch(_g._hx_index) {
			case 0:
				value = isNaN(_g.value);
				break;
			case 1:
				value = _g.value == false;
				break;
			case 5:
				value = _g.value.name == "";
				break;
			case 8:
				value = _g.value.elements.length == 0;
				break;
			case 10:
				value = _g.value.empty();
				break;
			default:
				value = false;
			}
		}
		return formula_Value.VBoolean(value);
	}};
	this.funcMap.h["empty"] = value;
	var compare = formula_Compare.compareValue;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) == 0);
	}};
	this.funcMap.h["equal"] = value;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) != 0);
	}};
	this.funcMap.h["unequal"] = value;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) < 0);
	}};
	this.funcMap.h["smaller"] = value;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) <= 0);
	}};
	this.funcMap.h["smallerEq"] = value;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) > 0);
	}};
	this.funcMap.h["larger"] = value;
	var value = { type : formula_Builtins.genFnTy([0],boolTy,[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) >= 0);
	}};
	this.funcMap.h["largerEq"] = value;
	var value = { type : formula_Builtins.genFnTy([0],numTy,[formula_ValueType.TVar(0)]), 'eval' : function(args) {
		var _g = args[0];
		var value;
		switch(_g._hx_index) {
		case 8:
			value = _g.value.elements.length;
			break;
		case 10:
			value = _g.value.length();
			break;
		default:
			value = 0;
		}
		return formula_Value.VNumber(value);
	}};
	this.funcMap.h["length"] = value;
	var subString = function(args) {
		var arg1 = formula_ValueTool.asString(args[0]);
		var arg2 = formula_ValueTool.asNumber(args[1]) | 0;
		if(arg2 < 0) {
			arg2 = Math.max(0,arg1.length + arg2) | 0;
		}
		if(args.length == 2) {
			return formula_Value.VText(formula_Text.fromString(arg1.substring(arg2,arg1.length)));
		} else {
			var arg3 = formula_ValueTool.asNumber(args[2]) | 0;
			if(arg3 < 0) {
				arg3 = Math.max(0,arg1.length + arg3) | 0;
			}
			return formula_Value.VText(formula_Text.fromString(arg2 >= arg3 ? "" : arg1.substring(arg2,arg3)));
		}
	};
	var value = { type : formula_Builtins.fnTy(formula_ValueType.TList(formula_ValueType.TVar(0)),[formula_ValueType.TList(formula_ValueType.TVar(0)),numTy],[numTy]), 'eval' : function(args) {
		var list = formula_ValueTool.asList(args[0]);
		var start = formula_ValueTool.asNumber(args[1]) | 0;
		if(args.length == 2) {
			return formula_Value.VList(new formula_ListValue(list.elementType,list.elements.slice(start)));
		} else {
			var end = formula_ValueTool.asNumber(args[2]) | 0;
			return formula_Value.VList(new formula_ListValue(list.elementType,list.elements.slice(start,end)));
		}
	}};
	this.funcMap.h["list_slice"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy,numTy],[numTy]), 'eval' : function(args) {
		return subString(args);
	}};
	this.funcMap.h["string_slice"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy,numTy],[numTy]), 'eval' : function(args) {
		var arg1 = formula_ValueTool.asText(args[0]);
		var arg2 = formula_ValueTool.asNumber(args[1]) | 0;
		if(arg2 < 0) {
			arg2 = Math.max(0,arg1.length() + arg2) | 0;
		}
		if(args.length == 2) {
			return formula_Value.VText(arg1.substring(arg2,arg1.length()));
		} else {
			var arg3 = formula_ValueTool.asNumber(args[2]) | 0;
			if(arg3 < 0) {
				arg3 = Math.max(0,arg1.length() + arg3) | 0;
			}
			return formula_Value.VText(arg2 >= arg3 ? formula_Text.fromString("") : arg1.substring(arg2,arg3));
		}
	}};
	this.funcMap.h["substring"] = value;
	var value = { type : formula_Builtins.genFnTy([0],textTy,[formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formula_ValueTool.toString(args[0])));
	}};
	this.funcMap.h["format"] = value;
	var plus = function(args) {
		if(args.length == 0) {
			return formula_Value.VText(formula_Text.fromString(""));
		}
		var value = formula_ValueTool.toText(args[0]);
		var _this = args.slice(1);
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.toText(_this[i]);
		}
		($_=value,$_.add.apply($_,result));
		return formula_Value.VText(($_=value,$_.add.apply($_,result)));
	};
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TVar(0),[formula_ValueType.TVar(0),formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return plus(args);
	}};
	this.funcMap.h["plus"] = value;
	var value = { type : formula_Builtins.genVaFnTy([0],formula_ValueType.TVar(0),[formula_ValueType.TVar(0)],formula_ValueType.TVar(0)), 'eval' : function(args) {
		if(args.length == 0) {
			return formula_Value.VText(formula_Text.fromString(""));
		}
		var hasRichText = false;
		var _g = 0;
		var _g1 = args.length;
		while(_g < _g1) if(formula_ValueTool.typeOf(args[_g++]) == formula_ValueType.TText) {
			hasRichText = true;
		}
		if(hasRichText) {
			var text = formula_Text.fromString("");
			var _g = 0;
			var _g1 = args.length;
			while(_g < _g1) {
				var i = _g++;
				if(formula_ValueTool.typeOf(args[i]) == formula_ValueType.TText) {
					text = text.add(formula_ValueTool.asText(args[i]));
				}
			}
			return formula_Value.VText(text);
		}
		var list = formula_ValueTool.asList(args[0]);
		var elementType = list.elementType;
		var arg1Type = formula_ValueTool.typeOf(args[1]);
		if(formula_ValueTool.isListType(arg1Type)) {
			var _g = [];
			var _g_current = 0;
			while(_g_current < args.length) _g.push(formula_ValueTool.asList(args[_g_current++]).elements);
			var _g1 = [];
			var e = $getIterator(_g);
			while(e.hasNext()) {
				var x = $getIterator(e.next());
				while(x.hasNext()) _g1.push(x.next());
			}
			return formula_Value.VList(new formula_ListValue(elementType,_g1));
		} else if(arg1Type == list.elementType) {
			var arg1 = formula_ValueTool.toDynamic(args[1]);
			list.elements.push(arg1);
			return formula_Value.VList(new formula_ListValue(elementType,list.elements.slice(0)));
		}
		return formula_Value.VText(formula_Text.fromString(""));
	}};
	this.funcMap.h["concat"] = value;
	var value = { type : formula_Builtins.genVaFnTy([0],textTy,[formula_ValueType.TVar(0),textTy],textTy), 'eval' : function(args) {
		var arg0Type = formula_ValueTool.typeOf(args[0]);
		var joiner = formula_ValueTool.toText(args[1]);
		if(arg0Type == formula_ValueType.TText) {
			var _this = args.slice(1);
			var result = new Array(_this.length);
			var _g = 0;
			var _g1 = _this.length;
			while(_g < _g1) {
				var i = _g++;
				result[i] = formula_ValueTool.asString(_this[i]);
			}
			return formula_Value.VText(formula_Text.fromString(result.join(formula_ValueTool.asString(args[0]))));
		}
		if(formula_ValueTool.isListType(arg0Type)) {
			var _this = formula_ValueTool.asList(args[0]).elements;
			var result = new Array(_this.length);
			var _g = 0;
			var _g1 = _this.length;
			while(_g < _g1) {
				var i = _g++;
				result[i] = formula_ValueTool.toText(formula_ValueTool.fromDynamic(_this[i]));
			}
			var joinTexts = [];
			var _g_current = 0;
			while(_g_current < result.length) {
				joinTexts.push(result[_g_current++]);
				if(_g_current - 1 != result.length - 1) {
					joinTexts.push(joiner);
				}
			}
			var firstText = joinTexts[0];
			firstText = ($_=firstText,$_.add.apply($_,joinTexts.slice(1)));
			return formula_Value.VText(firstText);
		}
		return formula_Value.VText(formula_Text.fromString(""));
	}};
	this.funcMap.h["join"] = value;
	var value = { type : formula_Builtins.fnTy(boolTy,[textTy,textTy]), 'eval' : function(args) {
		var arg0 = formula_ValueTool.asString(args[0]);
		var arg1 = formula_ValueTool.asString(args[1]);
		return formula_Value.VBoolean(arg0.length <= 0 ? false : arg0.indexOf(arg1) >= 0);
	}};
	this.funcMap.h["contains"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy,textTy,textTy]), 'eval' : function(args) {
		var _this_r = new RegExp(formula_ValueTool.asString(args[1]),"".split("u").join(""));
		var s = formula_ValueTool.asString(args[0]);
		var by = formula_ValueTool.asString(args[2]);
		return formula_Value.VText(formula_Text.fromString(s.replace(_this_r,by)));
	}};
	this.funcMap.h["replace"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy,textTy,textTy]), 'eval' : function(args) {
		var _this_r = new RegExp(formula_ValueTool.asString(args[1]),"g".split("u").join(""));
		var s = formula_ValueTool.asString(args[0]);
		var by = formula_ValueTool.asString(args[2]);
		return formula_Value.VText(formula_Text.fromString(s.replace(_this_r,by)));
	}};
	this.funcMap.h["replaceAll"] = value;
	var value = { type : formula_Builtins.fnTy(boolTy,[textTy,textTy]), 'eval' : function(args) {
		return formula_Value.VBoolean(new EReg(formula_ValueTool.asString(args[1]),"").match(formula_ValueTool.asString(args[0])));
	}};
	this.funcMap.h["test"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.fnTy(this.listTy(textTy),[textTy,textTy]), 'eval' : function(args) {
		var result = formula_ValueTool.asString(args[0]).split(formula_ValueTool.asString(args[1]));
		var textTy1 = textTy;
		var result1 = new Array(result.length);
		var _g = 0;
		var _g1 = result.length;
		while(_g < _g1) {
			var i = _g++;
			result1[i] = formula_Text.fromString(result[i]);
		}
		return formula_Value.VList(new formula_ListValue(textTy1,result1));
	}};
	this1.h["split"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.fnTy(this.listTy(textTy),[textTy,textTy]), 'eval' : function(args) {
		var pattern = new EReg(formula_ValueTool.asString(args[1]),"");
		var input = formula_ValueTool.asString(args[0]);
		var result = [];
		var cursor = 0;
		while(pattern.match(input)) {
			result.push(pattern.matched(0));
			if(result.length > 100) {
				break;
			}
			var posObj = pattern.matchedPos();
			cursor += posObj.pos + posObj.len;
			input = input.substring(cursor);
		}
		var textTy1 = textTy;
		var result1 = new Array(result.length);
		var _g = 0;
		var _g1 = result.length;
		while(_g < _g1) {
			var i = _g++;
			result1[i] = formula_Text.fromString(result[i]);
		}
		return formula_Value.VList(new formula_ListValue(textTy1,result1));
	}};
	this1.h["match"] = value;
	var value = { type : formula_Builtins.vaFnTy(textTy,[textTy],textTy), 'eval' : function(args) {
		var text = formula_ValueTool.asText(args[0]);
		var _this = args.slice(1);
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asString(_this[i]).toLowerCase();
		}
		var _g = 0;
		var _g1 = result.length;
		while(_g < _g1) {
			var style = result[_g++];
			if(style == "gray") {
				style = "grey";
			}
			if(style == "gray_background") {
				style = "grey_background";
			}
			switch(style) {
			case "b":
				text.applyStyle({ bold : true});
				break;
			case "c":
				text.applyStyle({ code : true});
				break;
			case "i":
				text.applyStyle({ italic : true});
				break;
			case "s":
				text.applyStyle({ lineThrough : true});
				break;
			case "u":
				text.applyStyle({ underline : true});
				break;
			default:
				if(formula_Builtins.COLORS.indexOf(style) != -1) {
					text.applyStyle({ textColor : style});
				}
				if(formula_Builtins.BACKGROUND_COLORS.indexOf(style) != -1) {
					text.applyStyle({ backgroundColor : style.substring(0,style.length - "_backgound".length - 1)});
				}
			}
		}
		return formula_Value.VText(text);
	}};
	this.funcMap.h["style"] = value;
	var value = { type : formula_Builtins.vaFnTy(textTy,[textTy],textTy), 'eval' : function(args) {
		var text = formula_ValueTool.asText(args[0]);
		var _this = args.slice(1);
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asString(_this[i]).toLowerCase();
		}
		var _g = 0;
		var _g1 = result.length;
		while(_g < _g1) {
			var style = result[_g++];
			if(style == "gray") {
				style = "grey";
			}
			if(style == "gray_background") {
				style = "grey_background";
			}
			switch(style) {
			case "b":
				text.applyStyle({ bold : false});
				break;
			case "c":
				text.applyStyle({ code : false});
				break;
			case "i":
				text.applyStyle({ italic : false});
				break;
			case "s":
				text.applyStyle({ lineThrough : false});
				break;
			case "u":
				text.applyStyle({ underline : false});
				break;
			default:
				if(formula_Builtins.COLORS.indexOf(style) != -1) {
					text.deleteColor(style);
				}
				if(formula_Builtins.BACKGROUND_COLORS.indexOf(style) != -1) {
					text.deleteColor(style,true);
				}
			}
		}
		return formula_Value.VText(text);
	}};
	this.funcMap.h["unstyle"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy,numTy]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formula_Utils.repeat(formula_ValueTool.asString(args[0]),formula_ValueTool.asNumber(args[1]) | 0)));
	}};
	this.funcMap.h["repeat"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formula_ValueTool.asString(args[0]).toLowerCase()));
	}};
	this.funcMap.h["lower"] = value;
	var value = { type : formula_Builtins.fnTy(textTy,[textTy]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formula_ValueTool.asString(args[0]).toUpperCase()));
	}};
	this.funcMap.h["upper"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[],numTy), 'eval' : function(args) {
		if(args.length == 0) {
			return formula_Value.VNumber(0);
		}
		var sum = 0;
		var values = _gthis.parseValues(args);
		var _g_current = 0;
		while(_g_current < values.length) sum += formula_ValueTool.asNumber(values[_g_current++]);
		return formula_Value.VNumber(sum);
	}};
	this.funcMap.h["sum"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[],numTy), 'eval' : function(args) {
		var allValues = _gthis.parseValues(args);
		var result = new Array(allValues.length);
		var _g = 0;
		var _g1 = allValues.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asNumber(allValues[i]);
		}
		result.sort(function(v0,v1) {
			return (v0 | 0) - (v1 | 0);
		});
		if(result.length % 2 == 0) {
			var median = result.length / 2 | 0;
			return formula_Value.VNumber((result[median] + result[median - 1]) / 2);
		} else {
			return formula_Value.VNumber(result[(result.length - 1) / 2 | 0]);
		}
	}};
	this.funcMap.h["median"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[],numTy), 'eval' : function(args) {
		var sum = 0;
		var values = _gthis.parseValues(args);
		var _g_current = 0;
		while(_g_current < values.length) sum += formula_ValueTool.asNumber(values[_g_current++]);
		return formula_Value.VNumber(formula_Utils.formatFloat(sum / values.length,2));
	}};
	this.funcMap.h["mean"] = value;
	this.funcMap.h["now"] = { type : formula_Builtins.fnTy(dateTy,[]), 'eval' : null};
	this.funcMap.h["today"] = { type : formula_Builtins.fnTy(dateTy,[]), 'eval' : null};
	var pad = function(x,n) {
		var s = "0000" + (x == null ? "null" : "" + x);
		return s.substring(s.length - n);
	};
	var ord = function(x) {
		switch(x % 10) {
		case 1:
			if(x != 11) {
				return "" + x + "st";
			} else {
				return "" + x + "th";
			}
			break;
		case 2:
			if(x != 12) {
				return "" + x + "nd";
			} else {
				return "" + x + "th";
			}
			break;
		case 3:
			if(x != 13) {
				return "" + x + "rd";
			} else {
				return "" + x + "th";
			}
			break;
		default:
			return "" + x + "th";
		}
	};
	var tz = function(z,colon) {
		var absz = Math.abs(z);
		return "" + (z < 0 ? "-" : "+") + pad(Math.floor(absz / 60),2) + (colon ? ":" : "") + pad(absz % 60 | 0,2);
	};
	var formatDate = function(date,fmt) {
		return new EReg("YYYY|YY|Qo|Q|MMMM|MMM|MM|Mo|M|ww|wo|w|DDDD|DDDo|DDD|DD|Do|D|dddd|ddd|dd|d|HH|H|hh|h|mm|m|ss|s|A|a|ZZ|Z|SSS|SS|S|X|x","g").map(fmt,function(reg) {
			switch(reg.matched(0)) {
			case "A":
				if(date.getHours() < 12) {
					return "AM";
				} else {
					return "PM";
				}
				break;
			case "D":
				return Std.string(date.getDate());
			case "DD":
				return pad(date.getDate(),2);
			case "DDD":
				var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
				return Std.string(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1);
			case "DDDD":
				var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
				return pad(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,3);
			case "DDDo":
				var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
				return ord(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1);
			case "Do":
				return ord(date.getDate());
			case "H":
				return Std.string(date.getHours());
			case "HH":
				return pad(date.getHours(),2);
			case "M":
				return Std.string(date.getMonth() + 1);
			case "MM":
				return pad(date.getMonth() + 1,2);
			case "MMM":
				return DateTools.format(date,"%b");
			case "MMMM":
				return DateTools.format(date,"%B");
			case "Mo":
				return ord(date.getMonth() + 1);
			case "Q":
				return Std.string(Math.floor(date.getMonth() / 3) + 1);
			case "Qo":
				return ord(Math.floor(date.getMonth() / 3) + 1);
			case "S":
				return Std.string(Math.floor(date.getTime() % 1000 / 100));
			case "SS":
				return Std.string(Math.floor(date.getTime() % 1000 / 10));
			case "SSS":
				return Std.string(date.getTime() % 1000);
			case "X":
				return Std.string(Math.floor(date.getTime() / 1000));
			case "XX":
				return Std.string(date.getTime());
			case "YY":
				return pad(date.getFullYear(),2);
			case "YYYY":
				return pad(date.getFullYear(),4);
			case "Z":
				return tz(-date.getTimezoneOffset(),true);
			case "ZZ":
				return tz(-date.getTimezoneOffset(),false);
			case "a":
				if(date.getHours() < 12) {
					return "am";
				} else {
					return "pm";
				}
				break;
			case "d":
				return Std.string(date.getDay());
			case "dd":
				return HxOverrides.substr(DateTools.format(date,"%a"),0,2);
			case "ddd":
				return DateTools.format(date,"%a");
			case "dddd":
				return DateTools.format(date,"%A");
			case "do":
				return ord(date.getDay());
			case "h":
				return Std.string(date.getHours() % 12);
			case "hh":
				return pad(date.getHours() % 12,2);
			case "m":
				return Std.string(date.getMinutes());
			case "mm":
				return pad(date.getMinutes(),2);
			case "s":
				return Std.string(date.getSeconds());
			case "ss":
				return pad(date.getSeconds(),2);
			case "w":
				return Std.string(formula_IntervalFix.getLocalWeek(Math.floor(date.getTime() / 1000) + 62135596800.0));
			case "wo":
				return ord(formula_IntervalFix.getLocalWeek(Math.floor(date.getTime() / 1000) + 62135596800.0));
			case "ww":
				return pad(formula_IntervalFix.getLocalWeek(Math.floor(date.getTime() / 1000) + 62135596800.0),2);
			default:
				throw new haxe_Exception("");
			}
		});
	};
	var value = { type : formula_Builtins.fnTy(textTy,[dateTy,textTy]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formatDate(formula_ValueTool.asDate(args[0]),formula_ValueTool.asString(args[1]))));
	}};
	this.funcMap.h["formatDate"] = value;
	var value = { type : formula_Builtins.fnTy(dateTy,[textTy]), 'eval' : function(args) {
		return formula_Value.VDate(new Date((formula_DateTimeUtilsFix.fromString(formula_ValueTool.asString(args[0])) - 62135596800.0) * 1000));
	}};
	this.funcMap.h["parseDate"] = value;
	var value = { type : formula_Builtins.fnTy(dateTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VDate(new Date(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["fromTimestamp"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.round(formula_ValueTool.asDate(args[0]).getTime()));
	}};
	this.funcMap.h["timestamp"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getFullYear());
	}};
	this.funcMap.h["year"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getMonth() + 1);
	}};
	this.funcMap.h["month"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getDate());
	}};
	this.funcMap.h["date"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		var day = formula_ValueTool.asDate(args[0]).getDay();
		if(day == 0) {
			day = 7;
		}
		return formula_Value.VNumber(day);
	}};
	this.funcMap.h["day"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getHours());
	}};
	this.funcMap.h["hour"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getMinutes());
	}};
	this.funcMap.h["minute"] = value;
	var dateAdd = function(date,amount,unit) {
		if(unit == "milliseconds") {
			var milliseconds = date.getTime() % 1000 + amount;
			var seconds = Math.floor(milliseconds / 1000);
			milliseconds -= seconds * 1000;
			return new Date((datetime_DateTime.add(Math.floor(date.getTime() / 1000) + 62135596800.0,datetime_DTPeriod.Second(seconds)) - 62135596800.0) * 1000 + milliseconds);
		}
		var year = date.getFullYear();
		var month = date.getMonth();
		var day = date.getDate();
		var hour = date.getHours();
		var minute = date.getMinutes();
		var sec = date.getSeconds();
		switch(unit) {
		case "days":
			day += amount;
			break;
		case "hours":
			hour += amount;
			break;
		case "minutes":
			minute += amount;
			break;
		case "months":
			month += amount;
			break;
		case "quarters":
			month += amount * 3;
			break;
		case "seconds":
			sec += amount;
			break;
		case "weeks":
			day += 7 * amount;
			break;
		case "years":
			year += amount;
			break;
		default:
			throw new haxe_Exception("");
		}
		return new Date(year,month,day,hour,minute,sec);
	};
	var value = { type : formula_Builtins.fnTy(dateTy,[dateTy,numTy,textTy]), 'eval' : function(args) {
		return formula_Value.VDate(dateAdd(formula_ValueTool.asDate(args[0]),formula_ValueTool.asNumber(args[1]) | 0,formula_ValueTool.asString(args[2])));
	}};
	this.funcMap.h["dateAdd"] = value;
	var value = { type : formula_Builtins.fnTy(dateTy,[dateTy,numTy,textTy]), 'eval' : function(args) {
		return formula_Value.VDate(dateAdd(formula_ValueTool.asDate(args[0]),-(formula_ValueTool.asNumber(args[1]) | 0),formula_ValueTool.asString(args[2])));
	}};
	this.funcMap.h["dateSubtract"] = value;
	var dateBetween = function(date1,date2,unit) {
		var milliseconds = date1.getTime() % 1000 - date2.getTime() % 1000;
		var datetime1 = Math.floor(date1.getTime() / 1000) + 62135596800.0;
		if(milliseconds < 0) {
			milliseconds += 1000;
			datetime1 = datetime_DateTime.add(datetime1,datetime_DTPeriod.Second(-1));
		}
		var interval = datetime_DateTimeInterval.create(Math.floor(date2.getTime() / 1000) + 62135596800.0,datetime1);
		var dateBetween = interval.negative ? -1 : 1;
		var dateBetween1;
		switch(unit) {
		case "days":
			dateBetween1 = interval.getTotalDays();
			break;
		case "hours":
			dateBetween1 = interval.getTotalHours();
			break;
		case "milliseconds":
			dateBetween1 = interval.getTotalSeconds() * 1000 + milliseconds;
			break;
		case "minutes":
			dateBetween1 = interval.getTotalMinutes();
			break;
		case "months":
			dateBetween1 = formula_IntervalFix.getTotalMonthsFix(interval);
			break;
		case "quarters":
			dateBetween1 = formula_IntervalFix.getTotalMonthsFix(interval) / 3;
			break;
		case "seconds":
			dateBetween1 = interval.getTotalSeconds();
			break;
		case "weeks":
			dateBetween1 = interval.getTotalWeeks();
			break;
		case "years":
			dateBetween1 = interval.getYears();
			break;
		default:
			throw new haxe_Exception("");
		}
		return dateBetween * dateBetween1;
	};
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy,dateTy,textTy]), 'eval' : function(args) {
		return formula_Value.VNumber(dateBetween(formula_ValueTool.asDate(args[0]),formula_ValueTool.asDate(args[1]),formula_ValueTool.asString(args[2])));
	}};
	this.funcMap.h["dateBetween"] = value;
	var value = { type : formula_Builtins.fnTy(formula_ValueType.TDate,[formula_ValueType.TDateRange]), 'eval' : function(args) {
		return formula_Value.VDate(formula_ValueTool.asDateRange(args[0]).start);
	}};
	this.funcMap.h["dateStart"] = value;
	var value = { type : formula_Builtins.fnTy(formula_ValueType.TDate,[formula_ValueType.TDateRange]), 'eval' : function(args) {
		return formula_Value.VDate(formula_ValueTool.asDateRange(args[0]).end);
	}};
	this.funcMap.h["dateEnd"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TVar(0),[this.listTy(formula_ValueType.TVar(0)),numTy]), 'eval' : function(args) {
		return formula_ValueTool.asList(args[0]).get(formula_ValueTool.asNumber(args[1]) | 0);
	}};
	this1.h["at"] = value;
	var value = { type : formula_Builtins.genFnTy([0],textTy,[formula_ValueType.TVar(0),numTy,textTy]), 'eval' : function(args) {
		var arg0 = args[0];
		formula_ValueTool.typeOf(arg0);
		var targetLength = formula_ValueTool.asNumber(args[1]) | 0;
		var padChar = formula_ValueTool.toString(args[2]);
		if(padChar.length == 0) {
			return args[0];
		}
		var padLength = targetLength - formula_ValueTool.toString(arg0).length;
		if(padLength <= 0) {
			return args[0];
		}
		var padding = padChar;
		while(padding.length < padLength) padding += padChar;
		return plus([formula_Value.VText(formula_Text.fromString(padding.substring(0,padLength))),args[0]]);
	}};
	this.funcMap.h["padStart"] = value;
	var value = { type : formula_Builtins.genFnTy([0],textTy,[formula_ValueType.TVar(0),numTy,textTy]), 'eval' : function(args) {
		var arg0 = args[0];
		formula_ValueTool.typeOf(arg0);
		var targetLength = formula_ValueTool.asNumber(args[1]) | 0;
		var padChar = formula_ValueTool.toString(args[2]);
		if(padChar.length == 0) {
			return args[0];
		}
		var padLength = targetLength - formula_ValueTool.toString(arg0).length;
		if(padLength <= 0) {
			return args[0];
		}
		var padding = padChar;
		while(padding.length < padLength) padding += padChar;
		return plus([args[0],formula_Value.VText(formula_Text.fromString(padding.substring(0,padLength)))]);
	}};
	this.funcMap.h["padEnd"] = value;
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TVar(0),[formula_ValueType.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VList(formula_ValueTool.asList(args[0]).unique());
	}};
	this.funcMap.h["unique"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0,1],this.listTy(formula_ValueType.TVar(1)),[this.listTy(formula_ValueType.TVar(0)),formula_ValueType.TVar(1)]), 'eval' : null};
	this1.h["map"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0],this.listTy(formula_ValueType.TVar(0)),[this.listTy(formula_ValueType.TVar(0)),formula_ValueType.TBoolean]), 'eval' : null};
	this1.h["filter"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TBoolean,[this.listTy(formula_ValueType.TVar(0)),formula_ValueType.TBoolean]), 'eval' : null};
	this1.h["every"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TBoolean,[this.listTy(formula_ValueType.TVar(0)),formula_ValueType.TBoolean]), 'eval' : null};
	this1.h["some"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TVar(0),[this.listTy(formula_ValueType.TVar(0))]), 'eval' : function(args) {
		return formula_ValueTool.asList(args[0]).first();
	}};
	this1.h["first"] = value;
	var this1 = this.funcMap;
	var value = { type : formula_Builtins.genFnTy([0],formula_ValueType.TVar(0),[this.listTy(formula_ValueType.TVar(0))]), 'eval' : function(args) {
		return formula_ValueTool.asList(args[0]).last();
	}};
	this1.h["last"] = value;
	this.funcMap.h["id"] = { type : formula_Builtins.fnTy(textTy,[]), 'eval' : null};
	var value = { type : formula_Builtins.fnTy(formula_ValueType.TUser,[textTy]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formula_ValueTool.asUser(args[0]).name));
	}};
	this.funcMap.h["name"] = value;
	var value = { type : formula_Builtins.fnTy(formula_ValueType.TUser,[textTy]), 'eval' : function(args) {
		return formula_Value.VText(formula_Text.fromString(formula_ValueTool.asUser(args[0]).email));
	}};
	this.funcMap.h["email"] = value;
};
formula_Builtins.__name__ = true;
formula_Builtins.fnTy = function(ret,params,optParams) {
	return { typeVars : null, params : params, optParams : optParams, variadic : null, ret : ret};
};
formula_Builtins.vaFnTy = function(ret,params,variadic,optParams) {
	return { typeVars : null, params : params, optParams : optParams, variadic : variadic, ret : ret};
};
formula_Builtins.genFnTy = function(typeVars,ret,params,optParams) {
	return { typeVars : typeVars, params : params, optParams : optParams, variadic : null, ret : ret};
};
formula_Builtins.genVaFnTy = function(typeVars,ret,params,variadic,optParams) {
	return { typeVars : typeVars, params : params, optParams : optParams, variadic : variadic, ret : ret};
};
formula_Builtins.toNumberArray = function(value) {
	return formula_ValueTool.asList(value).toArray();
};
formula_Builtins.prototype = {
	findFuncDef: function(name) {
		return this.funcMap.h[name];
	}
	,findConstDef: function(name) {
		return this.constMap.h[name];
	}
	,listTy: function(ty) {
		return formula_ValueType.TList(ty);
	}
	,parseValues: function(args) {
		var allValues = [];
		var _g_current = 0;
		while(_g_current < args.length) {
			var _g_value = args[_g_current];
			++_g_current;
			var vType = formula_ValueTool.typeOf(_g_value);
			if(vType == formula_ValueType.TNumber) {
				allValues.push(_g_value);
			} else if(formula_ValueTool.isListType(vType)) {
				var array = formula_Builtins.toNumberArray(_g_value);
				var _g = 0;
				var _g1 = array.length;
				while(_g < _g1) allValues.push(array[_g++]);
			}
		}
		return allValues;
	}
};
var formula_ExpectedKind = $hxEnums["formula.ExpectedKind"] = { __ename__:true,__constructs__:null
	,EkExpr: {_hx_name:"EkExpr",_hx_index:0,__enum__:"formula.ExpectedKind",toString:$estr}
	,EkUnknown: {_hx_name:"EkUnknown",_hx_index:1,__enum__:"formula.ExpectedKind",toString:$estr}
	,EkRparen: {_hx_name:"EkRparen",_hx_index:2,__enum__:"formula.ExpectedKind",toString:$estr}
};
formula_ExpectedKind.__constructs__ = [formula_ExpectedKind.EkExpr,formula_ExpectedKind.EkUnknown,formula_ExpectedKind.EkRparen];
var formula_Error = $hxEnums["formula.Error"] = { __ename__:true,__constructs__:null
	,UnclosedStringLiteral: ($_=function(pos) { return {_hx_index:0,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnclosedStringLiteral",$_.__params__ = ["pos"],$_)
	,InvalidStringLiteral: ($_=function(pos) { return {_hx_index:1,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="InvalidStringLiteral",$_.__params__ = ["pos"],$_)
	,UnexpectedChar: ($_=function(char,pos) { return {_hx_index:2,char:char,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnexpectedChar",$_.__params__ = ["char","pos"],$_)
	,UnexpectedToken: ($_=function(token,expected) { return {_hx_index:3,token:token,expected:expected,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnexpectedToken",$_.__params__ = ["token","expected"],$_)
	,UnbalancedParen: ($_=function(pos) { return {_hx_index:4,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnbalancedParen",$_.__params__ = ["pos"],$_)
	,UnbalancedBracket: ($_=function(pos) { return {_hx_index:5,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnbalancedBracket",$_.__params__ = ["pos"],$_)
	,UnbalancedCommant: ($_=function(pos) { return {_hx_index:6,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnbalancedCommant",$_.__params__ = ["pos"],$_)
	,UnknownError: ($_=function(pos) { return {_hx_index:7,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnknownError",$_.__params__ = ["pos"],$_)
	,UndefinedConstant: ($_=function(name,pos) { return {_hx_index:8,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UndefinedConstant",$_.__params__ = ["name","pos"],$_)
	,FunctionAsConstant: ($_=function(name,pos) { return {_hx_index:9,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="FunctionAsConstant",$_.__params__ = ["name","pos"],$_)
	,UndefinedFunction: ($_=function(name,pos) { return {_hx_index:10,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UndefinedFunction",$_.__params__ = ["name","pos"],$_)
	,UndefinedProp: ($_=function(name,pos) { return {_hx_index:11,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UndefinedProp",$_.__params__ = ["name","pos"],$_)
	,TypeMismatch: ($_=function(expr,types) { return {_hx_index:12,expr:expr,types:types,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="TypeMismatch",$_.__params__ = ["expr","types"],$_)
	,TooManyArguments: ($_=function(actual,expected,pos) { return {_hx_index:13,actual:actual,expected:expected,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="TooManyArguments",$_.__params__ = ["actual","expected","pos"],$_)
	,TooFewArguments: ($_=function(actual,expected,pos) { return {_hx_index:14,actual:actual,expected:expected,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="TooFewArguments",$_.__params__ = ["actual","expected","pos"],$_)
	,ExpectIdentifier: ($_=function(pos) { return {_hx_index:15,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="ExpectIdentifier",$_.__params__ = ["pos"],$_)
	,LetCallMissingNameArg: ($_=function(pos) { return {_hx_index:16,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="LetCallMissingNameArg",$_.__params__ = ["pos"],$_)
	,LetCallMissingValueArg: ($_=function(pos) { return {_hx_index:17,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="LetCallMissingValueArg",$_.__params__ = ["pos"],$_)
	,LetCallMissingBodyArg: ($_=function(pos) { return {_hx_index:18,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="LetCallMissingBodyArg",$_.__params__ = ["pos"],$_)
	,IfsCallMissingTestArg: ($_=function(pos) { return {_hx_index:19,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="IfsCallMissingTestArg",$_.__params__ = ["pos"],$_)
	,IfsCallMissingThenArg: ($_=function(pos) { return {_hx_index:20,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="IfsCallMissingThenArg",$_.__params__ = ["pos"],$_)
	,IfsCallMissingElseArg: ($_=function(pos) { return {_hx_index:21,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="IfsCallMissingElseArg",$_.__params__ = ["pos"],$_)
};
formula_Error.__constructs__ = [formula_Error.UnclosedStringLiteral,formula_Error.InvalidStringLiteral,formula_Error.UnexpectedChar,formula_Error.UnexpectedToken,formula_Error.UnbalancedParen,formula_Error.UnbalancedBracket,formula_Error.UnbalancedCommant,formula_Error.UnknownError,formula_Error.UndefinedConstant,formula_Error.FunctionAsConstant,formula_Error.UndefinedFunction,formula_Error.UndefinedProp,formula_Error.TypeMismatch,formula_Error.TooManyArguments,formula_Error.TooFewArguments,formula_Error.ExpectIdentifier,formula_Error.LetCallMissingNameArg,formula_Error.LetCallMissingValueArg,formula_Error.LetCallMissingBodyArg,formula_Error.IfsCallMissingTestArg,formula_Error.IfsCallMissingThenArg,formula_Error.IfsCallMissingElseArg];
var formula_ErrorTool = $hx_exports["formula"]["ErrorTool"] = function() { };
formula_ErrorTool.__name__ = true;
formula_ErrorTool.getPosition = function(error) {
	switch(error._hx_index) {
	case 0:
		return error.pos;
	case 1:
		return error.pos;
	case 2:
		return error.pos;
	case 3:
		return error.token.pos;
	case 4:
		return error.pos;
	case 5:
		return error.pos;
	case 6:
		return error.pos;
	case 7:
		return error.pos;
	case 8:
		return error.pos;
	case 9:
		return error.pos;
	case 10:
		return error.pos;
	case 11:
		return error.pos;
	case 12:
		return error.expr.pos;
	case 13:
		return error.pos;
	case 14:
		return error.pos;
	case 15:
		return error.pos;
	case 16:
		return error.pos;
	case 17:
		return error.pos;
	case 18:
		return error.pos;
	case 19:
		return error.pos;
	case 20:
		return error.pos;
	case 21:
		return error.pos;
	}
};
formula_ErrorTool.reprType = function(type) {
	switch(type._hx_index) {
	case 0:
		return "数值";
	case 1:
		return "布尔";
	case 2:
		return "日期";
	case 3:
		return "日期范围";
	case 4:
		var _g = type.elementType;
		if(_g._hx_index == 11) {
			return "列表";
		} else {
			return formula_ErrorTool.reprType(_g) + "列表";
		}
		break;
	case 5:
		return "人员";
	case 6:
		return "单选";
	case 7:
		return "文本";
	case 8:
		return "文件";
	case 9:
		return "汇总";
	case 10:
		return "任意值";
	case 11:
		return "unreachable";
	case 12:
		return "unreachable";
	case 13:
		return "null value";
	}
};
formula_ErrorTool.toString = function(error) {
	switch(error._hx_index) {
	case 0:
		return "缺少成对的引号";
	case 1:
		return "字符串语法错误";
	case 2:
		return "语法错误";
	case 3:
		return "语法错误";
	case 4:
		return "缺少成对的括号";
	case 5:
		return "缺少成对的方括号";
	case 6:
		return "注释不对";
	case 7:
		return "语法错误";
	case 8:
		return "找不到常量`" + error.name + "`";
	case 9:
		return "`" + error.name + "`是一个函数而不是常量";
	case 10:
		return "找不到函数`" + error.name + "`";
	case 11:
		return "找不到属性`" + error.name + "`";
	case 12:
		var _g = error.types;
		var f = formula_ErrorTool.reprType;
		var result = new Array(_g.length);
		var _g1 = 0;
		var _g2 = _g.length;
		while(_g1 < _g2) {
			var i = _g1++;
			result[i] = f(_g[i]);
		}
		return "类型错误，期望" + result.join("或") + "类型";
	case 13:
		return "参数过多，期望" + error.expected + "个，传入" + error.actual + "个";
	case 14:
		return "参数过少，期望" + error.expected + "个，传入" + error.actual + "个";
	case 15:
		return "期望一个变量名称";
	case 16:
		return "let 调用缺少变量名称";
	case 17:
		return "let 调用缺少变量赋值";
	case 18:
		return "let 调用缺少 body 参数";
	case 19:
		return "ifs 调用缺少 test 参数";
	case 20:
		return "ifs 调用缺少 then 参数";
	case 21:
		return "ifs 调用缺少 else 参数";
	}
};
var formula_EvalError = $hxEnums["formula.EvalError"] = { __ename__:true,__constructs__:null
};
formula_EvalError.__constructs__ = [];
var formula_EvalContext = $hx_exports["formula"]["EvalContext"] = function() { };
formula_EvalContext.__name__ = true;
var formula_Env = function(parent) {
	this.parent = parent;
	this.bindings = new haxe_ds_StringMap();
};
formula_Env.__name__ = true;
formula_Env.prototype = {
	lookup: function(name) {
		if(Object.prototype.hasOwnProperty.call(this.bindings.h,name)) {
			return this.bindings.h[name];
		} else if(this.parent != null) {
			return this.parent.lookup(name);
		} else {
			return null;
		}
	}
};
var formula_FormulaEvaluator = function(ctx) {
	this.ctx = ctx;
};
formula_FormulaEvaluator.__name__ = true;
formula_FormulaEvaluator.prototype = {
	'eval': function(expr,recordId) {
		var _gthis = this;
		var $eval = null;
		$eval = function(expr,env) {
			while(true) {
				var _genv = [env];
				var _g = expr.desc;
				switch(_g._hx_index) {
				case 0:
					return formula_Value.VNumber(_g.value);
				case 1:
					return formula_Value.VText(formula_Text.fromString(_g.value));
				case 2:
					var _g1 = _g.$const;
					if(_genv[0] != null && _genv[0].lookup(_g1) != null) {
						return _genv[0].lookup(_g1);
					}
					return formula_Builtins.singleton.findConstDef(_g1);
				case 3:
					var _g2 = _g.values;
					var elementType;
					var _g3 = expr.type;
					if(_g3 == null) {
						throw new haxe_Exception("unreachable");
					} else if(_g3._hx_index == 4) {
						elementType = _g3.elementType;
					} else {
						throw new haxe_Exception("unreachable");
					}
					var result = new Array(_g2.length);
					var _g4 = 0;
					var _g5 = _g2.length;
					while(_g4 < _g5) {
						var i = _g4++;
						result[i] = formula_ValueTool.toDynamic($eval(_g2[i],_genv[0]));
					}
					return formula_Value.VList(new formula_ListValue(elementType,result));
				case 4:
					var _g6 = _g.fn;
					var _g7 = _g.args;
					var func = _g6;
					switch(_g6) {
					case "and":
						var arg2 = _g7[1];
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g7[0],_genv[0])) && formula_ValueTool.asBoolean($eval(arg2,_genv[0])));
					case "every":
						var arg1 = _g7[0];
						var arg21 = [_g7[1]];
						var _g8 = arg1.type;
						if(_g8 == null) {
							throw new haxe_Exception("arg1 should be a list");
						} else if(_g8._hx_index != 4) {
							throw new haxe_Exception("arg1 should be a list");
						}
						return formula_Value.VBoolean(formula_ValueTool.asList($eval(arg1,_genv[0])).every((function(_genv,arg2) {
							return function(current,index) {
								var env2 = new formula_Env(_genv[0]);
								env2.bindings.h["current"] = current;
								env2.bindings.h["index"] = index;
								return $eval(arg2[0],env2);
							};
						})(_genv,arg21)));
					case "filter":
						var arg11 = _g7[0];
						var arg22 = [_g7[1]];
						var _g9 = arg11.type;
						if(_g9 == null) {
							throw new haxe_Exception("arg1 should be a list");
						} else if(_g9._hx_index != 4) {
							throw new haxe_Exception("arg1 should be a list");
						}
						return formula_Value.VList(formula_ValueTool.asList($eval(arg11,_genv[0])).filter((function(_genv,arg2) {
							return function(current,index) {
								var env2 = new formula_Env(_genv[0]);
								env2.bindings.h["current"] = current;
								env2.bindings.h["index"] = index;
								return $eval(arg2[0],env2);
							};
						})(_genv,arg22)));
					case "id":
						return formula_Value.VText(formula_Text.fromString(recordId));
					case "if":case "ifs":
						var i1 = 0;
						while(_g7.length - i1 > 1) {
							var then_ = _g7[i1 + 1];
							if(formula_ValueTool.asBoolean($eval(_g7[i1],_genv[0]))) {
								return $eval(then_,_genv[0]);
							}
							i1 += 2;
						}
						expr = _g7[i1];
						env = _genv[0];
						continue;
					case "let":case "lets":
						var env2 = new formula_Env(_genv[0]);
						var i2 = 0;
						do {
							var name;
							var _g10 = _g7[i2].desc;
							if(_g10._hx_index == 2) {
								name = _g10.$const;
							} else {
								throw new haxe_Exception("unreachable");
							}
							var value = $eval(_g7[i2 + 1],env2);
							env2.bindings.h[name] = value;
							i2 += 2;
						} while(_g7.length - i2 > 1);
						expr = _g7[i2];
						env = env2;
						continue;
					case "map":
						var arg12 = _g7[0];
						var arg23 = [_g7[1]];
						var _g11 = arg12.type;
						if(_g11 == null) {
							throw new haxe_Exception("arg1 should be a list");
						} else if(_g11._hx_index != 4) {
							throw new haxe_Exception("arg1 should be a list");
						}
						var newElemType = arg23[0].type;
						return formula_Value.VList(formula_ValueTool.asList($eval(arg12,_genv[0])).map(newElemType,(function(_genv,arg2) {
							return function(current,index) {
								var env2 = new formula_Env(_genv[0]);
								env2.bindings.h["current"] = current;
								env2.bindings.h["index"] = index;
								return $eval(arg2[0],env2);
							};
						})(_genv,arg23)));
					case "now":
						var now = new Date();
						return formula_Value.VDate(now);
					case "or":
						var arg24 = _g7[1];
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g7[0],_genv[0])) || formula_ValueTool.asBoolean($eval(arg24,_genv[0])));
					case "prop":
						return _gthis.ctx.readProp(recordId,formula_ExprTool.getStringValue(_g7[1]),expr.type);
					case "some":
						var arg13 = _g7[0];
						var arg25 = [_g7[1]];
						var _g12 = arg13.type;
						if(_g12 == null) {
							throw new haxe_Exception("arg1 should be a list");
						} else if(_g12._hx_index != 4) {
							throw new haxe_Exception("arg1 should be a list");
						}
						return formula_Value.VBoolean(formula_ValueTool.asList($eval(arg13,_genv[0])).some((function(_genv,arg2) {
							return function(current,index) {
								var env2 = new formula_Env(_genv[0]);
								env2.bindings.h["current"] = current;
								env2.bindings.h["index"] = index;
								return $eval(arg2[0],env2);
							};
						})(_genv,arg25)));
					case "today":
						var now1 = new Date();
						return formula_Value.VDate(new Date(now1.getFullYear(),now1.getMonth(),now1.getDate(),0,0,0));
					default:
						if(_g6 == "median" || _g6 == "mean") {
							if(_g7.length == 0) {
								return formula_Value.VText(formula_Text.fromString(""));
							}
						}
						var result1 = new Array(_g7.length);
						var _g13 = 0;
						var _g14 = _g7.length;
						while(_g13 < _g14) {
							var i3 = _g13++;
							result1[i3] = $eval(_g7[i3],_genv[0]);
						}
						if(_g7.length > 1) {
							func = formula_Utils.splitFun(_g6,_g7[0].type);
						}
						return formula_Builtins.singleton.findFuncDef(func).eval(result1);
					}
					break;
				case 5:
					expr = _g.exp;
					env = _genv[0];
					continue;
				case 6:
					var argValues = [$eval(_g.arg,_genv[0])];
					var func1;
					switch(_g.op._hx_index) {
					case 0:
						func1 = "unaryPlus";
						break;
					case 1:
						func1 = "unaryMinus";
						break;
					case 2:
						func1 = "not";
						break;
					}
					return formula_Builtins.singleton.findFuncDef(func1).eval(argValues);
				case 7:
					var _g15 = _g.arg1;
					var _g16 = _g.arg2;
					var argValues1 = [$eval(_g15,_genv[0]),$eval(_g16,_genv[0])];
					var func2;
					switch(_g.op._hx_index) {
					case 0:
						func2 = _g15.type == formula_ValueType.TNumber && _g16.type == formula_ValueType.TNumber ? "add" : "plus";
						break;
					case 1:
						func2 = "subtract";
						break;
					case 2:
						func2 = "multiply";
						break;
					case 3:
						func2 = "divide";
						break;
					case 4:
						func2 = "mod";
						break;
					case 5:
						func2 = "pow";
						break;
					case 6:
						func2 = "equal";
						break;
					case 7:
						func2 = "unequal";
						break;
					case 8:
						func2 = "smaller";
						break;
					case 9:
						func2 = "smallerEq";
						break;
					case 10:
						func2 = "larger";
						break;
					case 11:
						func2 = "largerEq";
						break;
					case 12:
						func2 = "and";
						break;
					case 13:
						func2 = "or";
						break;
					}
					if(func2 == "and") {
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g15,_genv[0])) && formula_ValueTool.asBoolean($eval(_g16,_genv[0])));
					} else if(func2 == "or") {
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g15,_genv[0])) || formula_ValueTool.asBoolean($eval(_g16,_genv[0])));
					}
					return formula_Builtins.singleton.findFuncDef(func2).eval(argValues1);
				case 8:
					if(formula_ValueTool.asBoolean($eval(_g.test,_genv[0]))) {
						expr = _g.then;
						env = _genv[0];
						continue;
					} else {
						expr = _g.else_;
						env = _genv[0];
						continue;
					}
					break;
				case 9:
					return formula_Value.VText(formula_Text.fromString(""));
				}
			}
		};
		var eval1 = $eval;
		try {
			return eval1(expr,formula_Env.top);
		} catch( _g ) {
			var _g1 = haxe_Exception.caught(_g);
			haxe_Log.trace("err",{ fileName : "src/formula/Eval.hx", lineNumber : 234, className : "formula.FormulaEvaluator", methodName : "eval", customParams : [_g1]});
			return null;
		}
	}
};
var formula_UnOp = $hxEnums["formula.UnOp"] = { __ename__:true,__constructs__:null
	,OpUnaryPlus: {_hx_name:"OpUnaryPlus",_hx_index:0,__enum__:"formula.UnOp",toString:$estr}
	,OpUnaryMinus: {_hx_name:"OpUnaryMinus",_hx_index:1,__enum__:"formula.UnOp",toString:$estr}
	,OpNot: {_hx_name:"OpNot",_hx_index:2,__enum__:"formula.UnOp",toString:$estr}
};
formula_UnOp.__constructs__ = [formula_UnOp.OpUnaryPlus,formula_UnOp.OpUnaryMinus,formula_UnOp.OpNot];
var formula_BinOp = $hxEnums["formula.BinOp"] = { __ename__:true,__constructs__:null
	,OpAdd: {_hx_name:"OpAdd",_hx_index:0,__enum__:"formula.BinOp",toString:$estr}
	,OpSub: {_hx_name:"OpSub",_hx_index:1,__enum__:"formula.BinOp",toString:$estr}
	,OpMul: {_hx_name:"OpMul",_hx_index:2,__enum__:"formula.BinOp",toString:$estr}
	,OpDiv: {_hx_name:"OpDiv",_hx_index:3,__enum__:"formula.BinOp",toString:$estr}
	,OpMod: {_hx_name:"OpMod",_hx_index:4,__enum__:"formula.BinOp",toString:$estr}
	,OpPow: {_hx_name:"OpPow",_hx_index:5,__enum__:"formula.BinOp",toString:$estr}
	,OpEq: {_hx_name:"OpEq",_hx_index:6,__enum__:"formula.BinOp",toString:$estr}
	,OpNeq: {_hx_name:"OpNeq",_hx_index:7,__enum__:"formula.BinOp",toString:$estr}
	,OpLt: {_hx_name:"OpLt",_hx_index:8,__enum__:"formula.BinOp",toString:$estr}
	,OpLeq: {_hx_name:"OpLeq",_hx_index:9,__enum__:"formula.BinOp",toString:$estr}
	,OpGt: {_hx_name:"OpGt",_hx_index:10,__enum__:"formula.BinOp",toString:$estr}
	,OpGeq: {_hx_name:"OpGeq",_hx_index:11,__enum__:"formula.BinOp",toString:$estr}
	,OpAnd: {_hx_name:"OpAnd",_hx_index:12,__enum__:"formula.BinOp",toString:$estr}
	,OpOr: {_hx_name:"OpOr",_hx_index:13,__enum__:"formula.BinOp",toString:$estr}
};
formula_BinOp.__constructs__ = [formula_BinOp.OpAdd,formula_BinOp.OpSub,formula_BinOp.OpMul,formula_BinOp.OpDiv,formula_BinOp.OpMod,formula_BinOp.OpPow,formula_BinOp.OpEq,formula_BinOp.OpNeq,formula_BinOp.OpLt,formula_BinOp.OpLeq,formula_BinOp.OpGt,formula_BinOp.OpGeq,formula_BinOp.OpAnd,formula_BinOp.OpOr];
var formula_ExprDesc = $hxEnums["formula.ExprDesc"] = { __ename__:true,__constructs__:null
	,ENumber: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="ENumber",$_.__params__ = ["value"],$_)
	,EString: ($_=function(value) { return {_hx_index:1,value:value,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EString",$_.__params__ = ["value"],$_)
	,EConst: ($_=function($const) { return {_hx_index:2,$const:$const,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EConst",$_.__params__ = ["$const"],$_)
	,EList: ($_=function(values) { return {_hx_index:3,values:values,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EList",$_.__params__ = ["values"],$_)
	,ECall: ($_=function(fn,fnPos,args) { return {_hx_index:4,fn:fn,fnPos:fnPos,args:args,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="ECall",$_.__params__ = ["fn","fnPos","args"],$_)
	,EParen: ($_=function(exp) { return {_hx_index:5,exp:exp,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EParen",$_.__params__ = ["exp"],$_)
	,EUnary: ($_=function(op,opPos,arg) { return {_hx_index:6,op:op,opPos:opPos,arg:arg,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EUnary",$_.__params__ = ["op","opPos","arg"],$_)
	,EBinary: ($_=function(op,opPos,arg1,arg2) { return {_hx_index:7,op:op,opPos:opPos,arg1:arg1,arg2:arg2,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EBinary",$_.__params__ = ["op","opPos","arg1","arg2"],$_)
	,ETernary: ($_=function(test,then,else_) { return {_hx_index:8,test:test,then:then,else_:else_,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="ETernary",$_.__params__ = ["test","then","else_"],$_)
	,EComment: {_hx_name:"EComment",_hx_index:9,__enum__:"formula.ExprDesc",toString:$estr}
};
formula_ExprDesc.__constructs__ = [formula_ExprDesc.ENumber,formula_ExprDesc.EString,formula_ExprDesc.EConst,formula_ExprDesc.EList,formula_ExprDesc.ECall,formula_ExprDesc.EParen,formula_ExprDesc.EUnary,formula_ExprDesc.EBinary,formula_ExprDesc.ETernary,formula_ExprDesc.EComment];
var formula_ExprTool = $hx_exports["formula"]["ExprTool"] = function() { };
formula_ExprTool.__name__ = true;
formula_ExprTool.getStringValue = function(expr) {
	var _g = expr.desc;
	if(_g._hx_index == 1) {
		return _g.value;
	} else {
		return null;
	}
};
formula_ExprTool.typeOf = function(expr) {
	return expr.type;
};
var formula_Formula = $hx_exports["formula"]["Formula"] = function() { };
formula_Formula.__name__ = true;
formula_Formula.main = function() {
};
formula_Formula.toServer = function(formula,schema) {
	var refProps = { };
	return { text : formula_Formula.regex.map(formula,function(regex) {
		var propName = JSON.parse(regex.matched(1));
		var propDef = schema.findPropDefByName(propName);
		refProps[propDef.id] = propName;
		var _this_r = new RegExp("\"(?:[^\\\\\"]|\\\\.)*\"","".split("u").join(""));
		var s = regex.matched(0);
		var by = JSON.stringify(propDef.id);
		return s.replace(_this_r,by);
	}), refProps : refProps};
};
formula_Formula.fromServer = function(formula,schema) {
	return formula_Formula.regex.map(formula.text == null ? "" : formula.text,function(regex) {
		var propId = JSON.parse(regex.matched(1));
		var propDef = schema.findPropDefById(propId);
		var propName = propDef == null ? formula.refProps[propId] : propDef.name;
		var _this_r = new RegExp("\"(?:[^\\\\\"]|\\\\.)*\"","".split("u").join(""));
		var s = regex.matched(0);
		var by = JSON.stringify(propName);
		return s.replace(_this_r,by);
	});
};
formula_Formula.parse = function(input,schema) {
	var parser = new formula_FormulaParser(new formula_FormulaTokenSource(input),schema);
	try {
		return formula_Result.Ok(parser.parse());
	} catch( _g ) {
		var _g1 = haxe_Exception.caught(_g);
		var _g2 = _g1.unwrap();
		if(((_g1) instanceof formula_ParseException)) {
			return formula_Result.Error(_g1.error);
		} else if(((_g2) instanceof hxparse_NoMatch)) {
			return formula_Result.Error(formula_Error.UnexpectedToken(_g2.token,formula_ExpectedKind.EkUnknown));
		} else if(((_g2) instanceof hxparse_Unexpected)) {
			return formula_Result.Error(formula_Error.UnexpectedToken(_g2.token,formula_ExpectedKind.EkUnknown));
		} else if(((_g2) instanceof hxparse_UnexpectedChar)) {
			var e = _g2;
			return formula_Result.Error(formula_Error.UnexpectedChar(e.char,e.pos));
		} else if(((_g2) instanceof hxparse_ParserError)) {
			return formula_Result.Error(formula_Error.UnknownError(_g2.pos));
		} else {
			throw _g;
		}
	}
};
formula_Formula.eval = function(expr,ctx,recordId) {
	return new formula_FormulaEvaluator(ctx).eval(expr,recordId);
};
formula_Formula.objc_parse = function(input,schemaConfig) {
	var schema = new formula_Schema();
	var _g = 0;
	while(_g < schemaConfig.length) {
		var prop = schemaConfig[_g];
		++_g;
		var tmp;
		switch(prop.type) {
		case "boolean":
			tmp = formula_ValueType.TBoolean;
			break;
		case "date":
			tmp = formula_ValueType.TDate;
			break;
		case "number":
			tmp = formula_ValueType.TNumber;
			break;
		case "string":
			tmp = formula_ValueType.TText;
			break;
		default:
			tmp = formula_ValueType.TText;
		}
		schema.defineProp(prop.id,prop.name,tmp);
	}
	var result = formula_Formula.parse(input,schema);
	return { ok : formula_ResultTool.isOk(result) ? formula_ResultTool.getOk(result) : null, error : formula_ResultTool.isError(result) ? formula_ErrorTool.toString(formula_ResultTool.getError(result)) : null};
};
formula_Formula.objc_eval = function(expr,recordId) {
	var value = formula_Formula.eval(expr,formula_ObjcEvalContext.singleton,recordId);
	var tmp = formula_ValueTool.toString(value);
	var tmp1;
	switch(value._hx_index) {
	case 0:
		tmp1 = "number";
		break;
	case 1:
		tmp1 = "boolean";
		break;
	case 2:
		tmp1 = "date";
		break;
	case 3:
		tmp1 = "dateRange";
		break;
	case 4:
		tmp1 = "user";
		break;
	case 5:
		tmp1 = "select";
		break;
	case 6:
		tmp1 = "file";
		break;
	case 7:
		tmp1 = "rollup";
		break;
	case 8:
		tmp1 = "array";
		break;
	case 9:
		tmp1 = "null";
		break;
	case 10:
		tmp1 = "string";
		break;
	}
	return { value : tmp, type : tmp1};
};
formula_Formula.objc_expr_type = function(expr) {
	switch(expr.type._hx_index) {
	case 0:
		return "number";
	case 1:
		return "boolean";
	case 2:
		return "date";
	case 3:
		return "dateRange";
	case 4:
		return "list";
	case 5:
		return "user";
	case 6:
		return "select";
	case 7:
		return "string";
	case 8:
		return "file";
	case 9:
		return "rollup";
	case 10:
		return "any";
	case 11:
		throw new haxe_Exception("unreachable");
	case 12:
		throw new haxe_Exception("unreachable");
	case 13:
		return "null";
	}
};
var formula_ObjcEvalContext = function() {
};
formula_ObjcEvalContext.__name__ = true;
formula_ObjcEvalContext.__super__ = formula_EvalContext;
formula_ObjcEvalContext.prototype = $extend(formula_EvalContext.prototype,{
	readProp: function(recordId,propId,type) {
		var value = objc_read_prop(recordId, propId);
		if(typeof(value) == "string") {
			return formula_Value.VText(formula_Text.fromString(value));
		} else if(typeof(value) == "number") {
			return formula_Value.VNumber(value);
		} else if(typeof(value) == "boolean") {
			return formula_Value.VBoolean(value);
		} else if(((value) instanceof Date)) {
			return formula_Value.VDate(value);
		}
		return null;
	}
});
var formula_ParseException = function(error) {
	haxe_Exception.call(this,formula_ErrorTool.toString(error));
	this.error = error;
};
formula_ParseException.__name__ = true;
formula_ParseException.__super__ = haxe_Exception;
formula_ParseException.prototype = $extend(haxe_Exception.prototype,{
});
var formula_FormulaTokenSource = function(input,source) {
	if(source == null) {
		source = "(none)";
	}
	this.cursor = 0;
	this.input = input;
	this.source = source;
};
formula_FormulaTokenSource.__name__ = true;
formula_FormulaTokenSource.prototype = {
	token: function() {
		var _gthis = this;
		this.skip(new EReg("^[ \t\r\n]+",""));
		var pmin = this.cursor;
		var mk = function(desc) {
			return { tok : desc, pos : new hxparse_Position(_gthis.source,pmin,_gthis.cursor)};
		};
		if(this.cursor == this.input.length) {
			return mk(formula_TokenDesc.TkEof);
		}
		var reg = new EReg("(?:\"([^\"\\\\]|\\\\.)*\"?|(0|[1-9][0-9]*)([.][0-9]+)?([eE][\\\\+\\\\-]?0|[1-9][0-9]*)?|[A-Za-z_][A-Za-z0-9_]*|\\(|\\)|\\+|\\-|/\\*|\\*/|\\*|/|%|\\^|==|!=|<>|<=|<|>=|>|&&|\\|\\||,|\\.|\\?|:|!|\\[|\\])","");
		if(reg.match(this.input.substring(this.cursor))) {
			var m = reg.matched(0);
			this.cursor += reg.matchedPos().len;
			switch(m) {
			case "!":
				return mk(formula_TokenDesc.TkBang);
			case "!=":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpNeq));
			case "%":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpMod));
			case "&&":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpAnd));
			case "(":
				return mk(formula_TokenDesc.TkLparen);
			case ")":
				return mk(formula_TokenDesc.TkRparen);
			case "*":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpMul));
			case "*/":
				throw new formula_ParseException(formula_Error.UnbalancedCommant(new hxparse_Position(this.source,this.cursor,this.cursor + 2)));
			case "+":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpAdd));
			case ",":
				return mk(formula_TokenDesc.TkComma);
			case "-":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpSub));
			case ".":
				return mk(formula_TokenDesc.TkDot);
			case "/":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpDiv));
			case "/*":
				var commentStart = this.cursor - 2;
				var finish = false;
				while(this.cursor < this.input.length) {
					if(HxOverrides.substr(this.input,this.cursor,2) == "*/") {
						this.cursor += 2;
						finish = true;
						break;
					}
					this.cursor++;
				}
				if(!finish) {
					throw new formula_ParseException(formula_Error.UnbalancedCommant(new hxparse_Position(this.source,commentStart,commentStart + 2)));
				} else {
					return this.token();
				}
				break;
			case ":":
				return mk(formula_TokenDesc.TkColon);
			case "<":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpLt));
			case "<=":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpLeq));
			case "<>":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpNeq));
			case "==":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpEq));
			case ">":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpGt));
			case ">=":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpGeq));
			case "?":
				return mk(formula_TokenDesc.TkQuestion);
			case "[":
				return mk(formula_TokenDesc.TkLbracket);
			case "]":
				return mk(formula_TokenDesc.TkRbracket);
			case "^":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpPow));
			case "||":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpOr));
			default:
				var c = m.charAt(0);
				if(c == "\"") {
					if(m.length > 1 && m.charAt(m.length - 1) == "\"") {
						try {
							return mk(formula_TokenDesc.TkStr(JSON.parse(m)));
						} catch( _g ) {
							var quoteStart = this.cursor - reg.matchedPos().len;
							throw new formula_ParseException(formula_Error.InvalidStringLiteral(new hxparse_Position(this.source,quoteStart,this.cursor)));
						}
					} else {
						var quoteStart = this.cursor - reg.matchedPos().len;
						throw new formula_ParseException(formula_Error.UnclosedStringLiteral(new hxparse_Position(this.source,quoteStart,quoteStart + 1)));
					}
				} else if(c >= "0" && c <= "9") {
					return mk(formula_TokenDesc.TkNum(parseFloat(m)));
				} else {
					return mk(formula_TokenDesc.TkIdent(m));
				}
			}
		} else {
			throw new formula_ParseException(formula_Error.UnexpectedChar(this.input.charAt(this.cursor),new hxparse_Position(this.source,this.cursor,this.cursor + 1)));
		}
	}
	,skip: function(reg) {
		if(reg.match(this.input.substring(this.cursor))) {
			this.cursor += reg.matchedPos().len;
		}
	}
	,curPos: function() {
		return new hxparse_Position(this.source,this.cursor,this.cursor);
	}
};
var hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token = function(stream) {
	this.stream = stream;
};
hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.__name__ = true;
hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.prototype = {
	peek: function(n) {
		if(this.token == null) {
			this.token = new haxe_ds_GenericCell(this.stream.token(),null);
			--n;
		}
		var tok = this.token;
		while(n > 0) {
			if(tok.next == null) {
				tok.next = new haxe_ds_GenericCell(this.stream.token(),null);
			}
			tok = tok.next;
			--n;
		}
		return tok.elt;
	}
	,junk: function() {
		this.last = this.token.elt;
		this.token = this.token.next;
	}
	,curPos: function() {
		return this.stream.curPos();
	}
	,parseSeparated: function(separatorFunc,f) {
		var acc = [];
		while(true) {
			try {
				acc.push(f());
			} catch( _g ) {
				if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
					break;
				} else {
					throw _g;
				}
			}
			if(separatorFunc(this.peek(0))) {
				this.last = this.token.elt;
				this.token = this.token.next;
			} else {
				break;
			}
		}
		return acc;
	}
	,parseOptional: function(f) {
		try {
			return f();
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				return null;
			} else {
				throw _g;
			}
		}
	}
	,parseRepeat: function(f) {
		var acc = [];
		while(true) try {
			acc.push(f());
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				return acc;
			} else {
				throw _g;
			}
		}
	}
	,parseExpect: function(f) {
		try {
			return f();
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
			} else {
				throw _g;
			}
		}
	}
	,noMatch: function() {
		return new hxparse_NoMatch(this.stream.curPos(),this.peek(0));
	}
	,unexpected: function() {
		throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
	}
};
var formula_FormulaParser = function(stream,schema) {
	hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.call(this,stream);
	this.schema = schema;
	this.typer = new formula_TypeChecker(schema);
};
formula_FormulaParser.__name__ = true;
formula_FormulaParser.punion = function(p1,p2) {
	return new hxparse_Position(p1.psource,p1.pmin < p2.pmin ? p1.pmin : p2.pmin,p1.pmax > p2.pmax ? p1.pmax : p2.pmax);
};
formula_FormulaParser.__super__ = hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token;
formula_FormulaParser.prototype = $extend(hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.prototype,{
	mk: function(desc,pos) {
		return { desc : desc, pos : pos, type : null};
	}
	,mkUnary: function(op,opPos,e) {
		var _g = e.desc;
		switch(_g._hx_index) {
		case 7:
			var _g1 = _g.op;
			var _g2 = _g.arg1;
			if(op != formula_UnOp.OpNot || this.precedence(_g1).p >= 7) {
				return this.mk(formula_ExprDesc.EBinary(_g1,_g.opPos,this.mkUnary(op,opPos,_g2),_g.arg2),formula_FormulaParser.punion(opPos,_g2.pos));
			} else {
				return this.mk(formula_ExprDesc.EUnary(op,opPos,e),formula_FormulaParser.punion(opPos,e.pos));
			}
			break;
		case 8:
			return this.mk(formula_ExprDesc.ETernary(this.mkUnary(op,opPos,_g.test),_g.then,_g.else_),formula_FormulaParser.punion(opPos,e.pos));
		default:
			return this.mk(formula_ExprDesc.EUnary(op,opPos,e),formula_FormulaParser.punion(opPos,e.pos));
		}
	}
	,mkBinary: function(op,opPos,e,e2) {
		var _g = e2.desc;
		switch(_g._hx_index) {
		case 7:
			var _g1 = _g.op;
			var _g2 = _g.arg2;
			if(this.swap(op,_g1)) {
				var _e = this.mkBinary(op,opPos,e,_g.arg1);
				return this.mk(formula_ExprDesc.EBinary(_g1,_g.opPos,_e,_g2),formula_FormulaParser.punion(_e.pos,_g2.pos));
			} else {
				return this.mk(formula_ExprDesc.EBinary(op,opPos,e,e2),formula_FormulaParser.punion(e.pos,e2.pos));
			}
			break;
		case 8:
			var _g1 = _g.else_;
			var e1 = this.mkBinary(op,opPos,e,_g.test);
			return this.mk(formula_ExprDesc.ETernary(e1,_g.then,_g1),formula_FormulaParser.punion(e1.pos,_g1.pos));
		default:
			return this.mk(formula_ExprDesc.EBinary(op,opPos,e,e2),formula_FormulaParser.punion(e.pos,e2.pos));
		}
	}
	,psep: function(sep,f) {
		var acc = [];
		while(true) try {
			acc.push(f());
			if(this.peek(0).tok == sep) {
				this.last = this.token.elt;
				this.token = this.token.next;
			} else {
				throw haxe_Exception.thrown(new hxparse_NoMatch(this.stream.curPos(),this.peek(0)));
			}
		} catch( _g ) {
			var _g1 = haxe_Exception.caught(_g);
			if(((_g1.unwrap()) instanceof hxparse_NoMatch)) {
				break;
			} else if(((_g1) instanceof formula_ParseException)) {
				break;
			} else {
				throw _g;
			}
		}
		return acc;
	}
	,precedence: function(op) {
		switch(op._hx_index) {
		case 0:case 1:
			return { p : 3, left : true};
		case 2:case 3:case 4:
			return { p : 2, left : true};
		case 5:
			return { p : 1, left : true};
		case 6:case 7:case 8:case 9:case 10:case 11:
			return { p : 6, left : true};
		case 12:case 13:
			return { p : 7, left : true};
		}
	}
	,swap: function(op1,op2) {
		var i1 = this.precedence(op1);
		var i2 = this.precedence(op2);
		if(i1.left) {
			return i1.p <= i2.p;
		} else {
			return false;
		}
	}
	,parse: function() {
		var e = this.expr();
		if(this.peek(0).tok._hx_index == 15) {
			this.last = this.token.elt;
			this.token = this.token.next;
		} else {
			throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
		}
		this.typer.infer(e,formula_TypingEnv.top);
		return e;
	}
	,expr: function() {
		var _g = this.peek(0);
		var _g1 = _g.pos;
		var _g2 = _g.tok;
		switch(_g2._hx_index) {
		case 0:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.exprNext(this.mk(formula_ExprDesc.ENumber(_g2.value),_g1));
		case 1:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.exprNext(this.mk(formula_ExprDesc.EString(_g2.value),_g1));
		case 2:
			this.last = this.token.elt;
			this.token = this.token.next;
			var e = this.expr();
			var _g = this.peek(0);
			if(_g.tok._hx_index == 3) {
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.exprNext(this.mk(formula_ExprDesc.EParen(e),formula_FormulaParser.punion(_g1,_g.pos)));
			} else {
				throw new formula_ParseException(formula_Error.UnbalancedParen(_g1));
			}
			break;
		case 4:
			this.last = this.token.elt;
			this.token = this.token.next;
			var args = this.psep(formula_TokenDesc.TkComma,$bind(this,this.expr));
			var _g = this.peek(0);
			if(_g.tok._hx_index == 5) {
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.exprNext(this.mk(formula_ExprDesc.EList(args),formula_FormulaParser.punion(_g1,_g.pos)));
			} else {
				throw new formula_ParseException(formula_Error.UnbalancedBracket(_g1));
			}
			break;
		case 6:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.exprNext(this.mk(formula_ExprDesc.EComment,_g1));
		case 7:
			var _g = _g2.name;
			if(_g == "not") {
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkUnary(formula_UnOp.OpNot,_g1,this.expr());
			} else {
				this.last = this.token.elt;
				this.token = this.token.next;
				var _g3 = this.peek(0);
				var _g4 = _g3.pos;
				if(_g3.tok._hx_index == 2) {
					this.last = this.token.elt;
					this.token = this.token.next;
					var args = this.psep(formula_TokenDesc.TkComma,$bind(this,this.expr));
					var _g3 = this.peek(0);
					if(_g3.tok._hx_index == 3) {
						this.last = this.token.elt;
						this.token = this.token.next;
						return this.exprNext(this.mk(formula_ExprDesc.ECall(_g,_g1,args),formula_FormulaParser.punion(_g1,_g3.pos)));
					} else {
						var pt = this.peek(0);
						if(pt.tok == formula_TokenDesc.TkEof) {
							throw new formula_ParseException(formula_Error.UnbalancedParen(_g4));
						} else {
							throw new formula_ParseException(formula_Error.UnexpectedToken(pt,formula_ExpectedKind.EkRparen));
						}
					}
				} else {
					return this.exprNext(this.mk(formula_ExprDesc.EConst(_g),_g1));
				}
			}
			break;
		case 12:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.mkUnary(formula_UnOp.OpNot,_g1,this.expr());
		case 13:
			switch(_g2.op._hx_index) {
			case 0:
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkUnary(formula_UnOp.OpUnaryPlus,_g1,this.expr());
			case 1:
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkUnary(formula_UnOp.OpUnaryMinus,_g1,this.expr());
			default:
				throw new formula_ParseException(formula_Error.UnexpectedToken(this.peek(0),formula_ExpectedKind.EkExpr));
			}
			break;
		default:
			throw new formula_ParseException(formula_Error.UnexpectedToken(this.peek(0),formula_ExpectedKind.EkExpr));
		}
	}
	,exprNext: function(e1) {
		var _g = this.peek(0);
		var _g1 = _g.pos;
		var _g2 = _g.tok;
		switch(_g2._hx_index) {
		case 7:
			switch(_g2.name) {
			case "and":
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkBinary(formula_BinOp.OpAnd,_g1,e1,this.expr());
			case "or":
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkBinary(formula_BinOp.OpOr,_g1,e1,this.expr());
			default:
				return e1;
			}
			break;
		case 8:
			this.last = this.token.elt;
			this.token = this.token.next;
			var _g = this.peek(0);
			var _g3 = _g.pos;
			var _g4 = _g.tok;
			if(_g4._hx_index == 7) {
				this.last = this.token.elt;
				this.token = this.token.next;
				var _g = this.peek(0);
				var _g5 = _g.pos;
				if(_g.tok._hx_index == 2) {
					this.last = this.token.elt;
					this.token = this.token.next;
					var args = this.psep(formula_TokenDesc.TkComma,$bind(this,this.expr));
					var _g = this.peek(0);
					var _g6 = _g.pos;
					if(_g.tok._hx_index == 3) {
						this.last = this.token.elt;
						this.token = this.token.next;
						return this.exprNext(this.mk(formula_ExprDesc.ECall(_g4.name,_g3,[e1].concat(args)),formula_FormulaParser.punion(e1.pos,_g6)));
					} else {
						throw new formula_ParseException(formula_Error.UnbalancedParen(_g5));
					}
				} else {
					throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
				}
			} else {
				throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
			}
			break;
		case 10:
			this.last = this.token.elt;
			this.token = this.token.next;
			var e2 = this.expr();
			if(this.peek(0).tok._hx_index == 11) {
				this.last = this.token.elt;
				this.token = this.token.next;
				var e3 = this.expr();
				return this.mk(formula_ExprDesc.ETernary(e1,e2,e3),formula_FormulaParser.punion(e1.pos,e3.pos));
			} else {
				throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
			}
			break;
		case 13:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.mkBinary(_g2.op,_g1,e1,this.expr());
		default:
			return e1;
		}
	}
});
var formula_Result = $hxEnums["formula.Result"] = { __ename__:true,__constructs__:null
	,Ok: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.Result",toString:$estr}; },$_._hx_name="Ok",$_.__params__ = ["value"],$_)
	,Error: ($_=function(error) { return {_hx_index:1,error:error,__enum__:"formula.Result",toString:$estr}; },$_._hx_name="Error",$_.__params__ = ["error"],$_)
};
formula_Result.__constructs__ = [formula_Result.Ok,formula_Result.Error];
var formula_ResultTool = $hx_exports["formula"]["ResultTool"] = function() { };
formula_ResultTool.__name__ = true;
formula_ResultTool.isOk = function(res) {
	switch(res._hx_index) {
	case 0:
		return true;
	case 1:
		return false;
	}
};
formula_ResultTool.isError = function(res) {
	switch(res._hx_index) {
	case 0:
		return false;
	case 1:
		return true;
	}
};
formula_ResultTool.getOk = function(res) {
	switch(res._hx_index) {
	case 0:
		return res.value;
	case 1:
		throw new haxe_Exception("Invalid argument");
	}
};
formula_ResultTool.getError = function(res) {
	switch(res._hx_index) {
	case 0:
		throw new haxe_Exception("Invalid argument");
	case 1:
		return res.error;
	}
};
var formula_Schema = $hx_exports["formula"]["Schema"] = function() {
	this.byNameMap = new haxe_ds_StringMap();
	this.byIdMap = new haxe_ds_StringMap();
};
formula_Schema.__name__ = true;
formula_Schema.prototype = {
	defineProp: function(id,name,type) {
		var def = { id : id, name : name, type : type};
		this.byIdMap.h[id] = def;
		this.byNameMap.h[name] = def;
	}
	,findPropDefById: function(id) {
		return this.byIdMap.h[id];
	}
	,findPropDefByName: function(name) {
		return this.byNameMap.h[name];
	}
};
var formula_TokenDesc = $hxEnums["formula.TokenDesc"] = { __ename__:true,__constructs__:null
	,TkNum: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkNum",$_.__params__ = ["value"],$_)
	,TkStr: ($_=function(value) { return {_hx_index:1,value:value,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkStr",$_.__params__ = ["value"],$_)
	,TkLparen: {_hx_name:"TkLparen",_hx_index:2,__enum__:"formula.TokenDesc",toString:$estr}
	,TkRparen: {_hx_name:"TkRparen",_hx_index:3,__enum__:"formula.TokenDesc",toString:$estr}
	,TkLbracket: {_hx_name:"TkLbracket",_hx_index:4,__enum__:"formula.TokenDesc",toString:$estr}
	,TkRbracket: {_hx_name:"TkRbracket",_hx_index:5,__enum__:"formula.TokenDesc",toString:$estr}
	,TkComment: {_hx_name:"TkComment",_hx_index:6,__enum__:"formula.TokenDesc",toString:$estr}
	,TkIdent: ($_=function(name) { return {_hx_index:7,name:name,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkIdent",$_.__params__ = ["name"],$_)
	,TkDot: {_hx_name:"TkDot",_hx_index:8,__enum__:"formula.TokenDesc",toString:$estr}
	,TkComma: {_hx_name:"TkComma",_hx_index:9,__enum__:"formula.TokenDesc",toString:$estr}
	,TkQuestion: {_hx_name:"TkQuestion",_hx_index:10,__enum__:"formula.TokenDesc",toString:$estr}
	,TkColon: {_hx_name:"TkColon",_hx_index:11,__enum__:"formula.TokenDesc",toString:$estr}
	,TkBang: {_hx_name:"TkBang",_hx_index:12,__enum__:"formula.TokenDesc",toString:$estr}
	,TkBinOp: ($_=function(op) { return {_hx_index:13,op:op,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkBinOp",$_.__params__ = ["op"],$_)
	,TkArray: ($_=function(value) { return {_hx_index:14,value:value,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkArray",$_.__params__ = ["value"],$_)
	,TkEof: {_hx_name:"TkEof",_hx_index:15,__enum__:"formula.TokenDesc",toString:$estr}
};
formula_TokenDesc.__constructs__ = [formula_TokenDesc.TkNum,formula_TokenDesc.TkStr,formula_TokenDesc.TkLparen,formula_TokenDesc.TkRparen,formula_TokenDesc.TkLbracket,formula_TokenDesc.TkRbracket,formula_TokenDesc.TkComment,formula_TokenDesc.TkIdent,formula_TokenDesc.TkDot,formula_TokenDesc.TkComma,formula_TokenDesc.TkQuestion,formula_TokenDesc.TkColon,formula_TokenDesc.TkBang,formula_TokenDesc.TkBinOp,formula_TokenDesc.TkArray,formula_TokenDesc.TkEof];
var formula_TypingEnv = function(parent) {
	this.bindings = new haxe_ds_StringMap();
	this.parent = parent;
};
formula_TypingEnv.__name__ = true;
formula_TypingEnv.prototype = {
	lookup: function(name) {
		if(Object.prototype.hasOwnProperty.call(this.bindings.h,name)) {
			return this.bindings.h[name];
		} else if(this.parent != null) {
			return this.parent.lookup(name);
		} else {
			return null;
		}
	}
};
var formula_TypeChecker = function(schema) {
	this.schema = schema;
};
formula_TypeChecker.__name__ = true;
formula_TypeChecker.prototype = {
	subtype: function(subTy,supTy) {
		switch(supTy._hx_index) {
		case 2:
			switch(subTy._hx_index) {
			case 3:
				return true;
			case 4:
				return true;
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
			break;
		case 3:
			switch(subTy._hx_index) {
			case 2:
				return true;
			case 4:
				return true;
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
			break;
		case 4:
			var _g = supTy.elementType;
			switch(subTy._hx_index) {
			case 4:
				return this.subtype(subTy.elementType,_g);
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return true;
			}
			break;
		case 5:
			switch(subTy._hx_index) {
			case 4:
				return true;
			case 7:
				return true;
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
			break;
		case 6:
			switch(subTy._hx_index) {
			case 4:
				return true;
			case 7:
				return true;
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
			break;
		case 7:
			switch(subTy._hx_index) {
			case 4:
				return true;
			case 5:case 6:
				return true;
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
			break;
		case 10:
			return true;
		case 11:
			var _g = supTy.id;
			switch(subTy._hx_index) {
			case 4:
				return true;
			case 10:
				return true;
			case 11:
				if(subTy.id == _g) {
					return true;
				} else {
					return subTy == supTy;
				}
				break;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
			break;
		case 12:
			var _g = supTy.exist;
			switch(subTy._hx_index) {
			case 4:
				if(_g.resolvedType != null) {
					return this.subtype(subTy,_g.resolvedType);
				} else {
					_g.resolvedType = subTy;
					return true;
				}
				break;
			case 10:
				_g.resolvedType = formula_ValueType.TAny;
				return true;
			case 12:
				var _g1 = subTy.exist;
				if(_g1.resolvedType != null) {
					return this.subtype(_g1.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				if(_g.resolvedType != null) {
					return this.subtype(subTy,_g.resolvedType);
				} else {
					_g.resolvedType = subTy;
					return true;
				}
			}
			break;
		default:
			switch(subTy._hx_index) {
			case 4:
				return true;
			case 10:
				return true;
			case 12:
				var _g = subTy.exist;
				if(_g.resolvedType != null) {
					return this.subtype(_g.resolvedType,supTy);
				} else {
					throw new haxe_Exception("unreachable");
				}
				break;
			default:
				return subTy == supTy;
			}
		}
	}
	,instantiate: function(fnTy) {
		if(fnTy.typeVars == null) {
			return fnTy;
		}
		var tvarToInstantiatedType_h = { };
		var _this = fnTy.typeVars;
		var _g_current = 0;
		while(_g_current < _this.length) tvarToInstantiatedType_h[_g_current++] = formula_ValueType.TExist(new formula_TypeExist(null));
		var map = null;
		map = function(ty) {
			switch(ty._hx_index) {
			case 4:
				return formula_ValueType.TList(map(ty.elementType));
			case 11:
				var _g = ty.id;
				if(tvarToInstantiatedType_h.hasOwnProperty(_g)) {
					return tvarToInstantiatedType_h[_g];
				} else {
					return ty;
				}
				break;
			default:
				return ty;
			}
		};
		var _this = fnTy.params;
		var f = map;
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = f(_this[i]);
		}
		var tmp;
		if(fnTy.optParams == null) {
			tmp = null;
		} else {
			var _this = fnTy.optParams;
			var f = map;
			var result1 = new Array(_this.length);
			var _g = 0;
			var _g1 = _this.length;
			while(_g < _g1) {
				var i = _g++;
				result1[i] = f(_this[i]);
			}
			tmp = result1;
		}
		return { params : result, optParams : tmp, variadic : fnTy.variadic == null ? null : map(fnTy.variadic), ret : map(fnTy.ret), typeVars : null};
	}
	,normalize: function(ty) {
		switch(ty._hx_index) {
		case 4:
			return formula_ValueType.TList(this.normalize(ty.elementType));
		case 11:
			return formula_ValueType.TVar(ty.id);
		case 12:
			var _g = ty.exist;
			if(_g.resolvedType == null) {
				throw new haxe_Exception("unreachable");
			} else {
				return this.normalize(_g.resolvedType);
			}
			break;
		default:
			return ty;
		}
	}
	,inferResult: function(func,args,fnPos,pos) {
		var _gthis = this;
		if(func == "equal" || func == "unequal") {
			var _g_current = 0;
			while(_g_current < args.length) if([formula_ValueType.TSelect,formula_ValueType.TUser,formula_ValueType.TFile].indexOf(args[_g_current++].type) != -1) {
				return formula_ValueType.TBoolean;
			}
		}
		var def = formula_Builtins.singleton.findFuncDef(func);
		if(def == null) {
			throw new formula_ParseException(formula_Error.UndefinedFunction(func,fnPos));
		}
		var fnTy = this.instantiate(def.type);
		var check = function(exp,typSlot) {
			if(!_gthis.subtype(exp.type,typSlot)) {
				if(typSlot._hx_index == 12) {
					throw new formula_ParseException(formula_Error.TypeMismatch(exp,[typSlot.exist.resolvedType]));
				}
			}
		};
		if(args.length < fnTy.params.length) {
			throw new formula_ParseException(formula_Error.TooFewArguments(args.length,fnTy.params.length,pos));
		}
		var optParamsLen = fnTy.optParams == null ? 0 : fnTy.optParams.length;
		if(args.length > fnTy.params.length + optParamsLen && fnTy.variadic == null) {
			throw new formula_ParseException(formula_Error.TooManyArguments(args.length,fnTy.params.length,pos));
		}
		var _this = fnTy.params;
		var _g_current = 0;
		while(_g_current < _this.length) {
			var _g_value = _this[_g_current++];
			check(args[_g_current - 1],_g_value);
		}
		if(fnTy.optParams != null && args.length > fnTy.params.length) {
			var _g = fnTy.params.length;
			var _g1 = fnTy.params.length + optParamsLen;
			while(_g < _g1) {
				var i = _g++;
				check(args[i],fnTy.optParams[i - fnTy.params.length]);
			}
		}
		if(fnTy.variadic != null && args.length > fnTy.params.length + optParamsLen) {
			var _g = fnTy.params.length + optParamsLen;
			var _g1 = args.length;
			while(_g < _g1) check(args[_g++],fnTy.variadic);
		}
		if(func == "prop") {
			var prop = args[0];
			var name = formula_ExprTool.getStringValue(prop);
			var propDef = this.schema.findPropDefByName(name);
			if(propDef == null) {
				throw new formula_ParseException(formula_Error.UndefinedProp(name,prop.pos));
			}
			args[1] = { desc : formula_ExprDesc.EString(propDef.id), pos : prop.pos, type : formula_ValueType.TText};
			return propDef.type;
		} else if(func == "plus") {
			return formula_ValueType.TText;
		}
		return this.normalize(fnTy.ret);
	}
	,infer: function(exp,env) {
		if(exp.type != null) {
			return exp.type;
		}
		var _g = exp.desc;
		var tmp;
		switch(_g._hx_index) {
		case 0:
			tmp = formula_ValueType.TNumber;
			break;
		case 1:
			tmp = formula_ValueType.TText;
			break;
		case 2:
			var _g1 = _g.$const;
			if(env.lookup(_g1) != null) {
				tmp = env.lookup(_g1);
			} else {
				var def = formula_Builtins.singleton.findConstDef(_g1);
				if(def == null) {
					if(formula_Builtins.singleton.findFuncDef(_g1) != null) {
						throw new formula_ParseException(formula_Error.FunctionAsConstant(_g1,exp.pos));
					}
					throw new formula_ParseException(formula_Error.UndefinedConstant(_g1,exp.pos));
				}
				switch(def._hx_index) {
				case 0:
					tmp = formula_ValueType.TNumber;
					break;
				case 1:
					tmp = formula_ValueType.TBoolean;
					break;
				case 2:
					tmp = formula_ValueType.TDate;
					break;
				case 3:
					tmp = formula_ValueType.TDateRange;
					break;
				case 4:
					tmp = formula_ValueType.TUser;
					break;
				case 5:
					tmp = formula_ValueType.TSelect;
					break;
				case 6:
					tmp = formula_ValueType.TFile;
					break;
				case 7:
					tmp = formula_ValueType.TRollup;
					break;
				case 8:
					tmp = formula_ValueType.TList(def.value.elementType);
					break;
				case 9:
					tmp = formula_ValueType.TNull;
					break;
				case 10:
					tmp = formula_ValueType.TText;
					break;
				}
			}
			break;
		case 3:
			var _g1 = _g.values;
			if(_g1.length == 0) {
				tmp = formula_ValueType.TList(formula_ValueType.TAny);
			} else {
				var exist = new formula_TypeExist(null);
				var elemType = formula_ValueType.TExist(exist);
				var _g2 = 0;
				while(_g2 < _g1.length) this.check(_g1[_g2++],env,elemType);
				tmp = formula_ValueType.TList(this.normalize(exist.resolvedType));
			}
			break;
		case 4:
			var _g1 = _g.fn;
			var _g2 = _g.fnPos;
			var _g3 = _g.args;
			var fn = _g1;
			switch(_g1) {
			case "concat":
				var resultType = formula_ValueType.TAny;
				var _g_current = 0;
				while(_g_current < _g3.length) {
					var _g_value = _g3[_g_current];
					++_g_current;
					var argType = this.infer(_g_value,env);
					if(resultType != formula_ValueType.TAny && argType != resultType) {
						throw new formula_ParseException(formula_Error.TypeMismatch(_g_value,[resultType,formula_ValueType.TList(formula_ValueType.TVar(0))]));
					}
					if(argType == null) {
						throw new formula_ParseException(formula_Error.TypeMismatch(_g_value,[resultType,formula_ValueType.TList(formula_ValueType.TVar(0))]));
					} else {
						switch(argType._hx_index) {
						case 4:
							resultType = argType;
							break;
						case 7:
							resultType = argType;
							break;
						default:
							throw new formula_ParseException(formula_Error.TypeMismatch(_g_value,[resultType,formula_ValueType.TList(formula_ValueType.TVar(0))]));
						}
					}
				}
				tmp = resultType;
				break;
			case "ifs":
				var tyExist = formula_ValueType.TExist(new formula_TypeExist(null));
				var i = 0;
				while(_g3.length - i > 1) {
					var testArg = _g3[i];
					if(testArg == null) {
						throw new formula_ParseException(formula_Error.IfsCallMissingTestArg(exp.pos));
					}
					this.check(testArg,env,formula_ValueType.TBoolean);
					var thenArg = _g3[i + 1];
					if(thenArg == null) {
						throw new formula_ParseException(formula_Error.IfsCallMissingThenArg(exp.pos));
					}
					this.check(thenArg,env,tyExist);
					i += 2;
				}
				var elseArg = _g3[i];
				if(elseArg == null) {
					throw new formula_ParseException(formula_Error.IfsCallMissingElseArg(exp.pos));
				}
				this.check(elseArg,env,tyExist);
				tmp = this.normalize(tyExist);
				break;
			case "length":
				if(_g3.length < 1) {
					throw new formula_ParseException(formula_Error.TooFewArguments(_g3.length,1,exp.pos));
				}
				switch(this.normalize(this.infer(_g3[0],env))._hx_index) {
				case 4:
					tmp = formula_ValueType.TNumber;
					break;
				case 7:
					tmp = formula_ValueType.TNumber;
					break;
				default:
					throw new formula_ParseException(formula_Error.TypeMismatch(_g3[0],[formula_ValueType.TText,formula_ValueType.TList(formula_ValueType.TVar(0))]));
				}
				break;
			case "let":case "lets":
				if(_g1 == "let" && _g3.length != 3) {
					if(_g3.length < 3) {
						throw new formula_ParseException(formula_Error.TooFewArguments(_g3.length,3,exp.pos));
					} else {
						throw new formula_ParseException(formula_Error.TooManyArguments(_g3.length,3,exp.pos));
					}
				}
				var i = 0;
				var env2 = new formula_TypingEnv(env);
				do {
					var arg1 = _g3[i];
					if(arg1 == null) {
						throw new formula_ParseException(formula_Error.LetCallMissingNameArg(exp.pos));
					}
					var name;
					var _g4 = arg1.desc;
					if(_g4._hx_index == 2) {
						name = _g4.$const;
					} else {
						throw new formula_ParseException(formula_Error.ExpectIdentifier(arg1.pos));
					}
					var arg2 = _g3[i + 1];
					if(arg2 == null) {
						throw new formula_ParseException(formula_Error.LetCallMissingValueArg(exp.pos));
					}
					var this1 = env2.bindings;
					var value = this.infer(arg2,env2);
					this1.h[name] = value;
					i += 2;
				} while(_g3.length - i > 1);
				var arg3 = _g3[i];
				if(arg3 == null) {
					throw new formula_ParseException(formula_Error.LetCallMissingBodyArg(exp.pos));
				}
				tmp = this.infer(arg3,env2);
				break;
			case "plus":
				if(_g3.length < 2) {
					throw new formula_ParseException(formula_Error.TooFewArguments(_g3.length,2,exp.pos));
				}
				var resultType = formula_ValueType.TText;
				var _g_current = 0;
				while(_g_current < _g3.length) {
					var _g_value = _g3[_g_current];
					if(_g_current++ == 0) {
						var argType = this.infer(_g_value,env);
						if(argType == null) {
							throw new formula_ParseException(formula_Error.TypeMismatch(_g_value,[formula_ValueType.TText,formula_ValueType.TList(formula_ValueType.TVar(0))]));
						} else {
							switch(argType._hx_index) {
							case 4:
								break;
							case 5:
								break;
							case 6:
								break;
							case 7:
								break;
							case 8:
								resultType = argType;
								break;
							default:
								throw new formula_ParseException(formula_Error.TypeMismatch(_g_value,[formula_ValueType.TText,formula_ValueType.TList(formula_ValueType.TVar(0))]));
							}
						}
					} else {
						this.check(_g_value,env,resultType);
					}
				}
				tmp = resultType;
				break;
			case "unique":
				var _g4 = this.normalize(this.infer(_g3[0],env));
				if(_g4._hx_index == 4) {
					tmp = formula_ValueType.TList(_g4.elementType);
				} else {
					throw new formula_ParseException(formula_Error.TypeMismatch(_g3[0],[formula_ValueType.TList(formula_ValueType.TVar(0))]));
				}
				break;
			default:
				var splitFuns = ["slice"];
				var isListIterFn;
				switch(_g1) {
				case "every":case "filter":case "map":case "some":
					if(_g3.length != 2) {
						if(_g3.length < 2) {
							throw new formula_ParseException(formula_Error.TooFewArguments(_g3.length,2,exp.pos));
						} else {
							throw new formula_ParseException(formula_Error.TooManyArguments(_g3.length,2,exp.pos));
						}
					}
					isListIterFn = true;
					break;
				default:
					isListIterFn = false;
				}
				var _g_current = 0;
				while(_g_current < _g3.length) {
					var _g_value = _g3[_g_current++];
					var env1;
					if(isListIterFn && _g_current - 1 == 1) {
						var newEnv = new formula_TypingEnv(env);
						var arg1Type = this.infer(_g3[0],env);
						var elemType;
						if(arg1Type == null) {
							throw new haxe_Exception("unreachable");
						} else if(arg1Type._hx_index == 4) {
							elemType = arg1Type.elementType;
						} else {
							throw new haxe_Exception("unreachable");
						}
						newEnv.bindings.h["current"] = elemType;
						newEnv.bindings.h["index"] = formula_ValueType.TNumber;
						env1 = newEnv;
					} else {
						env1 = env;
					}
					this.infer(_g_value,env1);
				}
				var inferResultType = formula_ValueType.TNull;
				if(_g3.length > 0 && splitFuns.indexOf(_g1) != -1) {
					var inferType = this.infer(_g3[0],env);
					if(inferType != null) {
						switch(inferType._hx_index) {
						case 4:
							fn = "list_" + _g1;
							inferResultType = formula_ValueType.TList(inferType.elementType);
							break;
						case 7:
							fn = "string_" + _g1;
							inferResultType = formula_ValueType.TText;
							break;
						default:
						}
					}
					var paramType = formula_ValueType.TNumber;
					var _g_current = 0;
					while(_g_current < _g3.length) {
						var _g_value = _g3[_g_current++];
						if(_g_current - 1 != 0) {
							this.check(_g_value,env,paramType);
						}
					}
				}
				if(inferResultType != formula_ValueType.TNull) {
					tmp = inferResultType;
				} else {
					inferResultType = this.inferResult(fn,_g3,_g2,exp.pos);
					tmp = inferResultType;
				}
			}
			break;
		case 5:
			tmp = this.infer(_g.exp,env);
			break;
		case 6:
			var _g1 = _g.opPos;
			var _g2 = _g.arg;
			this.infer(_g2,env);
			var func;
			switch(_g.op._hx_index) {
			case 0:
				func = "unaryPlus";
				break;
			case 1:
				func = "unaryMinus";
				break;
			case 2:
				func = "not";
				break;
			}
			tmp = this.inferResult(func,[_g2],_g1,exp.pos);
			break;
		case 7:
			var _g1 = _g.opPos;
			var _g2 = _g.arg1;
			var _g3 = _g.arg2;
			this.infer(_g2,env);
			this.infer(_g3,env);
			var func;
			switch(_g.op._hx_index) {
			case 0:
				func = _g2.type == formula_ValueType.TNumber ? "add" : "plus";
				break;
			case 1:
				func = "subtract";
				break;
			case 2:
				func = "multiply";
				break;
			case 3:
				func = "divide";
				break;
			case 4:
				func = "mod";
				break;
			case 5:
				func = "pow";
				break;
			case 6:
				func = "equal";
				break;
			case 7:
				func = "unequal";
				break;
			case 8:
				func = "smaller";
				break;
			case 9:
				func = "smallerEq";
				break;
			case 10:
				func = "larger";
				break;
			case 11:
				func = "largerEq";
				break;
			case 12:
				func = "and";
				break;
			case 13:
				func = "or";
				break;
			}
			tmp = this.inferResult(func,[_g2,_g3],_g1,exp.pos);
			break;
		case 8:
			var _g1 = _g.test;
			var _g2 = _g.then;
			var _g3 = _g.else_;
			this.infer(_g1,env);
			this.infer(_g2,env);
			this.infer(_g3,env);
			tmp = this.inferResult("if",[_g1,_g2,_g3],null,exp.pos);
			break;
		case 9:
			tmp = formula_ValueType.TNull;
			break;
		}
		return exp.type = tmp;
	}
	,check: function(exp,env,type) {
		if(!this.subtype(this.infer(exp,env),type)) {
			throw new formula_ParseException(formula_Error.TypeMismatch(exp,[this.normalize(type)]));
		}
	}
};
var formula_TypeExist = function(resolvedType) {
	this.resolvedType = resolvedType;
};
formula_TypeExist.__name__ = true;
var haxe_Log = function() { };
haxe_Log.__name__ = true;
haxe_Log.formatOutput = function(v,infos) {
	var str = Std.string(v);
	if(infos == null) {
		return str;
	}
	var pstr = infos.fileName + ":" + infos.lineNumber;
	if(infos.customParams != null) {
		var _g = 0;
		var _g1 = infos.customParams;
		while(_g < _g1.length) str += ", " + Std.string(_g1[_g++]);
	}
	return pstr + ": " + str;
};
haxe_Log.trace = function(v,infos) {
	var str = haxe_Log.formatOutput(v,infos);
	if(typeof(console) != "undefined" && console.log != null) {
		console.log(str);
	}
};
var haxe_ValueException = function(value,previous,native) {
	haxe_Exception.call(this,String(value),previous,native);
	this.value = value;
};
haxe_ValueException.__name__ = true;
haxe_ValueException.__super__ = haxe_Exception;
haxe_ValueException.prototype = $extend(haxe_Exception.prototype,{
	unwrap: function() {
		return this.value;
	}
});
var haxe_ds_GenericCell = function(elt,next) {
	this.elt = elt;
	this.next = next;
};
haxe_ds_GenericCell.__name__ = true;
var haxe_ds_StringMap = function() {
	this.h = Object.create(null);
};
haxe_ds_StringMap.__name__ = true;
var haxe_io_Bytes = function(data) {
	this.length = data.byteLength;
	this.b = new Uint8Array(data);
	this.b.bufferValue = data;
	data.hxBytes = this;
	data.bytes = this.b;
};
haxe_io_Bytes.__name__ = true;
haxe_io_Bytes.ofString = function(s,encoding) {
	if(encoding == haxe_io_Encoding.RawNative) {
		var buf = new Uint8Array(s.length << 1);
		var _g = 0;
		var _g1 = s.length;
		while(_g < _g1) {
			var i = _g++;
			var c = s.charCodeAt(i);
			buf[i << 1] = c & 255;
			buf[i << 1 | 1] = c >> 8;
		}
		return new haxe_io_Bytes(buf.buffer);
	}
	var a = [];
	var i = 0;
	while(i < s.length) {
		var c = s.charCodeAt(i++);
		if(55296 <= c && c <= 56319) {
			c = c - 55232 << 10 | s.charCodeAt(i++) & 1023;
		}
		if(c <= 127) {
			a.push(c);
		} else if(c <= 2047) {
			a.push(192 | c >> 6);
			a.push(128 | c & 63);
		} else if(c <= 65535) {
			a.push(224 | c >> 12);
			a.push(128 | c >> 6 & 63);
			a.push(128 | c & 63);
		} else {
			a.push(240 | c >> 18);
			a.push(128 | c >> 12 & 63);
			a.push(128 | c >> 6 & 63);
			a.push(128 | c & 63);
		}
	}
	return new haxe_io_Bytes(new Uint8Array(a).buffer);
};
haxe_io_Bytes.prototype = {
	getString: function(pos,len,encoding) {
		if(pos < 0 || len < 0 || pos + len > this.length) {
			throw haxe_Exception.thrown(haxe_io_Error.OutsideBounds);
		}
		if(encoding == null) {
			encoding = haxe_io_Encoding.UTF8;
		}
		var s = "";
		var b = this.b;
		var i = pos;
		var max = pos + len;
		switch(encoding._hx_index) {
		case 0:
			while(i < max) {
				var c = b[i++];
				if(c < 128) {
					if(c == 0) {
						break;
					}
					s += String.fromCodePoint(c);
				} else if(c < 224) {
					var code = (c & 63) << 6 | b[i++] & 127;
					s += String.fromCodePoint(code);
				} else if(c < 240) {
					var code1 = (c & 31) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
					s += String.fromCodePoint(code1);
				} else {
					var u = (c & 15) << 18 | (b[i++] & 127) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
					s += String.fromCodePoint(u);
				}
			}
			break;
		case 1:
			while(i < max) {
				var c = b[i++] | b[i++] << 8;
				s += String.fromCodePoint(c);
			}
			break;
		}
		return s;
	}
};
var haxe_io_Encoding = $hxEnums["haxe.io.Encoding"] = { __ename__:true,__constructs__:null
	,UTF8: {_hx_name:"UTF8",_hx_index:0,__enum__:"haxe.io.Encoding",toString:$estr}
	,RawNative: {_hx_name:"RawNative",_hx_index:1,__enum__:"haxe.io.Encoding",toString:$estr}
};
haxe_io_Encoding.__constructs__ = [haxe_io_Encoding.UTF8,haxe_io_Encoding.RawNative];
var haxe_io_Error = $hxEnums["haxe.io.Error"] = { __ename__:true,__constructs__:null
	,Blocked: {_hx_name:"Blocked",_hx_index:0,__enum__:"haxe.io.Error",toString:$estr}
	,Overflow: {_hx_name:"Overflow",_hx_index:1,__enum__:"haxe.io.Error",toString:$estr}
	,OutsideBounds: {_hx_name:"OutsideBounds",_hx_index:2,__enum__:"haxe.io.Error",toString:$estr}
	,Custom: ($_=function(e) { return {_hx_index:3,e:e,__enum__:"haxe.io.Error",toString:$estr}; },$_._hx_name="Custom",$_.__params__ = ["e"],$_)
};
haxe_io_Error.__constructs__ = [haxe_io_Error.Blocked,haxe_io_Error.Overflow,haxe_io_Error.OutsideBounds,haxe_io_Error.Custom];
var hxparse_ParserError = function(pos) {
	this.pos = pos;
};
hxparse_ParserError.__name__ = true;
hxparse_ParserError.prototype = {
	toString: function() {
		return "Parser error";
	}
};
var hxparse_NoMatch = function(pos,token) {
	hxparse_ParserError.call(this,pos);
	this.token = token;
};
hxparse_NoMatch.__name__ = true;
hxparse_NoMatch.__super__ = hxparse_ParserError;
hxparse_NoMatch.prototype = $extend(hxparse_ParserError.prototype,{
	toString: function() {
		return "No match: " + Std.string(this.token);
	}
});
var hxparse_Parser = function(stream) {
	this.stream = stream;
};
hxparse_Parser.__name__ = true;
hxparse_Parser.prototype = {
	peek: function(n) {
		if(this.token == null) {
			this.token = new haxe_ds_GenericCell(this.stream.token(),null);
			--n;
		}
		var tok = this.token;
		while(n > 0) {
			if(tok.next == null) {
				tok.next = new haxe_ds_GenericCell(this.stream.token(),null);
			}
			tok = tok.next;
			--n;
		}
		return tok.elt;
	}
	,junk: function() {
		this.last = this.token.elt;
		this.token = this.token.next;
	}
	,curPos: function() {
		return this.stream.curPos();
	}
	,parseSeparated: function(separatorFunc,f) {
		var acc = [];
		while(true) {
			try {
				acc.push(f());
			} catch( _g ) {
				if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
					break;
				} else {
					throw _g;
				}
			}
			if(separatorFunc(this.peek(0))) {
				this.last = this.token.elt;
				this.token = this.token.next;
			} else {
				break;
			}
		}
		return acc;
	}
	,parseOptional: function(f) {
		try {
			return f();
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				return null;
			} else {
				throw _g;
			}
		}
	}
	,parseRepeat: function(f) {
		var acc = [];
		while(true) try {
			acc.push(f());
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				return acc;
			} else {
				throw _g;
			}
		}
	}
	,parseExpect: function(f) {
		try {
			return f();
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
			} else {
				throw _g;
			}
		}
	}
	,noMatch: function() {
		return new hxparse_NoMatch(this.stream.curPos(),this.peek(0));
	}
	,unexpected: function() {
		throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
	}
};
var hxparse_Position = function(source,min,max) {
	this.psource = source;
	this.pmin = min;
	this.pmax = max;
};
hxparse_Position.__name__ = true;
hxparse_Position.union = function(p1,p2) {
	return new hxparse_Position(p1.psource,p1.pmin < p2.pmin ? p1.pmin : p2.pmin,p1.pmax > p2.pmax ? p1.pmax : p2.pmax);
};
hxparse_Position.prototype = {
	toString: function() {
		return "" + this.psource + ":characters " + this.pmin + "-" + this.pmax;
	}
	,getLinePosition: function(input) {
		var lineMin = 1;
		var lineMax = 1;
		var posMin = 0;
		var posMax = 0;
		var cur = 0;
		while(cur < this.pmin) {
			if(input.b[cur] == 10) {
				++lineMin;
				posMin = cur + 1;
			}
			++cur;
		}
		lineMax = lineMin;
		posMax = posMin;
		posMin = cur - posMin;
		while(cur < this.pmax) {
			if(input.b[cur] == 10) {
				++lineMax;
				posMax = cur + 1;
			}
			++cur;
		}
		posMax = cur - posMax;
		return { lineMin : lineMin, lineMax : lineMax, posMin : posMin, posMax : posMax};
	}
	,format: function(input) {
		var linePos = this.getLinePosition(input);
		if(linePos.lineMin != linePos.lineMax) {
			return "" + this.psource + ":lines " + linePos.lineMin + "-" + linePos.lineMax;
		} else {
			return "" + this.psource + ":" + linePos.lineMin + ": characters " + linePos.posMin + "-" + linePos.posMax;
		}
	}
};
var hxparse_Unexpected = function(token,pos) {
	hxparse_ParserError.call(this,pos);
	this.token = token;
};
hxparse_Unexpected.__name__ = true;
hxparse_Unexpected.__super__ = hxparse_ParserError;
hxparse_Unexpected.prototype = $extend(hxparse_ParserError.prototype,{
	toString: function() {
		return "Unexpected " + Std.string(this.token);
	}
});
var hxparse_UnexpectedChar = function(char,pos) {
	hxparse_ParserError.call(this,pos);
	this.char = char;
};
hxparse_UnexpectedChar.__name__ = true;
hxparse_UnexpectedChar.__super__ = hxparse_ParserError;
hxparse_UnexpectedChar.prototype = $extend(hxparse_ParserError.prototype,{
	toString: function() {
		return "Unexpected " + this.char;
	}
});
function $getIterator(o) { if( o instanceof Array ) return new haxe_iterators_ArrayIterator(o); else return o.iterator(); }
function $bind(o,m) { if( m == null ) return null; if( m.__id__ == null ) m.__id__ = $global.$haxeUID++; var f; if( o.hx__closures__ == null ) o.hx__closures__ = {}; else f = o.hx__closures__[m.__id__]; if( f == null ) { f = m.bind(o); o.hx__closures__[m.__id__] = f; } return f; }
$global.$haxeUID |= 0;
if(typeof(performance) != "undefined" ? typeof(performance.now) == "function" : false) {
	HxOverrides.now = performance.now.bind(performance);
}
if( String.fromCodePoint == null ) String.fromCodePoint = function(c) { return c < 0x10000 ? String.fromCharCode(c) : String.fromCharCode((c>>10)+0xD7C0)+String.fromCharCode((c&0x3FF)+0xDC00); }
String.__name__ = true;
Array.__name__ = true;
Date.__name__ = "Date";
js_Boot.__toStr = ({ }).toString;
DateTools.DAY_SHORT_NAMES = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
DateTools.DAY_NAMES = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
DateTools.MONTH_SHORT_NAMES = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
DateTools.MONTH_NAMES = ["January","February","March","April","May","June","July","August","September","October","November","December"];
datetime_DateTime.UNIX_EPOCH_DIFF = 62135596800.0;
datetime_DateTime.SECONDS_IN_MINUTE = 60;
datetime_DateTime.SECONDS_IN_HOUR = 3600;
datetime_DateTime.SECONDS_IN_DAY = 86400;
datetime_DateTime.SECONDS_IN_WEEK = 604800;
datetime_DateTime.SECONDS_IN_YEAR = 31536000;
datetime_DateTime.SECONDS_IN_LEAP_YEAR = 31622400;
datetime_DateTime.SECONDS_IN_3_YEARS = 94608000;
datetime_DateTime.SECONDS_IN_QUAD = 126230400.0;
datetime_DateTime.SECONDS_IN_HALF_QUAD = 63072000.0;
datetime_DateTime.SECONDS_IN_HALF_QUAD_LEAP = 63158400.0;
datetime_DateTime.SECONDS_IN_3_PART_QUAD = 94694400.0;
datetime_DateTime.SECONDS_IN_CQUAD = 12622780800.0;
datetime_DateTime.SECONDS_IN_CENTURY = 3155673600.0;
datetime_DateTime.SECONDS_IN_LEAP_CENTURY = 3155760000.0;
formula_ValueTool.numberType = formula_ValueType.TNumber;
formula_ValueTool.booleanType = formula_ValueType.TBoolean;
formula_ValueTool.dateType = formula_ValueType.TDate;
formula_ValueTool.dateRangeType = formula_ValueType.TDateRange;
formula_ValueTool.userType = formula_ValueType.TUser;
formula_ValueTool.stringType = formula_ValueType.TText;
formula_ValueTool.selectType = formula_ValueType.TSelect;
formula_ValueTool.fileType = formula_ValueType.TFile;
formula_ValueTool.rollup = formula_ValueType.TRollup;
formula_ValueTool.anyType = formula_ValueType.TAny;
formula_ValueTool.listType = formula_ValueType.TList;
formula_ValueTool.textType = formula_ValueType.TText;
formula_Utils.splitFuns = ["slice"];
formula_Builtins.FLAG_BOLD = 1;
formula_Builtins.FLAG_UNDERLINE = 2;
formula_Builtins.FLAG_ITALICS = 4;
formula_Builtins.FLAG_CODE = 8;
formula_Builtins.FLAG_STRIKETHROUGH = 16;
formula_Builtins.FLAG_COLOR = 32;
formula_Builtins.FLAG_BACKGROUND_COLOR = 64;
formula_Builtins.STYLES = ["b","u","i","c","s"];
formula_Builtins.STYLES_FLAGS = [formula_Builtins.FLAG_BOLD,formula_Builtins.FLAG_UNDERLINE,formula_Builtins.FLAG_ITALICS,formula_Builtins.FLAG_CODE,formula_Builtins.FLAG_STRIKETHROUGH];
formula_Builtins.COLORS = ["grey","brown","orange","yellow","green","blue","purple","pink","red","black"];
formula_Builtins.BACKGROUND_COLORS = (function($this) {
	var $r;
	var _this = formula_Builtins.COLORS;
	var result = new Array(_this.length);
	{
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = _this[i] + "_background";
		}
	}
	$r = result;
	return $r;
}(this));
formula_Builtins.singleton = new formula_Builtins();
formula_Env.top = new formula_Env();
formula_Formula.regex = new EReg("\\bprop\\s*\\(\\s*(\"(?:[^\\\\\"]|\\\\.)*\")\\s*\\)","g");
formula_ObjcEvalContext.singleton = new formula_ObjcEvalContext();
formula_TypingEnv.top = new formula_TypingEnv();
})(typeof exports != "undefined" ? exports : typeof window != "undefined" ? window : typeof self != "undefined" ? self : this, typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this);

export const formula = exports.formula;
const builtinsEn = builtins.map((item) => {
  const list = item.list.map((obj) => {
	return {
	  ...obj,
	  examples: obj.examplesEn,
	  description: obj.descriptionEn,
	};
  });
  return { ...item, list };
});
export { builtins, builtinsEn };