import type { formula } from '@flowus/formula';
import { TextType, type SegmentDTO } from '@next-space/fe-api-idl';

export const formulaTextToSegemnts = (text: formula.Text) => {
  if (!text) return [];
  if (!text.segments) return [];
  return text.segments.map((v) => {
    const segment: SegmentDTO = {
      type: TextType.TEXT,
      text: v.text,
      enhancer: { ...v.enhancer },
    };
    if (v.enhancer.link) {
      segment.type = TextType.URL;
    }
    return segment;
  });
};
