import { formula } from '@flowus/formula';
import type { FC } from 'react';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { FormulaPersonValue, FormulaSinglePersonValue } from './person';
import { FormulaListTextValue, FormulaRichTextValue } from './richtext';

interface Props {
  value?: formula.Value;
  collectionId: string;
  propertyId: string;
}
/**
 * 这个组件暂时用于公式的编写预览(目前仅用于person/richText)，不用于单元格(单元格展示的逻辑过于复杂，大改会有很多bug，没测试的情况下不打算重构了)
 */
export const FormulaValue: FC<Props> = (props) => {
  const { value, collectionId, propertyId } = props;
  const propertySchema = useObservableBlock(
    collectionId,
    (block) => block?.data.schema?.[propertyId]
  );
  if (!value) return null;
  if (!propertySchema) return null;
  const type = formula.ValueTool.typeOf(value);
  if (!type) return null;
  if (type === formula.ValueTool.userType) {
    return <FormulaSinglePersonValue value={value} schema={propertySchema} />;
  } else if (type === formula.ValueTool.textType) {
    return <FormulaRichTextValue value={value} schema={propertySchema} />;
  } else if (formula.ValueTool.listType(type)) {
    if (formula.ValueTool.getListElementType(type) === formula.ValueTool.userType) {
      return <FormulaPersonValue value={value} schema={propertySchema} />;
    } else if (formula.ValueTool.getListElementType(type) === formula.ValueTool.textType) {
      return <FormulaListTextValue value={value} schema={propertySchema} />;
    }
  }

  return null;
};
