/**
 * 应用骨架
 */
// 为了解循环依赖，注册用
import { useSystemIsRu } from '@flowus/common/utils/system';
import { setLocalPersistKey } from '@flowus/common/zustand/utils';
import { lazyLoad } from '@flowus/login/utils';
import { Provider as JotaiProvider } from 'jotai';
import type { FC } from 'react';
import { StrictMode, useEffect, useMemo, useState } from 'react';
import { isWindows } from 'react-device-detect';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import 'src/bitable/table-view/cell/relation/relation-editor';
import 'src/editor/editor/uikit/editor';
import { ViewPath } from 'src/utils';
import { validate } from 'uuid';
import { FormViewContent } from './bitable/form-view';
import { set_cycle_formViewContent } from './bitable/form-view/share-form-view';
import { LoadingContainer } from './common/components/loading-container';
import { NextModalProvider } from './common/components/next-modal';
import { globalListenerHelper } from './common/utils/global-listener-helper';
import { checkViewPath, getDomain } from './common/utils/url-utils';
import { GlobalModal } from './components/global-modal';
import { ImagesProvider } from './components/images-provider';
import { SentryErrorBoundary } from './components/sentry-error';
import { VITE_CDN_HOST } from './env';
import { useAutoShowAiPage } from './hooks/ai/use-ai-chat';
import { clearSegmentOffset } from './hooks/block/use-throttle-update-block';
import { useDesktopIpc } from './hooks/desktop/use-desktop-ipc';
import { goBackInfo } from './hooks/page';
import { useListenerPageKeydown } from './hooks/page/use-page-keydown';
import { useListenerEmitterModal } from './hooks/public/use-listener-emitter-modal';
import { useObserverBodySize } from './hooks/public/use-observer-body-size';
import { useReportActive } from './hooks/public/use-report-active';
import { useSwitchTheme, useTheme } from './hooks/public/use-theme';
import { getCurrentUser } from './hooks/user';
import { useAutoLogin } from './hooks/user/use-auto-login';
import { useSplash } from './hooks/utils/use-splash';
import { useCloseAllModal } from './redux/managers/ui';
import { uiActions } from './redux/reducers/ui';
import { cache, dispatch, store } from './redux/store';
import {
  $appUiStateCache,
  setAppUiState,
  useFatalError,
  useFirstLoadPage,
  useGetOffline,
} from './services/app';
import { useCheckVersion } from './services/app/hook/use-check-version';
import { $networkStatus } from './services/network-status';
import { useScreenLock } from './services/screen-lock';
import { $spacesCache } from './services/spaces/spaces';
import { useIframeMessage } from './services/useIframeMessage';
import { bizTracker } from './utils/biz-tracker';
import { getDateTimeStamp } from './utils/date-utils';
import { isFlowUsApp } from './utils/electron-util';
import { parseRightPageId, rightPage } from './utils/right-utils';
import { useSetDrawerOpenState } from './views/main/drawer/shared';
import { Header } from './views/main/header';
import { antiCycleSet_TemplateCenter } from './views/main/page-doc/recommend-template';
import { TemplateCenter } from './views/main/template';
import { ScreenLock } from './views/screen-lock';

const { PageOfflineEmpty } = lazyLoad(() => import('./views/empty/page-offline-empty'));
const { LoginWrapper } = lazyLoad(() => import('./views/login'));
const { Create } = lazyLoad(() => import('./views/create'));
const { FilePreviewPage } = lazyLoad(() => import('./views/file-preview'));
const { SharePage } = lazyLoad(() => import('./views/share'));
const { SubscribePage } = lazyLoad(() => import('./views/subscribe'));
const { Home } = lazyLoad(() => import('./home'));
const { ShareFormPage } = lazyLoad(() => import('./views/form'));
const { Callback } = lazyLoad(() => import('./views/callback'));
const { Notion } = lazyLoad(() => import('./views/notion'));
const { UpgradePage } = lazyLoad(() => import('./views/upgrade'));

/** 应用级别上下文 Provider, 包括不限于 router, store */
export const AppProvider: FC = ({ children }) => {
  return (
    <StrictMode>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <JotaiProvider>
            <ImagesProvider>
              <NextModalProvider>
                <GlobalModal>{children}</GlobalModal>
              </NextModalProvider>
            </ImagesProvider>
          </JotaiProvider>
        </ReduxProvider>
      </BrowserRouter>
    </StrictMode>
  );
};

/** 应用内容 */
export const AppContent: FC = () => {
  const fatalError = useFatalError();
  const [, setState] = useState();
  useEffect(() => {
    if (fatalError) {
      setState(() => {
        throw new Error(fatalError);
      });
    }
  }, [fatalError]);

  return (
    <Switch>
      <Route path="/preview/:blockId?" component={FilePreviewPage} />
      <Route path={ViewPath.callback} component={Callback} />
      <Route path={ViewPath.notion} component={Notion} />

      <Route path="/form" component={ShareFormPage} />
      <Route path="/login" component={LoginWrapper} />
      <Route path="/create" component={Create} />
      <Route path="/upgrade" component={UpgradePage} />
      <Route path={['/subscribe/share', '/subscribe/share/:blockId']} component={SubscribePage} />
      <Route path={['/share/:blockId', '/:domain/share/:blockId']} component={SharePage} />
      {/* <Route path={`${ViewPath.chat}/:id?`} component={ChatPage} /> */}
      <Route path="/" component={Home} />
      <Route path={['/:domain/:blockId', '/:blockId?', '/chat/:chatId?']} component={Home} />
    </Switch>
  );
};

/** 应用布局 */
export const AppLayout: FC = ({ children }) => {
  useIframeMessage();
  useDesktopIpc();
  useSplash();
  useReportActive();
  useTheme();
  useSwitchTheme();
  useObserverBodySize();
  useCheckVersion();
  useListenerPageKeydown();
  useListenerEmitterModal();
  useSystemIsRu();
  const offline = useGetOffline();
  const autoLogin = useAutoLogin();
  const closeAllModal = useCloseAllModal();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const setDrawerOpen = useSetDrawerOpenState();
  const firstLoadPage = useFirstLoadPage();
  const screenLock = useScreenLock();
  const autoShowAiPage = useAutoShowAiPage();

  useEffect(() => {
    // if (location.pathname.includes(ViewPath.chat)) {
    //   setLoading(false);
    //   return;
    // }
    if (location.pathname === '/' && getDomain()) {
      setLoading(false);
      return;
    }

    if (!checkViewPath(ViewPath.login) && !checkViewPath(ViewPath.callback)) {
      const loginCallback = () => {
        setLoading(false);
        const userId = getCurrentUser().uuid;
        if (userId) {
          setLocalPersistKey(userId);
        }
      };

      // 如果是客户端，没登录的话应该去login
      if (isFlowUsApp.check) {
        void autoLogin().finally(loginCallback);
      } else {
        // 非客户端情况下，可能会是访问表单或者访问share。所以不需要去login
        const checkJump = location.pathname !== ViewPath.main;
        autoLogin(checkJump).finally(loginCallback);
      }
    } else {
      setLoading(false);
    }
  }, [autoLogin]);

  // 页面埋点，用于看页面PV/UV等
  useEffect(() => {
    bizTracker.event('predefinePageView');
  }, []);

  // 半小时检测一次，更新当天日期
  useEffect(() => {
    setInterval(() => {
      const now = getDateTimeStamp(Date.now());
      if ($appUiStateCache.$today !== now) {
        setAppUiState({ $today: now });
      }
    }, 30 * 60 * 1000);
  }, []);

  // 监听初始化离线
  useEffect(() => {
    const handleNetWorkChange = (e: Event) => {
      if (e.type === 'offline') {
        void fetch(`${VITE_CDN_HOST}gen_204`, {
          cache: 'no-cache',
        })
          .then(() => {
            // 返回来就说明正常
            $networkStatus.setStatus('online');
          })
          .catch(() => {
            // 二次确认，曾复现过navigator.onLine为true却离线的情况
            if (!navigator.onLine) {
              // api挂了，是离线
              $networkStatus.setStatus('offline');
            } else {
              $networkStatus.setStatus('online');
            }
          });
      } else {
        $networkStatus.setStatus('online');
      }

      // 离线冷启动，再联网时 reload
      if (e.type !== 'offline' && $appUiStateCache.$firstLoadPage) {
        location.reload();
      }

      return true; // 返回true表示有一个监听了后面的listener就不需要回调了
    };

    globalListenerHelper.addEventListener('online', handleNetWorkChange);
    globalListenerHelper.addEventListener('offline', handleNetWorkChange);

    return () => {
      globalListenerHelper.removeEventListener('online', handleNetWorkChange);
      globalListenerHelper.removeEventListener('offline', handleNetWorkChange);
    };
  });

  // history变化
  useEffect(() => {
    const cleanState = () => {
      // 热更新的时候会为空
      closeAllModal?.();
      // 路由变化的时候，选择的block清空
      dispatch(uiActions.updateSelectBlocks([]));
      setDrawerOpen(false);
      clearSegmentOffset();
    };
    // https://github.com/remix-run/react-router/issues/5362
    const unregister = history.block((location, action) => {
      // 如果最后一次访问的是 chat，下次打开还是这个
      if (location.pathname.includes(ViewPath.chat)) {
        autoShowAiPage.clear();
      } else {
        autoShowAiPage.close();
      }
      // 由于使用history.push/replace的地方太多且为了通用,这里如果有自定义域名就转成自定义域名的地址
      const uuid = location.pathname.slice(-36);
      if (!validate(uuid)) return;

      rightPage.lastPageId = parseRightPageId(location.search) ?? rightPage.lastPageId;

      const domain = $spacesCache[cache.blocks[uuid]?.spaceId ?? '']?.domain;
      if (!getDomain() && domain && !location.pathname.startsWith(`/${domain}`)) {
        const historyAction = action === 'PUSH' ? history.push : history.replace;
        if (checkViewPath(ViewPath.preview, 'startsWith', location.pathname)) {
          return;
        }

        if (checkViewPath(ViewPath.subscribe, 'startsWith', location.pathname)) {
          return;
        }
        if (checkViewPath(ViewPath.share, 'startsWith', location.pathname)) {
          historyAction(`/${domain}${ViewPath.share}${uuid}${location.search}${location.hash}`);
          return false;
        }
        historyAction(`/${domain}/${uuid}${location.search}${location.hash}`);
        return false;
      }
      if (!goBackInfo.tmpDisable) {
        goBackInfo.lastGoBackPath = action === 'POP' ? location.pathname : '';
      }
      cleanState();
    });

    if (screenLock) {
      cleanState();
    }

    return unregister;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenLock, history, closeAllModal, setDrawerOpen]);

  const content = useMemo(() => {
    if (screenLock) {
      return <ScreenLock />;
    }

    if (offline && firstLoadPage) {
      return (
        <>
          <Header />
          <PageOfflineEmpty />
        </>
      );
    }

    if (loading) {
      return <LoadingContainer fixed />;
    }

    return children;
  }, [children, firstLoadPage, loading, offline, screenLock]);

  return (
    <>
      {isFlowUsApp.check && !isWindows && (
        <div className="drag-container fixed w-full top-0 h-3.5 z-[8848]" />
      )}
      <SentryErrorBoundary>{content}</SentryErrorBoundary>
    </>
  );
};

/** 版本信息 */
export const AppVersion: FC = () => {
  if (__HOST_PRODUCTION__) {
    return null;
  }

  return (
    <div className="fixed bottom-0 right-0 z-50 px-1 text-grey4 pointer-events-none text-t2">
      version: {__VERSION__}
    </div>
  );
};
set_cycle_formViewContent(FormViewContent);
antiCycleSet_TemplateCenter(TemplateCenter);
