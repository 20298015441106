import { generateFormatByDate } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { useStableCallback } from '@flowus/common/hooks/react-utils';
import { formula } from '@flowus/formula';
import type { TextMarker } from 'codemirror';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Button } from 'src/common/components/button';
import { KeyboardShortcut } from 'src/components/keyboard-shortcut';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import type { FormulaError, FormulaPreview } from 'src/hooks/block/use-formula-tool';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useCollectionId } from 'src/hooks/collection-view/use-collection-id';
import { useViewParentId } from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateSchema } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { CheckboxValue } from '../checkbox';
import { NumValue } from '../num';
import { TextValueView } from '../text';
import { type CellEditorProps, type CellViewProps } from '../types';
import type { FormulaCodeEditorHandle } from './formula-code-editor';
import { FormulaCodeEditor, getSearchWord } from './formula-code-editor';
import { FormulaManual } from './formula-manual';
import {
  ArrayListValue,
  ArrayOfFile,
  ArrayOfFormulaText,
  ArrayOfSelectValue,
  ArrayOfUserValue,
  ArrayValue,
  FormulaText,
  UserValue,
} from '../array';
import { FormulaSelectValue } from '../style-text';
import 'dayjs/locale/zh-cn';
import { isBuildIn } from 'src/env';

const DateValue: FC<CellViewProps> = ({ className, propertyId, recordId }) => {
  const { collectionId, viewId } = useBitable();
  const value = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      return formulaTool.getValue(recordId, propertyId) as Date | formula.DateRangeValue | null;
    },
    [],
    {
      obsSelectCell: [
        {
          recordId,
          propertyId,
          viewId,
        },
      ],
    }
  );

  if (value == null) return null;
  if (value instanceof Date) {
    const dateFormat = generateFormatByDate(value);
    return (
      <TextValueView className={cx('p-2 leading-5', className)}>
        {dayjs(value)
          .locale(isBuildIn() ? 'en' : 'zh-cn')
          .format(dateFormat)}
      </TextValueView>
    );
  }
  const dateFormat = generateFormatByDate(value.start);
  return (
    <TextValueView className={cx('p-2 leading-5', className)}>
      {`${dayjs(value.start)
        .locale(isBuildIn() ? 'en' : 'zh-cn')
        .format(dateFormat)} → ${dayjs(value.end)
        .locale(isBuildIn() ? 'en' : 'zh-cn')
        .format(dateFormat)}`}
    </TextValueView>
  );
};

export const FormulaValue: FC<CellViewProps> = (props) => {
  const { collectionId, viewId } = useBitable();
  const { propertyId, recordId } = props;

  const type: formula.ValueType | null = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      return formulaTool.getType(propertyId);
    },
    [collectionId, propertyId],
    {
      obsSelectCell: [
        {
          recordId,
          propertyId,
          viewId,
        },
      ],
    }
  );

  if (type == null) {
    return null;
  }
  if (type === formula.ValueTool.numberType) {
    return <NumValue {...props} />;
  } else if (type === formula.ValueTool.booleanType) {
    return <CheckboxValue {...props} />;
  } else if (type === formula.ValueTool.dateType) {
    return <DateValue {...props} />;
  } else if (type === formula.ValueTool.selectType) {
    return <FormulaSelectValue {...props} />;
  } else if (type === formula.ValueTool.userType) {
    return <UserValue {...props} />;
  } else if (type === formula.ValueTool.textType) {
    return <FormulaText {...props} />;
  } else if (formula.ValueTool.isListType(type)) {
    const elementType = formula.ValueTool.getListElementType(type);
    if (elementType === formula.ValueTool.userType) {
      return <ArrayOfUserValue {...props} />;
    } else if (elementType === formula.ValueTool.textType) {
      return <ArrayOfFormulaText {...props} />;
    } else if (elementType === formula.ValueTool.selectType) {
      return <ArrayOfSelectValue {...props} />;
    } else if (elementType === formula.ValueTool.fileType) {
      return <ArrayOfFile {...props} />;
    } else if (formula.ValueTool.isListType(elementType)) {
      return <ArrayListValue {...props} />;
    }
    return <ArrayValue {...props} />;
  }
  return null;
};

export const FormulaEditor: FC<CellEditorProps> = ({ propertyId, recordId, onClose, viewId }) => {
  const collectionId = useCollectionId(recordId) ?? '';
  const formulaTool = getFormulaTool(collectionId);
  const collectionReadonly = useReadonly(collectionId);
  const viewParentId = useViewParentId(viewId) ?? collectionId;
  const isLocked = useBlockLocked(viewParentId);
  const readonly = collectionReadonly || isLocked;
  const schema = useObservableBlock(collectionId, (block) => block?.data.schema);
  const initialValue = useObservableBlock(collectionId, (block) => {
    const formula = block?.data.schema?.[propertyId]?.formula;
    return formula == null ? '' : formulaTool.fromServer(formula as any);
  });
  const editorRef = useRef<FormulaCodeEditorHandle>();
  const [search, setSearch] = useState<{ cursor: number; word: string } | null>(null);

  const markerRef = useRef<TextMarker | undefined>();
  const [result, setResult] = useState<FormulaPreview | FormulaError | null>(null);

  const transaction = useTransaction();
  const onSave = () => {
    if (editorRef.current == null) return;
    if (result?.type !== 'preview') return;

    const value = editorRef.current.cm().getValue();
    transaction(() => {
      const { blocks } = getState();
      const collection = blocks[collectionId];
      if (!collection) return;

      const schema = collection.data.schema ?? {};
      const propertySchema = schema[propertyId];
      if (!propertySchema) return;

      updateSchema(collection.uuid, {
        [propertyId]: {
          ...propertySchema,
          formula: formulaTool.toServer(value) as any,
        },
      });
    });
    onClose();
  };

  const handleChange = useStableCallback(() => {
    if (editorRef.current == null) return;
    const editor = editorRef.current.cm();

    const search = getSearchWord(editor);
    setSearch(search);

    const setResult2 = (result: FormulaPreview | FormulaError | null) => {
      setResult(result);
      if (markerRef.current != null) {
        markerRef.current.clear();
        markerRef.current = undefined;
      }
      if (result == null) return;
      if (result.type === 'error') {
        const doc = editor.getDoc();
        markerRef.current = editor.markText(
          doc.posFromIndex(result.pos.pmin),
          doc.posFromIndex(result.pos.pmax),
          { className: 'cm-mark-error' }
        );
      }
    };

    const value = editor.getValue();
    if (value === '') {
      setResult2(null);
      return;
    }

    try {
      const result = formulaTool.validate(value, collectionId, recordId, propertyId);
      setResult2(result);
    } catch (error) {
      console.log('error: ', error);
    }
  });

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return (
    <div
      className={cx(
        'bg-white1 flex flex-col',
        css({
          minWidth: '180px',
          width: '680px',
          height: readonly ? '48px' : '400px',
          maxWidth: 'calc(100vw - 24px)',
          maxHeight: '70vh',
        })
      )}
    >
      <div
        className="flex items-start max-h-[180px] formula-editor"
        onKeyDown={(event) => {
          event.stopPropagation();
          if (isHotkey('mod+enter')(event) && result?.type !== 'error') {
            onSave();
          }
        }}
      >
        <FormulaCodeEditor
          ref={editorRef}
          className="flex-1 min-w-0 mx-2.5 my-2 text-t2 border border-grey6 bg-grey9 focus-within-border rounded-sm box-border"
          autoFocus
          placeholder="输入公式"
          initialValue={initialValue}
          maxHeight="162px"
          readOnly={readonly}
          schema={schema}
          onChange={handleChange}
        />
        {!readonly && (
          <Button
            colorType="active"
            className="my-2 mr-2.5"
            onClick={onSave}
            disable={result?.type === 'error'}
          >
            提交
          </Button>
        )}
      </div>
      {!readonly && (
        <>
          <FormulaManual
            className="border-t border-grey6 flex-1"
            search={search?.word}
            recordId={recordId}
            propId={propertyId}
            onComplete={(kind, name) => {
              const cm = editorRef.current?.cm();
              if (cm == null) return;
              if (kind === 'function') {
                name += '(';
              } else if (kind === 'property') {
                name = `prop(${JSON.stringify(name)})`;
              }
              const doc = cm.getDoc();
              const from = search?.cursor ?? doc.indexFromPos(doc.getCursor());
              const to = search == null ? from : from + search.word.length;
              cm.replaceRange(name, doc.posFromIndex(from), doc.posFromIndex(to), '+input');
            }}
          />
          <div className="h-8 text-t2 flex items-center border-t border-grey6">
            <span className="flex items-center flex-1 mx-3 text-ellipsis">
              {result?.type === 'preview' && (
                <>
                  <span>预览结果：</span>
                  {result.preview}
                </>
              )}
              {result?.type === 'error' && (
                <span className="text-red flex-1">{result.message}</span>
              )}
            </span>
            <KeyboardShortcut className="mr-3" shortcut="Enter可快捷提交" />
          </div>
        </>
      )}
    </div>
  );
};
